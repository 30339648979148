<template>
<nav id="theNav" :class="dark?'darkCard':''">
	<div>
		<i @click="$router.go(-1)" class="material-icons" aria-hidden="true">arrow_back</i>
		<h2 style="cursor:pointer;" @click="changeFolderName()" id="folderName"></h2>
	</div>
	<div>
		<div style="display:none;" id="favorite_icon_div" @click="favoriteButton()">
			<i id="favorite_icon" class="material-icons" aria-hidden="true">star</i>
		</div>
		<i @click="showDropdownFolder()" id="dropdown_icon" class="material-icons" aria-hidden="true">arrow_drop_down</i>
	</div>
</nav>
<div id="dropdown-content-folder" :class="dark?'dropdown-content-folder darkCard':'dropdown-content-folder'">
	<a @click="deleteCurrentFolder()" class="titleText">Delete folder</a>
	<a @click="search()" class="titleText">Search</a>
</div>
<br>
<div class="contentPadding" :style="dark?'color:white;':''">

	<Modal :isLoading="isLoading" :isVisible="modalVisible" :closeModal="toggleModal" :options="options" :functions="functions" :dark="dark"/>

	<h3 v-if="folders.length==0&&notes.length==0" class="titleText noContent">No content</h3>
	<template v-else>
		<FolderCard v-for="(folder,index) in folders" @contextmenu.prevent="folderMenu(folder.id)" :key="index" :id="folder.id" :index="index" :dark="dark">{{folder.name}}</FolderCard>
		<NoteCard v-for="(note,index) in notes" @contextmenu.prevent="noteMenu(note.id)" :key="index" :id="note.id" :index="index" :dark="dark">{{note.title}}</NoteCard>
	</template>

</div>
<br>
<div class="folderAddButtons">
	<div @click="addNewFolder()" class="circularButton">
		<i :style="dark?'font-size:1.8em;color:white;':'font-size:1.8em;'" class="material-icons" aria-hidden="true">create_new_folder</i>
	</div>
	<div @click="addNewNote()" class="circularButton">
		<i :style="dark?'font-size:1.8em;color:white;':'font-size:1.8em;'" class="material-icons" aria-hidden="true">note_add</i>
	</div>
</div>
<br>
</template>

<script>
import { reactive } from 'vue';
import { ref } from "vue";
import { useRoute } from 'vue-router';
import router from '../router/router.js';
import { onMounted } from "vue";
import { onUnmounted } from "vue";
import Localbase from 'localbase';
import FolderCard from '../components/FolderCard.vue';
import NoteCard from '../components/NoteCard.vue';
import Modal from '../components/Modal.vue';
import mf from '../modules/mf';

export default {
  name: 'FolderView',
  components: {
		FolderCard,
		NoteCard,
		Modal,
  },
  setup(props,context){

		//Get the theme
		const dark = localStorage.getItem('darkTheme')=="true";

		let alwaysTrue = true;

		let db = new Localbase('monkeynote');
		const route = useRoute();
		let folder;

		const folders=reactive([]);
		const notes=reactive([]);

		const modalVisible = ref(false);
		const isLoading = ref(false);
		const options = reactive([]);
		const functions = reactive([]);

		const toggleModal=()=>{
			modalVisible.value=!modalVisible.value;
		}

		async function fetchFolderContent(){
			let foldersRaw = [];
			let notesRaw = [];
			//Get the folder
			folder = await db.collection("folders").doc(route.params.folderID).get();
			//Get folder IDs
			let foldersIDs;
			if(folder.foldersID.trim()==""){
				foldersIDs = [];
			} else {
				foldersIDs = folder.foldersID.split(" ");
			}
			//Get folders
			for (let i = 0; i < foldersIDs.length; i++) {
				let x = await db.collection("folders").doc(foldersIDs[i]).get();
				try {
					foldersRaw.push({id:foldersIDs[i],name:x.name});
				} catch(e) {
					//If the folder doesn't exists delete it from its parent list
					let z = folder.foldersID.split(" ");
					z.splice(z.indexOf(foldersIDs[i]),1);
					await db.collection("folders").doc(folder.id).update({
						foldersID: z.join(" "),
					});
				}
			}
			//Get notes IDs
			let notesIDs;
			if(folder.notesID.trim()==""){
				notesIDs = [];
			} else {
				notesIDs = folder.notesID.split(" ");
			}
			//Get notes
			for (let i = 0; i < notesIDs.length; i++) {
				let x = await db.collection("notes").doc(notesIDs[i]).get();
				try {
					notesRaw.push({id:notesIDs[i],title:x.title});
				} catch(e) {
					//If the note doesn't exists delete it from its parent list
					let z = folder.notesID.split(" ");
					z.splice(z.indexOf(notesIDs[i]),1);
					await db.collection("folders").doc(folder.id).update({
						notesID: z.join(" "),
					});
				}
			}

			//Set the title
			document.getElementById("folderName").innerHTML=folder.name;

			//Whether it's favorite or not
			if(!folder.favorite){
				document.getElementById("favorite_icon").innerHTML="star_border";
			}
			document.getElementById("favorite_icon_div").style.display = "block";

			//Update the UI folder content
			while (folders.length) {
				folders.pop();
			}
			while (notes.length) {
				notes.pop();
			}
			foldersRaw.forEach(function(element,index){
				folders.push(element);
			});
			notesRaw.forEach(function(element,index){
				notes.push(element);
			});
		}
		fetchFolderContent();

		function changeFolderName(){
			let newFolderName = prompt("New folder name:",folder.name);
			if(newFolderName!=null&&newFolderName.trim()!=""){
				if(newFolderName.length>150){
					alert("Less than 150 characters.");
					return;
				}
				newFolderName=newFolderName.trim();
				db.collection('folders').doc(route.params.folderID).update({
					name: newFolderName,
				}).then(response => {
					folder.name = newFolderName;
					document.getElementById("folderName").innerHTML=newFolderName;
				}).catch(error => {
					alert('There was an error');
				});
			}
		}
		
		const addNewFolder=async()=>{
			const currentFolderID = route.params.folderID;
			let name = prompt("New Folder:");
			if (name!=null&&name.trim()!="") {
				let db = new Localbase('monkeynote');
				//Create the new folder
				let response = await db.collection('folders').add({
					parentID: currentFolderID,
					name: name.trim(),
					notesID:"",
					foldersID:"",
					favorite:false,
				});
				//Add the new folder key into its parent folders ID list
				let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
				if(folder.foldersID.trim()==""){
					folder.foldersID=key;
					await db.collection('folders').doc(currentFolderID).update({
						foldersID: key,
					});
				} else {
					let a = folder.foldersID.split(" ");
					a.push(key);
					folder.foldersID = a.join(" ");
					await db.collection('folders').doc(currentFolderID).update({
						foldersID: a.join(" "),
					});
				}
				//Add the key to the folder itself
				await db.collection('folders').doc(key).update({id:key});

				//Update the changes in the UI
				folders.push({id:key,name:name});
			}
		}
		
		const addNewNote=async()=>{
			const currentFolderID = route.params.folderID;
			let title = prompt("New Note:");
			if (title!=null&&title.trim()!="") {
				let db = new Localbase('monkeynote');
				let milliseconds = (new Date()).getTime();//milliseconds since epoch
				//Create the new note
				let response = await db.collection('notes').add({
					folderID: currentFolderID,
					title: title.trim(),
					body: "",
					favorite:false,
					creationDate: milliseconds,
					lastModified: milliseconds,
				});
				//Add the new note key into its parent folders ID list
				let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
				if(folder.notesID.trim()==""){
					folder.notesID=key;
					await db.collection('folders').doc(currentFolderID).update({
						notesID: key,
					});
				} else {
					let a = folder.notesID.split(" ");
					a.push(key);
					folder.notesID = a.join(" ");
					await db.collection('folders').doc(currentFolderID).update({
						notesID: a.join(" "),
					});
				}
				//Add th key to the note itself
				await db.collection('notes').doc(key).update({id:key});

				//Update the changes in the UI
				notes.push({id:key,title:title});
			}
		}

		const folderMenu=id=>{
			//Clear the previous content
			while (options.length) {
				options.pop();
			}
			//Add the options
			options.push("Delete folder");
			options.push("Copy folder");
			options.push("Move folder");
			//Clear the previous content
			while (functions.length) {
				functions.pop();
			}
			//Add the options
			functions.push(async function(){
				isLoading.value=true;
				if(confirm("Delete the folder?")){
					await mf.deleteFolder(id);
				}
				await fetchFolderContent();
				isLoading.value=false;
				modalVisible.value=false;
			});
			functions.push(()=>{
				sessionStorage.setItem('selectedItem','folder:'+id);
				router.push('/select/copy/'+route.params.folderID);
			});
			functions.push(()=>{
				sessionStorage.setItem('selectedItem','folder:'+id);
				router.push('/select/move/'+route.params.folderID);
			});
			//Show the modal
			toggleModal();
		}

		const noteMenu=id=>{
			//Clear the previous content
			while (options.length) {
				options.pop();
			}
			//Add the options
			options.push("Delete note");
			options.push("Copy note");
			options.push("Move note");
			//Clear the previous content
			while (functions.length) {
				functions.pop();
			}
			//Add the options
			functions.push(async function(){
				isLoading.value=true;
				if(confirm("Delete the note?")){
					await mf.deleteNote(id);
				}
				await fetchFolderContent();
				isLoading.value=false;
				modalVisible.value=false;
			});
			functions.push(()=>{
				sessionStorage.setItem('selectedItem','note:'+id);
				router.push('/select/copy/'+route.params.folderID);
			});
			functions.push(()=>{
				sessionStorage.setItem('selectedItem','note:'+id);
				router.push('/select/move/'+route.params.folderID);
			});
			//Show the modal
			toggleModal();
		}

		//Change if the current element is favorite or not
		function favoriteButton(){
			let favorite_icon = document.getElementById("favorite_icon");
			db.collection('folders').doc(route.params.folderID).update({
				favorite: favorite_icon.innerHTML=="star_border",
			}).then(response => {
				//Change the icon and save it into the favorite folders
				if(favorite_icon.innerHTML=="star"){
					favorite_icon.innerHTML="star_border";
					let mirkoDebil = localStorage.getItem("favoriteFolders").split(" ");
					mirkoDebil.splice(mirkoDebil.indexOf(route.params.folderID),1);
					while(alwaysTrue){//Delete the empty items
						if(mirkoDebil.indexOf("")!=-1){
							mirkoDebil.splice(mirkoDebil.indexOf(""),1);
						} else {
							break;
						}
					}
					mirkoDebil=mirkoDebil.filter((item,index)=>mirkoDebil.indexOf(item)===index);//Remove duplicated items
					localStorage.setItem("favoriteFolders",mirkoDebil.join(" "));
				} else {
					favorite_icon.innerHTML="star";
					//Save it into the favorite folders
					if(localStorage.getItem("favoriteFolders")==null||localStorage.getItem("favoriteFolders")==""){
						localStorage.setItem("favoriteFolders",route.params.folderID);
					} else {
						let renzitoInutil = localStorage.getItem("favoriteFolders").split(" ");
						if(renzitoInutil.indexOf(route.params.noteID)==-1){
							renzitoInutil.push(route.params.folderID);
						}
						while(alwaysTrue){//Delete the empty items
							if(renzitoInutil.indexOf("")!=-1){
								renzitoInutil.splice(renzitoInutil.indexOf(""),1);
							} else {
								break;
							}
						}
						renzitoInutil=renzitoInutil.filter((item,index)=>renzitoInutil.indexOf(item)===index);//Remove duplicated items
						localStorage.setItem("favoriteFolders",renzitoInutil.join(" "));
					}
				}
			}).catch(error => {
				alert('There was an error');
			});
		}
		
		function showDropdownFolder(){
			let dropdownIcon = document.getElementById("dropdown_icon");
			if(document.getElementById("dropdown-content-folder").style.display=="block"){
				dropdownIcon.innerHTML="arrow_drop_down";
				document.getElementById("dropdown-content-folder").style.display="none";
			} else {
				dropdownIcon.innerHTML="arrow_drop_up";
				document.getElementById("dropdown-content-folder").style.display="block";
			}
		}
		
		async function deleteCurrentFolder(){
			if(confirm("Are you sure you want to delete the folder?")){
				isLoading.value=true;
				modalVisible.value=true;
				try {
					await mf.deleteFolder(route.params.folderID);
				} catch(e) {
					alert('There was an error');
					console.log(e);
				}
				modalVisible.value=false;
				isLoading.value=false;
				history.back();
			}
		}

		const search=()=>{
			sessionStorage.setItem('searchBoundaries',folder.name+'<monkeynote separator>'+route.params.folderID);
			router.push('/search');
		}

    // Shortcuts
		const isKeyPressed = { 
			'm': false,//main folder
			'f': false,//folder
			'n': false,//Note
			'c': false,//Create
			's': false,//Search
			//Indexes
			'1': false,
			'2': false,
			'3': false,
			'4': false,
			'5': false,
			'6': false,
			'7': false,
			'8': false,
			'9': false,
		}
		onMounted(()=>{
			document.onkeydown = (keyDownEvent) => {
				try {
					isKeyPressed[keyDownEvent.key] = true;
					//The shortcuts
					if(isKeyPressed["0"])router.go(-1);
					if(isKeyPressed["Enter"]){
						const x = document.activeElement;
						if(x.getAttribute("class")=="folder_card"){
							router.push('/folder/'+folders[parseInt(x.getAttribute("tabindex"))-1].id);
						} else if(x.getAttribute("class")=="note_card"){
							router.push('/note/'+notes[parseInt(x.getAttribute("tabindex"))-1001].id);
						}
					}
					if(isKeyPressed["c"]&&isKeyPressed["f"]){
						addNewFolder();
						isKeyPressed['c']=false;
						isKeyPressed['f']=false;
					}
					if(isKeyPressed["c"]&&isKeyPressed["n"]){
						addNewNote();
						isKeyPressed['c']=false;
						isKeyPressed['n']=false;
					}
					if(isKeyPressed["s"]){
						search();
					}
					if(isKeyPressed["Escape"]){
						modalVisible.value=false;
					}
					if (isKeyPressed["f"]) {
						if(isKeyPressed["1"]){
							router.push('/folder/'+folders[0].id);
						} else if (isKeyPressed["2"]) {
							router.push('/folder/'+folders[1].id);
						} else if (isKeyPressed["3"]) {
							router.push('/folder/'+folders[2].id);
						} else if (isKeyPressed["4"]) {
							router.push('/folder/'+folders[3].id);
						} else if (isKeyPressed["5"]) {
							router.push('/folder/'+folders[4].id);
						} else if (isKeyPressed["6"]) {
							router.push('/folder/'+folders[5].id);
						} else if (isKeyPressed["7"]) {
							router.push('/folder/'+folders[6].id);
						} else if (isKeyPressed["8"]) {
							router.push('/folder/'+folders[7].id);
						} else if (isKeyPressed["9"]) {
							router.push('/folder/'+folders[8].id);
						}
					} else if (isKeyPressed["n"]) {
						if(isKeyPressed["1"]){
							router.push('/note/'+notes[0].id);
						} else if (isKeyPressed["2"]) {
							router.push('/note/'+notes[1].id);
						} else if (isKeyPressed["3"]) {
							router.push('/note/'+notes[2].id);
						} else if (isKeyPressed["4"]) {
							router.push('/note/'+notes[3].id);
						} else if (isKeyPressed["5"]) {
							router.push('/note/'+notes[4].id);
						} else if (isKeyPressed["6"]) {
							router.push('/note/'+notes[5].id);
						} else if (isKeyPressed["7"]) {
							router.push('/note/'+notes[6].id);
						} else if (isKeyPressed["8"]) {
							router.push('/note/'+notes[7].id);
						} else if (isKeyPressed["9"]) {
							router.push('/note/'+notes[8].id);
						}
					} else {
						if(isKeyPressed["1"]){
							if(1<=folders.length){
								router.push('/folder/'+folders[0].id);
							} else {
								router.push('/note/'+notes[0-folders.length].id);
							}
						} else if (isKeyPressed["2"]) {
							if(2<=folders.length){
								router.push('/folder/'+folders[1].id);
							} else {
								router.push('/note/'+notes[1-folders.length].id);
							}
						} else if (isKeyPressed["3"]) {
							if(3<=folders.length){
								router.push('/folder/'+folders[2].id);
							} else {
								router.push('/note/'+notes[2-folders.length].id);
							}
						} else if (isKeyPressed["4"]) {
							if(4<=folders.length){
								router.push('/folder/'+folders[3].id);
							} else {
								router.push('/note/'+notes[3-folders.length].id);
							}
						} else if (isKeyPressed["5"]) {
							if(5<=folders.length){
								router.push('/folder/'+folders[4].id);
							} else {
								router.push('/note/'+notes[4-folders.length].id);
							}
						} else if (isKeyPressed["6"]) {
							if(6<=folders.length){
								router.push('/folder/'+folders[5].id);
							} else {
								router.push('/note/'+notes[5-folders.length].id);
							}
						} else if (isKeyPressed["7"]) {
							if(7<=folders.length){
								router.push('/folder/'+folders[6].id);
							} else {
								router.push('/note/'+notes[6-folders.length].id);
							}
						} else if (isKeyPressed["8"]) {
							if(8<=folders.length){
								router.push('/folder/'+folders[7].id);
							} else {
								router.push('/note/'+notes[7-folders.length].id);
							}
						} else if (isKeyPressed["9"]) {
							if(9<=folders.length){
								router.push('/folder/'+folders[8].id);
							} else {
								router.push('/note/'+notes[8-folders.length].id);
							}
						}
					}
				} catch(e) {
					console.log('Item not found 🐵');
				}
			}
			document.onkeyup = (keyUpEvent) => {
				isKeyPressed[keyUpEvent.key] = false;
			};
		});
		onUnmounted(()=>{
			document.onkeydown = null;
			document.onkeyup = null;
		});

		return {
			toggleModal,
			modalVisible,
			options,
			functions,
			folderMenu,
			noteMenu,
			addNewFolder,
			addNewNote,
			favoriteButton,
			showDropdownFolder,
			changeFolderName,
			folders,
			notes,
			deleteCurrentFolder,
			fetchFolderContent,
			isLoading,
			search,
			dark,
		}
  }
}
</script>

<style scoped>
	@import '../styles/nav.css';

	#theNav > div:first-child h2 {
		padding: 0;
		margin: 0;
	}
	.folderAddButtons {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.folderAddButtons	.circularButton {
		margin: 0px;
	}
	.dropdown-content-folder {
		display: none;
		background-color: rgb(121,85,72);
		width: 80%;
		margin: 0px 0px 0px auto;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		z-index: 1;
		cursor: pointer;
	}
	.dropdown-content-folder a {
		color: white;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
	}
	.dropdown-content-folder a:hover {
		background-color: rgb(88,62,52);
	}
</style>