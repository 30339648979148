<template>

	<div v-if="selectable" class="folder_card_selectable" :style="dark?'background-color:rgb(50,50,50);':''">
		<div :style="dark?'color:white;':''">
			<i class="material-icons" aria-hidden="true">folder</i>
			<h2 style="overflow:hidden;text-overflow:ellipsis;" class="bodyText"><slot></slot></h2>
		</div>
		<i class="material-icons" aria-hidden="true">
			{{selected?'check_box':'check_box_outline_blank'}}
		</i>
	</div>

  <div v-else-if="isInMainFolder" :tabindex="index+1" @click="$router.push('/folder/'+id)" class="folder_card folder_card_in_main_folder" :style="dark?'background-color:rgb(72,72,72);color:white;':''">
		<i class="material-icons" aria-hidden="true">folder</i>
		<div style="width:10px;padding:0;margin:0;"></div>
		<h2 style="overflow:hidden;text-overflow:ellipsis;" class="bodyText"><slot></slot></h2>
	</div>

	<div v-else :tabindex="index+1" @click="if(!web)$router.push('/folder/'+id);" class="folder_card" :style="dark?'background-color:rgb(50,50,50);color:white;':''">
    <i class="material-icons" aria-hidden="true">folder</i>
    <div style="width:10px;"></div>
    <h2 style="overflow:hidden;text-overflow:ellipsis;" class="bodyText"><slot></slot></h2>
  </div>

</template>

<script>
export default {
  name: 'FolderCard',
  props: {
    id: String,
    index: Number,
    isInMainFolder: Boolean,
    selectable: Boolean,
    selected: Boolean,
    dark: Boolean,
    web: Boolean,
  },
  setup(props,context){
		function toggle(){
		}
		return {toggle};
  }
}
</script>

<style scoped>
	.folder_card:hover {
    background-color: #E4C872;
  }
  .folder_card {
    background-color: rgb(255,224,130);
    color: black;
    font-size: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: none;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19);
    width: 85%;
    margin: 20px auto 20px auto;
  }
  .folder_card i {
    color: rgb(140,105,0);
    font-size:1.9em;
    display:inline;
    margin:0px;
    padding:0px;
  }
  .folder_card h2 {
    display:inline;
    margin:0px;
    padding:0px;
  }
  .folder_card_in_main_folder {
		padding: 10px;
		margin: 15px auto 15px auto;
		background-color: rgb(255,209,72);
	}
	.folder_card_in_main_folder i:hover {
		background-color: rgba(0,0,0,0) !important;
	}
	.folder_card_selectable:hover {
		background-color: #E4C872;
	}
	.folder_card_selectable {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: none;
		padding: 20px;
		text-align: center;
		cursor: pointer;
		box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19);
		width: 85%;
		margin: 20px auto 20px auto;
	}
	.folder_card_selectable div {
		color: black;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.folder_card_selectable i {
		color: rgb(140,105,0);
		font-size:1.9em;
		display:inline;
		margin:0px;
		padding:0px;
	}
	.folder_card_selectable h2 {
		display:inline;
		margin:0px;
		padding-left: 10px;
	}
</style>