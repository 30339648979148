<template>

	<Modal :isLoading="true" :isVisible="loading" :dark="dark"/>

	<div :class="dark?'monkeyBar darkCard':'monkeyBar'">
		<i @click="$router.go(-1)" class="material-icons" aria-hidden="true">arrow_back</i>
		<h3 class="titleText">Register</h3>
	</div>
	<div class="contentPadding" :style="dark?'color:white;':'color:black;'">
		<h3 class="titleText">Email:</h3>
		<input :style="dark?'color:white;':''" class="bodyText normalInput" type="email">
		<h3 class="titleText">Password:</h3>
		<input :style="dark?'color:white;':''" class="bodyText normalInput" type="password">
		<br>
		<br>
		<br>
		<button @click="signIn()" :class="'monkeyButton titleText'+(dark?' darkCard':'')">
			Sign in
		</button>
		<br>
		<br>
		<button @click="signUp()" :class="'monkeyButton titleText'+(dark?' darkCard':'')">
			Sign up
		</button>
		<br>
		<br>
		<p @click="resetPassword()" class="bodyText">Forgot password?</p>
	</div>
</template>

<script>
	import { ref } from "vue";
	import router from '../router/router.js';
	import firebase from 'firebase/app';
	import 'firebase/auth';
	import 'firebase/firestore';
	import Modal from '../components/Modal.vue';
	export default {
		name: 'Authentication',
		components: {Modal},
		setup(props,context){
			
			let dark = localStorage.getItem('darkTheme')=="true";
			let userLoggedIn = false;
			const loading = ref(false);
			const auth = firebase.auth();

			const validData=(email,password)=>email&&email.trim()!=''&&email.trim().length>5&&email.indexOf("@")!=-1&&email.indexOf(".")!=-1&&password&&password.trim()!=''&&password.trim().length>5;
			
			const signIn=async()=>{
				let email = document.getElementsByTagName("input")[0].value;
				let password = document.getElementsByTagName("input")[1].value;
				if(!validData(email,password)){
					alert('Invalid data');
					return;
				}
				try {
					loading.value=true;
					await auth.signInWithEmailAndPassword(email,password);
					loading.value=false;
					router.go(-1);
				} catch(e) {
					alert("Error when signing in");
					loading.value=false;
				}
			}

			const signUp=async()=>{
				let email = document.getElementsByTagName("input")[0].value;
				let password = document.getElementsByTagName("input")[1].value;
				if(!validData(email,password)){
					alert('Invalid data');
				} else {
					if(confirm('Create account with: '+email+'?')){
						loading.value=true;
						try {
							const x = await auth.createUserWithEmailAndPassword(email,password);
							await firebase.firestore().collection('users').doc(x.user.uid).set({
								'fastNote': 'Welcome to the Fast Note!\n\nHere you can quickly pass information between devices through your account.',
							});
							alert('Account created 🐒 welcome '+email.split('@')[0]);
							loading.value=false;
							router.go(-1);
						} catch(e) {
							alert("Error when creating the account");
							loading.value=false;
						}
					}
				}
			}

			const resetPassword=async()=>{
				let email = prompt("Email:");
				if(!validData(email,"monkeylautee")){
					alert('Invalid email');
				} else {
					loading.value=true;
					try {
						await auth.sendPasswordResetEmail(email);
						loading.value=false;
						alert('Check your email inbox 🐒');
					} catch(e) {
						alert('An error happened');
						loading.value=false;
					}
				}
			}

			return {
				dark,
				loading,
				signIn,
				signUp,
				resetPassword,
			}

		}
	}

</script>

<style scoped>
	.normalInput {
		background-color: rgba(0,0,0,0);
		height: 40px;
		width: 100%;
		margin-bottom: 16px;
		display: inline;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	h3 {
		text-align: center;
	}
	p {
		text-decoration:underline;
		cursor:pointer;
		text-align: center;
	}
	button {
		width: 40%;
	}
</style>