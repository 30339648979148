<template>
	<nav id="theNav" :class="dark?'darkCard':''">
		<div>
			<i @click="$router.go(-1)" class="material-icons" aria-hidden="true">arrow_back</i>
			<h2 style="cursor:pointer;" @click="changeTitle()" id="noteTitle"></h2>
		</div>
		<div>
			<i v-if="microVisible" @click="changeLang()" class="material-icons" aria-hidden="true">translate</i>
			<div style="display:none;" id="favorite_icon_div" @click="favoriteButton()">
				<i id="favorite_icon" class="material-icons" aria-hidden="true">star</i>
			</div>
			<i @click="showDropdownNote()" id="dropdown_icon" class="material-icons" aria-hidden="true">arrow_drop_down</i>
		</div>
	</nav>
	<div id="dropdown-content-note" :class="dark?'dropdown-content-note darkCard':'dropdown-content-note'">
		<a @click="closeWithoutSaving()" class="titleText">Close without saving</a>
		<a @click="readAloud();$router.push('/readNote');" class="titleText">Read aloud</a>
		<a @click="exportAsTxt()" class="titleText">Export as txt</a>
		<a @click="displayMicro()" class="titleText">Voice typing</a>
		<a @click="replaceWord()" class="titleText">Replace word</a>
		<a @click="displayNoteInformation()" class="titleText">Information</a>
	</div>
	<div v-if="microVisible" id="speechToText">
		<div @click="speechToText()" class="circularButton" :style="dark?'background-color:rgb(72,72,72);':'background-color:rgb(255,194,13);'">
			<i id="microIcon" :style="dark?'color:white;':'color:black;'" class="material-icons" aria-hidden="true">keyboard_voice</i>
		</div>
	</div>
	<div v-if="selectLang" id="languagesPanel">
		<label for="language">Language:</label>
		<select name="language" id="language" :onchange="setLanguage">
			<option v-for="(lang,index) in monkey.langs" :key="index" :value="monkey.langCodes[index]">{{lang}}</option>
		</select>
	</div>
	<div style="padding:10px;">
		<textarea :style="dark?'color:white;':'color:black;'" :disabled="!noteReady" id="noteBody" class="bodyText" spellcheck="false"></textarea>
	</div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from 'vue-router'
import Localbase from 'localbase';
import { onMounted } from "vue";
import { onUnmounted } from "vue";
import monkey from '../modules/monkey';

export default {
  name: 'NoteView',
	beforeUnmount(){
		if(localStorage.getItem("closeWithoutSaving")=="true"){
			localStorage.setItem("closeWithoutSaving","false");
		} else {
			//Save the note body
			let db=new Localbase('monkeynote');
			db.collection('notes').doc(localStorage.getItem("currentNote")).update({
				body: document.getElementById("noteBody").value.trim(),
				lastModified: (new Date()).getTime(),
			}).then(response => {
				// Do nothing
			}).catch(error => {
				alert('There was an error');
			});
		}
		localStorage.setItem("currentNote","");
	},
	setup(props,context){

		const dark = localStorage.getItem('darkTheme')=="true";

		let alwaysTrue = true;
		const noteReady = ref(false);

		//Save the note when the tab is closed 
		window.addEventListener("beforeunload", function (e) {
			let db = new Localbase('monkeynote');
			db.collection('notes').doc(localStorage.getItem("currentNote")).update({
				body: document.getElementById("noteBody").value,
				lastModified: (new Date()).getTime(),
			}).then(response => {
				// Do nothing
			}).catch(error => {
				alert('There was an error');
			});
			localStorage.setItem("currentNote","");
		});

		let db = new Localbase('monkeynote');
		const route = useRoute();

		localStorage.setItem("currentNote",route.params.noteID);
		
		let note;
		
		async function loadNoteData(){
			//Get the note
			note = await db.collection("notes").doc(route.params.noteID).get();
			//Set the title
			document.getElementById("noteTitle").innerHTML=note.title;
			//Set the body
			let noteBodyTag = document.getElementById("noteBody");
			noteBodyTag.value = note.body;
			noteBodyTag.style.height = "";
			noteBodyTag.style.height = noteBodyTag.scrollHeight + "px";

			//Whether it's favorite or not
			if(!note.favorite){
				document.getElementById("favorite_icon").innerHTML="star_border";
			}
			//If the note is in the recycle bin, don't allow it to be favorite
			if(localStorage.getItem("deletedNotes")==null||localStorage.getItem("deletedNotes").split(" ").indexOf(route.params.noteID)==-1){
				document.getElementById("favorite_icon_div").style.display="block";
			}
			//Get the height
			setCorrectHeight();
			noteReady.value=true;
		}
		loadNoteData();

		function closeWithoutSaving(){
			if(confirm("Are you sure you want to close without saving the note?")){
				localStorage.setItem("closeWithoutSaving","true");
				history.back();
			}
		}

		function readAloud(){
			sessionStorage.setItem("textToRead",document.getElementById("noteBody").value.trim());
			sessionStorage.setItem("textToReadTitle",note.title.trim());
		}

		function exportAsTxt(){
			if(confirm("Export as txt?")){
				let txtBody = document.getElementById("noteBody").value.trim();
				var element = document.createElement('a');
				element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(txtBody));
				element.setAttribute('download',note.title+'.txt');
				element.style.display = 'none';
				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
			}
		}

		function displayNoteInformation(){
			let creationDate = new Date(note.creationDate);
			let creationDateString = ""+creationDate.getFullYear()+"/"+(creationDate.getMonth()+1)+"/"+creationDate.getDate()+" - "+creationDate.getHours()+":"+creationDate.getMinutes()+":"+(creationDate.getSeconds().toString().length==1?"0"+creationDate.getSeconds():creationDate.getSeconds());
			let lastModified = new Date(note.lastModified);
			let lastModifiedString = ""+lastModified.getFullYear()+"/"+(lastModified.getMonth()+1)+"/"+lastModified.getDate()+" - "+lastModified.getHours()+":"+lastModified.getMinutes()+":"+(lastModified.getSeconds().toString().length==1?"0"+lastModified.getSeconds():lastModified.getSeconds());
			alert("- Characters: "+
				document.getElementById("noteBody").value.length+
				"\n\n- Creation: "+
				creationDateString+
				'\n\n- Last modified: '+
				lastModifiedString);
		}

		function changeTitle(){
			let newTitle = prompt("New title:",note.title);
			if(newTitle!=null&&newTitle.trim()!=""){
				if(newTitle.length>150){
					alert("Less than 150 characters.");
					return;
				}
				newTitle=newTitle.trim();
				db.collection('notes').doc(route.params.noteID).update({
					title: newTitle,
				}).then(response => {
					note.title = newTitle;
					document.getElementById("noteTitle").innerHTML=newTitle;
				}).catch(error => {
					alert('There was an error');
				});
			}
		}

		function favoriteButton(){
			let favorite_icon = document.getElementById("favorite_icon");
			db.collection('notes').doc(route.params.noteID).update({
				favorite: favorite_icon.innerHTML=="star_border",
			}).then(response => {
				//Change the icon and save it into the favorite notes
				if(favorite_icon.innerHTML=="star"){
					favorite_icon.innerHTML="star_border";
					let mirkoDebil = localStorage.getItem("favoriteNotes").split(" ");
					mirkoDebil.splice(mirkoDebil.indexOf(route.params.noteID),1);
					while(alwaysTrue){//Delete the empty items
						if(mirkoDebil.indexOf("")!=-1){
							mirkoDebil.splice(mirkoDebil.indexOf(""),1);
						} else {
							break;
						}
					}
					mirkoDebil=mirkoDebil.filter((item,index)=>mirkoDebil.indexOf(item)===index);//Remove duplicated items
					localStorage.setItem("favoriteNotes",mirkoDebil.join(" "));
				} else {
					favorite_icon.innerHTML="star";
					//Save it into the favorite notes
					if(localStorage.getItem("favoriteNotes")==null||localStorage.getItem("favoriteNotes")==""){
						localStorage.setItem("favoriteNotes",route.params.noteID);
					} else {
						let renzitoInutil = localStorage.getItem("favoriteNotes").split(" ");
						if(renzitoInutil.indexOf(route.params.noteID)==-1){
							renzitoInutil.push(route.params.noteID);
						}
						while(alwaysTrue){//Delete the empty items
							if(renzitoInutil.indexOf("")!=-1){
								renzitoInutil.splice(renzitoInutil.indexOf(""),1);
							} else {
								break;
							}
						}
						renzitoInutil=renzitoInutil.filter((item,index)=>renzitoInutil.indexOf(item)===index);//Remove duplicated items
						localStorage.setItem("favoriteNotes",renzitoInutil.join(" "));
					}
				}
			}).catch(error => {
				alert('There was an error');
			});
		}
		
		function showDropdownNote(){
			let dropdownIcon = document.getElementById("dropdown_icon");
			if(document.getElementById("dropdown-content-note").style.display=="block"){
				dropdownIcon.innerHTML="arrow_drop_down";
				document.getElementById("dropdown-content-note").style.display="none";
			} else {
				dropdownIcon.innerHTML="arrow_drop_up";
				document.getElementById("dropdown-content-note").style.display="block";
			}
		}

		const replaceWord=()=>{
			showDropdownNote();
			const replacedWord=prompt("Word to replace:");
			if(replacedWord==null)return;
			const word=prompt("Word:");
			if(word==null)return;
			const body=document.getElementById("noteBody").value.trim();
			const newBody=body.replaceAll(replacedWord,word);
			document.getElementById("noteBody").value=newBody;
		}

		const setCorrectHeight=async()=>{
			setTimeout(()=>{
				//Get the nav height
				let navHeight = document.getElementById("theNav").offsetHeight;
				//Set the correct height for the body (height - nav height)
				document.getElementById("noteBody").style.height = (window.innerHeight-navHeight-25).toString() + "px";
			},250);
		}

		// Speech to text
		const microVisible=ref(localStorage.getItem("microVisible")=="true");
		const displayMicro=()=>monkey.displayMicro(selectLang,microVisible);
		let microPressed = false;
		const setMicroPressed=(boolValue)=>microPressed=boolValue;
		const speechToText=()=>monkey.speechToText(dark,microPressed,setMicroPressed);
		const selectLang=ref(false);
		const setLanguage=()=>monkey.setLanguage(document.getElementById('language'));
		const changeLang=()=>monkey.changeLang(selectLang);

		// Shortcuts
		const isKeyPressed = {
			'Alt': false,
			'v': false,
		}
		onMounted(()=>{
			document.onkeydown=keyDownEvent=>{
				isKeyPressed[keyDownEvent.key] = true;
				if(isKeyPressed["Control"])isKeyPressed["Alt"]=false;
				if(isKeyPressed["Alt"]&&isKeyPressed["v"]){
					if(microVisible.value){
						speechToText();
					} else {
						displayMicro();
					}
				}
				if(isKeyPressed["Alt"]&&isKeyPressed["l"]&&microVisible.value)changeLang();
				if(isKeyPressed["Alt"]&&isKeyPressed["x"]&&microVisible.value)displayMicro();
				if(keyDownEvent.key!="Alt")isKeyPressed["Alt"]=false;
				if(isKeyPressed["Escape"]&&document.getElementById("dropdown-content-note").style.display=="block")showDropdownNote();
			}
			document.onkeyup = (keyUpEvent) => {
				isKeyPressed[keyUpEvent.key] = false;
			};
		});
		onUnmounted(()=>{
			document.onkeydown = null;
			document.onkeyup = null;
		});

		return {
			monkey,
			speechToText,
			displayMicro,
			microVisible,
			changeLang,
			selectLang,
			setLanguage,
			favoriteButton,
			showDropdownNote,
			closeWithoutSaving,
			displayNoteInformation,
			changeTitle,
			noteReady,
			readAloud,
			exportAsTxt,
			replaceWord,
			dark,
		}
	}
}

</script>

<style scoped>
	@import '../styles/style_for_notes.css';
	@import '../styles/nav.css';
</style>