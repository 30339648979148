<template>
<nav :class="dark?'darkCard':''">
	<div>
		<i @click="$router.go(-1)" class="material-icons" aria-hidden="true">arrow_back</i>
		<h2></h2>
	</div>
</nav>
<Modal :isLoading="true" :isVisible="loading"/>
<br>
<SelectFolder :foldersToAvoid="foldersToAvoid" :isVisible="selectFoder" :selectFolderID="selectFolderID" :close="close" :dark="dark" :initialFolderID="initialFolderID"/>
<div class="contentPadding" :style="dark?'color:white;':''">
	<FolderCard v-for="(folder,i) in folders" @click="switchFolder(i)" :key="i" :selectable="true" :selected="selectedFolders[i]" :dark="dark">{{folder.name}}</FolderCard>
	<NoteCard v-for="(note,i) in notes" @click="switchNote(i)" :key="i" :selectable="true" :selected="selectedNotes[i]" :dark="dark">{{note.title}}</NoteCard>
</div>
<div style="display:flex;justify-content:flex-end;">
	<div class="tooltip">
		<div @click="buttonPressed()" class="circularButton" :style="dark?'background-color:rgb(72,72,72);':'background-color:rgb(255,194,13);'">
			<i :style="dark?'color:white;':'color:black;'" class="material-icons" aria-hidden="true">{{isCopy?'content_copy':'content_cut'}}</i>
		</div>
		<span class="tooltiptext">{{isCopy?'Copy':'Cut'}}</span>
	</div>
</div>
</template>

<script>
import {reactive,ref} from "vue";
import {useRoute} from 'vue-router';
import router from '../router/router.js';
import Localbase from 'localbase';
import FolderCard from '../components/FolderCard.vue';
import NoteCard from '../components/NoteCard.vue';
import SelectFolder from '../components/SelectFolder.vue';
import Modal from '../components/Modal.vue';

export default {
  name: 'SelectItems',
  components: {FolderCard,NoteCard,Modal,SelectFolder},
  setup(props,context){

		const loading=ref(false);
		let alwaysTrue=true;

		const db=new Localbase('monkeynote');

		const dark=localStorage.getItem('darkTheme')=="true";
		const route=useRoute();
		const isCopy=route.params.process=="copy";
		let foldersToAvoid=[];
		let folder;
		const folders=reactive([]);
		const notes=reactive([]);
		const selectedNotes=reactive([]);
		const selectedFolders=reactive([]);
		const selectedItem=sessionStorage.getItem('selectedItem');
		const initialFolderID=route.params.folderID=='mainfolder'?null:route.params.folderID;

		const selectInitialItem=()=>{
			if(selectedItem.split(':')[0]=='folder'){
				folders.forEach((folder,index)=>{
					if (folder.id==selectedItem.split(':')[1]) {
						selectedFolders[index]=true;
					}
				});
			} else {
				notes.forEach((note,index)=>{
					if (note.id==selectedItem.split(':')[1]) {
						selectedNotes[index]=true;
					}
				});
			}
		}

		const fetchMainFolderContent=async()=>{
			let mainFolderFolderIDs;
			let mainFolderNoteIDs;
			//Get the list of main folder folder IDs
			let mainFolderFolderIDsAsString = localStorage.getItem("mainFolderFolders");
			if(mainFolderFolderIDsAsString!=null&&mainFolderFolderIDsAsString.trim()!=""){
				mainFolderFolderIDs=mainFolderFolderIDsAsString.split(" ");
			} else {
				mainFolderFolderIDs = [];
			}
			//Get the list of main folder note IDs
			let mainFolderNoteIDsAsString = localStorage.getItem("mainFolderNotes");
			if(mainFolderNoteIDsAsString!=null&&mainFolderNoteIDsAsString.trim()!=""){
				mainFolderNoteIDs=mainFolderNoteIDsAsString.split(" ");
			} else {
				mainFolderNoteIDs = [];
			}
			//Get the list of main folder folders
			for (let i = 0; i < mainFolderFolderIDs.length; i++) {
				try {
					let x = await db.collection("folders").doc(mainFolderFolderIDs[i]).get();
					folders.push({id:mainFolderFolderIDs[i],name:x.name});
				} catch(e) {
					//Delete the ID
				}
			}
			//Get the list of main folder notes
			for (let i = 0; i < mainFolderNoteIDs.length; i++) {
				try {
					let x = await db.collection("notes").doc(mainFolderNoteIDs[i]).get();
					notes.push({id:mainFolderNoteIDs[i],title:x.title});
				} catch(e) {
					//Delete the ID
				}
			}
			selectInitialItem();
		}

		const fetchFolderContent=async()=>{
			if(route.params.folderID=='mainfolder'){
				fetchMainFolderContent();
				return;
			}
			let foldersRaw = [];
			let notesRaw = [];
			//Get the folder
			folder = await db.collection("folders").doc(route.params.folderID).get();
			//Set the folder name
			document.getElementsByTagName("h2")[0].innerHTML=folder.name;
			//Get folder IDs
			let foldersIDs;
			if(folder.foldersID.trim()==""){
				foldersIDs = [];
			} else {
				foldersIDs = folder.foldersID.split(" ");
			}
			//Get folders
			for (let i = foldersIDs.length - 1; i >= 0; i--) {
				let x = await db.collection("folders").doc(foldersIDs[i]).get();
				foldersRaw.push({id:foldersIDs[i],name:x.name});
				selectedFolders.push(false);
			}
			//Get notes IDs
			let notesIDs;
			if(folder.notesID.trim()==""){
				notesIDs = [];
			} else {
				notesIDs = folder.notesID.split(" ");
			}
			//Get notes
			for (let i = notesIDs.length - 1; i >= 0; i--) {
				let x = await db.collection("notes").doc(notesIDs[i]).get();
				notesRaw.push({id:notesIDs[i],title:x.title});
				selectedNotes.push(false);
			}
			foldersRaw.forEach(function(element,index){
				folders.push(element);
			});
			notesRaw.forEach(function(element,index){
				notes.push(element);
			});
			selectInitialItem();
		}
		fetchFolderContent();

		const switchFolder=(i)=>selectedFolders[i]=!selectedFolders[i];
		const switchNote=(i)=>selectedNotes[i]=!selectedNotes[i];

		const copyFolder=async(folderID,destination)=>{
			//Get the folder to be copied
			let folderToBeCopied = await db.collection('folders').doc(folderID).get();
			//Add a new Folder into the destination with the same name
			let newFolder;
			if(destination==null){
				let response = await db.collection('folders').add({
					parentID: null,
					name: folderToBeCopied.name,
					notesID:"",
					foldersID:"",
					favorite:false,
				});
				//Add the key into the main folder folders ID list
				let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
				let mainFolderFoldersAsString = localStorage.getItem("mainFolderFolders");
				if(mainFolderFoldersAsString==null){
					localStorage.setItem("mainFolderFolders",key);
				} else {
					let y = mainFolderFoldersAsString.split(" ");
					y.push(key);
					while(alwaysTrue){//Delete the empty items
						if(y.indexOf("")!=-1){
							y.splice(y.indexOf(""),1);
						} else {
							break;
						}
					}
					localStorage.setItem("mainFolderFolders",y.join(" "));
				}
				//Add the key into the folder itself
				await db.collection('folders').doc(key).update({id:key});
				//Get the instance
				newFolder = await db.collection('folders').doc(key).get();
			} else {
				let response = await db.collection('folders').add({
					parentID: destination,
					name: folderToBeCopied.name,
					notesID:"",
					foldersID:"",
					favorite:false,
				});
				//Get the destination folder instance
				let destinationFolder = await db.collection('folders').doc(destination).get();
				//Add the new folder key into its parent folders ID list
				let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
				if(destinationFolder.foldersID.trim()==""){
					await db.collection('folders').doc(destination).update({
						foldersID: key,
					});
				} else {
					let a = destinationFolder.foldersID.split(" ");
					a.push(key);
					await db.collection('folders').doc(destination).update({
						foldersID: a.join(" "),
					});
				}
				//Add the key to the folder itself
				await db.collection('folders').doc(key).update({id:key});
				//Get the instance
				newFolder = await db.collection('folders').doc(key).get();
			}
			//Copy all the notes and folders of the folder to be copied into the new folder
			let foldersInside = [];
			let notesInside = [];
			//Get the folders
			if(folderToBeCopied.foldersID.trim()!=''){
				for(let c=0;c<folderToBeCopied.foldersID.split(' ').length;c++){
					foldersInside.push(await db.collection('folders').doc(folderToBeCopied.foldersID.split(' ')[c]).get());
				}
			}
			//Get the notes
			if(folderToBeCopied.notesID.trim()!=''){
				for(let c=0;c<folderToBeCopied.notesID.split(' ').length;c++){
					notesInside.push(await db.collection('notes').doc(folderToBeCopied.notesID.split(' ')[c]).get());
				}
			}
			//Copy the notes into the new folder
			if(notesInside.length>0){
				for(let c=0;c<notesInside.length;c++){
					//Insert a new note in the notes table with the same data and get its ID
					let milliseconds = (new Date()).getTime();//milliseconds since epoch
					//Create the new note
					let response = await db.collection('notes').add({
						folderID: newFolder.id,
						title: notesInside[c].title,
						body: notesInside[c].body,
						favorite:false,
						creationDate: milliseconds,
						lastModified: milliseconds,
					});
					let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
					//Add the key to the note itself
					await db.collection('notes').doc(key).update({id:key});
					//Add the new note ID into the list of notes IDs of the new folder
					let x = await db.collection('folders').doc(newFolder.id).get();
					let notesID = x.notesID.trim()==''?[]:x.notesID.split(' ');
					notesID.push(key);//add the new note id
					await db.collection('folders').doc(x.id).update({
						notesID: notesID.join(' '),
					});
				}
			}
			//Copy all the folders into the new folder
			if(foldersInside.length>0){
				for(let c=0;c<foldersInside.length;c++){
					await copyFolder(foldersInside[c].id,newFolder.id);
				}
			}
		}
		const moveFolderToMainFolder=async(id)=>{
			if(route.params.folderID=='mainfolder')return;
			//Remove the id from the previous folder
			let z = folder.foldersID.trim().split(' ');
			z.splice(z.indexOf(id),1);
			folder.foldersID=z.join(' ');
			await db.collection('folders').doc(folder.id).update({foldersID:z.join(' ')});
			//Set the parentID as null
			await db.collection('folders').doc(id).update({parentID:null});
			//Save it into the main folder folders IDs
			let mainFolderFolderIDs;
			let mainFolderFolderIDsAsString = localStorage.getItem("mainFolderFolders");
			if(mainFolderFolderIDsAsString!=null&&mainFolderFolderIDsAsString.trim()!=""){
				mainFolderFolderIDs=mainFolderFolderIDsAsString.split(" ");
			} else {
				mainFolderFolderIDs = [];
			}
			mainFolderFolderIDs.push(id);
			localStorage.setItem("mainFolderFolders",mainFolderFolderIDs.join(' '));
		}
		const moveFolderTo=async(id,destinationID)=>{
			if(destinationID==route.params.folderID)return;
			//Remove the id from the previous folder
			if(route.params.folderID=='mainfolder'){
				let y = localStorage.getItem('mainFolderFolders').split(' ');
				y.splice(y.indexOf(id),1);
				localStorage.setItem('mainFolderFolders',y.join(' '));
			} else {
				let z = folder.foldersID.trim().split(' ');
				z.splice(z.indexOf(id),1);
				folder.foldersID=z.join(' ');
				await db.collection('folders').doc(folder.id).update({foldersID:z.join(' ')});
			}
			//Set the parentID as destinationID
			await db.collection('folders').doc(id).update({parentID:destinationID});
			//Save the ID into the destination
			let parentFolder = await db.collection('folders').doc(destinationID).get();
			if(parentFolder.foldersID.trim()!=''){
				let x = parentFolder.foldersID.split(' ');
				x.push(id);
				await db.collection('folders').doc(destinationID).update({foldersID:x.join(' ')});
			} else {
				await db.collection('folders').doc(destinationID).update({foldersID:id});
			}
		}
		const copyNoteToMainFolder=async(id)=>{
			//Get the note
			let note = await db.collection('notes').doc(id).get();
			//Create the new note
			let milliseconds = (new Date()).getTime();//milliseconds since epoch
			let response = await db.collection('notes').add({
				folderID: null,
				title: note.title,
				body: note.body,
				favorite:false,
				creationDate: milliseconds,
				lastModified: milliseconds,
			});
			let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
			await db.collection('notes').doc(key).update({id:key});
			//Save the new note ID into the main folder note IDs
			let mainFolderNotesAsString = localStorage.getItem("mainFolderNotes");
			if(mainFolderNotesAsString==null){
				localStorage.setItem("mainFolderNotes",key);
			} else {
				let y = mainFolderNotesAsString.split(" ");
				y.push(key);
				while(alwaysTrue){//Delete the empty items
					if(y.indexOf("")!=-1){
						y.splice(y.indexOf(""),1);
					} else {
						break;
					}
				}
				localStorage.setItem("mainFolderNotes",y.join(" "));
			}
		}
		const copyNoteTo=async(id,destinationID)=>{
			//Get the note
			let note = await db.collection('notes').doc(id).get();
			//Create the new note
			let milliseconds = (new Date()).getTime();//milliseconds since epoch
			let response = await db.collection('notes').add({
				folderID: destinationID,
				title: note.title,
				body: note.body,
				favorite:false,
				creationDate: milliseconds,
				lastModified: milliseconds,
			});
			let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
			await db.collection('notes').doc(key).update({id:key});
			//Save the new note ID into the destination
			let parentFolder = await db.collection('folders').doc(destinationID).get();
			if(parentFolder.notesID.trim()!=''){
				let x = parentFolder.notesID.split(' ');
				x.push(key);
				await db.collection('folders').doc(destinationID).update({notesID:x.join(' ')});
			} else {
				await db.collection('folders').doc(destinationID).update({notesID:key});
			}
		}
		const moveNoteToMainFolder=async(id)=>{
			if(route.params.folderID=='mainfolder')return;
			//Remove the id from the previous location
			let x = folder.notesID.trim().split(' ');
			x.splice(x.indexOf(id),1);
			folder.notesID=x.join(' ');
			await db.collection('folders').doc(folder.id).update({notesID:x.join(' ')});
			//Set the folderID as null
			await db.collection('notes').doc(id).update({folderID:null});
			//Save it into the main folder note IDs
			let mainFolderNotesAsString = localStorage.getItem("mainFolderNotes");
			if(mainFolderNotesAsString==null){
				localStorage.setItem("mainFolderNotes",id);
			} else {
				let y = mainFolderNotesAsString.split(" ");
				y.push(id);
				while(alwaysTrue){//Delete the empty items
					if(y.indexOf("")!=-1){
						y.splice(y.indexOf(""),1);
					} else {
						break;
					}
				}
				localStorage.setItem("mainFolderNotes",y.join(" "));
			}
		}
		const moveNoteTo=async(id,destinationID)=>{
			if(destinationID==route.params.folderID)return;
			//Remove the id from the previous location
			if(route.params.folderID=='mainfolder'){
				let y = localStorage.getItem('mainFolderNotes').split(' ');
				y.splice(y.indexOf(id),1);
				localStorage.setItem('mainFolderNotes',y.join(' '));
			} else {
				let x = folder.notesID.split(' ');
				x.splice(x.indexOf(id),1);
				folder.notesID=x.join(' ');
				await db.collection('folders').doc(folder.id).update({notesID:x.join(' ')});
			}
			//Set the folderID as the destionation ID
			await db.collection('notes').doc(id).update({folderID:destinationID});
			//Save the ID into the destination
			let parentFolder = await db.collection('folders').doc(destinationID).get();
			if(parentFolder.notesID.trim()!=''){
				let x = parentFolder.notesID.split(' ');
				x.push(id);
				await db.collection('folders').doc(destinationID).update({notesID:x.join(' ')});
			} else {
				await db.collection('folders').doc(destinationID).update({notesID:id});
			}
		}

		const buttonPressed=()=>{
			//Get the selected folders to avoid showing them
			while (foldersToAvoid.length) {
				foldersToAvoid.pop();
			}
			for (let i = 0; i < selectedFolders.length; i++) {
				if(selectedFolders[i]){
					foldersToAvoid.push(folders[i].id);
				}
			}
			selectFoder.value=true;
		}
		const selectFoder=ref(false);
    const selectFolderID=async(folderID)=>{
      close();
      if(folderID!==undefined&&(folderID===null||folderID.trim()!='')){
				try {
					loading.value=true;
					await select(folderID);
					router.go(-1);
				} catch(e) {
					alert('An error happened');
					router.go(-1);
				}
      }
		}
    const close=()=>selectFoder.value=false;

		const select=async(folderID)=>{
			for (let i = 0; i < selectedFolders.length; i++) {
				if(selectedFolders[i]){
					if(isCopy){
						await copyFolder(folders[i].id,folderID);
					}	else {
						if(folderID){
							await moveFolderTo(folders[i].id,folderID);
						} else {
							await moveFolderToMainFolder(folders[i].id);
						}
					}
				}
			}
			for (let i = 0; i < selectedNotes.length; i++) {
				if(selectedNotes[i]){
					if(isCopy){
						if(folderID){
							await copyNoteTo(notes[i].id,folderID);
						} else {
							await copyNoteToMainFolder(notes[i].id);
						}
					}	else {
						if(folderID){
							await moveNoteTo(notes[i].id,folderID);
						} else {
							await moveNoteToMainFolder(notes[i].id);
						}
					}
				}
			}
		}

		return {
			dark,
			initialFolderID,
			folders,
			notes,
			isCopy,
			selectedFolders,
			switchFolder,
			selectedNotes,
			switchNote,
			select,
			loading,
			selectFoder,
			selectFolderID,
			close,
			buttonPressed,
			foldersToAvoid,
		}
  }
}
</script>

<style scoped>
	.tooltip {
		border: none;
		position: relative;
		display: inline-block;
	}
	.tooltip .tooltiptext {
		visibility: hidden;
		width: 120px;
		background-color: black;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px 0;
		position: absolute;
		z-index: 1;
		top: 50px;
		right: 100%;
	}
	.tooltip .tooltiptext::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 100%;
		margin-top: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: transparent transparent transparent black;
	}
	.tooltip:hover .tooltiptext {
		visibility: visible;
	}
	nav {
		background-color: rgb(121,85,72);
		color: white;
		font-size: 1em;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		z-index: 1;
		/*Flex box*/
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
	}
	nav div {
		display: flex;
		align-items: center;
	}
	nav i {
		cursor: pointer;
		padding: 15px;
	}
	nav i:hover {
		background-color: rgb(88,62,52);
	}
	nav div h2 {
		padding: 0;
		margin: 0;
	}
</style>