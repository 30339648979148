<template>
<nav id="theNav" :class="dark?'darkCard':''">
	<div>
		<i @click="goBack()" class="material-icons" aria-hidden="true">arrow_back</i>
		<h2 style="cursor:pointer;" @click="changeFolderName()">{{folderHistory[folderHistory.length-1].folderName}}</h2>
	</div>
</nav>
<br>
<div class="contentPadding" :style="dark?'color:white;':''">

	<Modal :isLoading="isLoading" :isVisible="modalVisible" :closeModal="toggleModal" :options="options" :functions="functions" :dark="dark" :customText="customText"/>

	<h3 v-if="folderHistory[folderHistory.length-1].folders.length==0&&folderHistory[folderHistory.length-1].notes.length==0" class="titleText noContent">No content</h3>
	<template v-else>
		<FolderCard v-for="(folder,index) in folderHistory[folderHistory.length-1].folders" @click="goTo(folder.id,folder.name)" @contextmenu.prevent="folderMenu(folder.id)" :key="index" :id="folder.id" :dark="dark" :web="true">{{folder.name}}</FolderCard>
		<NoteCard v-for="(note,index) in folderHistory[folderHistory.length-1].notes" @click="saveFolderHistory(note.title)" @contextmenu.prevent="noteMenu(note.id)" :key="index" :id="note.id" :dark="dark" :web="true">{{note.title}}</NoteCard>
	</template>

</div>
<br>
<div class="folderAddButtons">
	<div @click="addNewFolder()" class="circularButton">
		<i :style="dark?'font-size:1.8em;color:white;':'font-size:1.8em;'" class="material-icons" aria-hidden="true">create_new_folder</i>
	</div>
	<div @click="addNewNote()" class="circularButton">
		<i :style="dark?'font-size:1.8em;color:white;':'font-size:1.8em;'" class="material-icons" aria-hidden="true">note_add</i>
	</div>
</div>
</template>

<script>
import {reactive,ref,onMounted,onUnmounted} from 'vue';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import router from '../router/router.js';
import FolderCard from '../components/FolderCard.vue';
import NoteCard from '../components/NoteCard.vue';
import Modal from '../components/Modal.vue';

export default {
  name:"WebFolder",
  components:{FolderCard,NoteCard,Modal},
  setup(props,context){

		const dark=localStorage.getItem('darkTheme')=="true";

		let alwaysTrue=true;
		const customText=ref('');

		const db=firebase.firestore();
		let uid;

		const folderIndex=ref(0);
		const folderHistory=reactive([]);
		folderHistory.push({folderName:'',path:'',folders:[],notes:[]});

		const modalVisible=ref(false);
		const isLoading=ref(false);
		const options=reactive([]);
		const functions=reactive([]);

		const toggleModal=()=>modalVisible.value=!modalVisible.value;

		const theFolder=()=>folderHistory[folderHistory.length-1];

		const saveFolderHistory=(title)=>{
			sessionStorage.setItem('savedFolderHistory',JSON.stringify(folderHistory));
			sessionStorage.setItem('webNoteTitle',title);
			sessionStorage.setItem('webFolderPath',theFolder().path);
		}

		const recoverFolderHistory=(savedFolderHistory)=>{
			const x = JSON.parse(savedFolderHistory);
			for (let i = 0; i < x.length; i++) {
				folderHistory[i]=x[i];
			}
		}

		const goTo=async(webFolderID,webFolderName)=>{
			customText.value='📁🔍🐵';
			isLoading.value=true;
			modalVisible.value=true;
			try {
				//Get the web folder
				let folder;
				if(folderHistory.length==1){
					folder = await db.collection(theFolder().path).doc(webFolderID).collection('content').get();
				} else {
					folder = await db.collection(theFolder().path+'/content/'+webFolderID+'/content').get();
				}
				//Get the folders and notes
				let notes=[];
				let folders=[];
				folder.docs.forEach((doc)=>{
					if(doc.id=='MonkeyNotes'){
						for (const [webNoteID, webNoteTitle] of Object.entries(doc.data())) {
							notes.push({id:webNoteID,title:webNoteTitle});
						}
					} else {
						folders.push({id:doc.id,name:doc.data()['folderName']});
					}
				});
				//Save the history snapshot
				folderHistory.push({
					folderName: webFolderName,
					path: theFolder().path+(folderHistory.length==1?'/':'/content/')+webFolderID,
					notes: notes,
					folders: folders
				});
				isLoading.value=false;
				modalVisible.value=false;
				customText.value='';
			} catch(e) {
				alert('An error happened');
				isLoading.value=false;
				modalVisible.value=false;
				customText.value='';
			}
		}
		
		const goBack=()=>{
			if(folderHistory.length==1){
				history.back();
				return;
			}
			folderHistory.pop();
		}

		const fetchFolderContent=async()=>{

			//See if there is a saved path
			const savedFolderHistory = sessionStorage.getItem('savedFolderHistory');
			if(savedFolderHistory&&savedFolderHistory!=''){
				recoverFolderHistory(savedFolderHistory);
				sessionStorage.setItem('savedFolderHistory','');
				//See if the title of the note have been changed
				const t = sessionStorage.getItem('changesMadeInTitle');
				sessionStorage.setItem('changesMadeInTitle','');
				if(t!=null&&t!=undefined&&t.trim()!=''){
					theFolder().notes.forEach((note,index)=>{
						if(note.id==t.substring(0,t.indexOf(':'))){
							theFolder().notes[index].title=t.substring(t.indexOf(':')+1,t.length);
						}
					});
				}
				return;
			}

			//Get the main web folder
			let folder = await db.collection('users/'+uid+'/WebFolder').get();

			//Get folders and notes of the main web folder
			let notes = [];
			let folders = [];
			folder.docs.forEach((doc)=>{
				if(doc.id=='MonkeyNotes'){
					for (const [webNoteID, webNoteTitle] of Object.entries(doc.data())) {
						notes.push({id:webNoteID,title:webNoteTitle});
					}
				} else {
					folders.push({id:doc.id,name:doc.data()['folderName']});
				}
			});

			//Save the history snapshot
			folderHistory[0]={
				folderName: 'Main Web Folder',
				path: 'users/'+uid+'/WebFolder',
				notes: notes,
				folders: folders
			};

		}
		firebase.auth().onAuthStateChanged((user)=>{
			if(user&&uid==null){
				uid=user.uid;
				(async()=>{
					isLoading.value=true;
					modalVisible.value=true;
					await fetchFolderContent(user.uid);
					isLoading.value=false;
					modalVisible.value=false;
				})();
			}
		});


		const changeFolderName=()=>{
			if(folderHistory.length==1)return;//If it's the main folder
			let newFolderName = prompt("New folder name:",theFolder().folderName);
			if(newFolderName!=null&&newFolderName.trim()!=""){
				if(newFolderName.length>150){
					alert("Less than 150 characters.");
					return;
				}
				newFolderName=newFolderName.trim();
				const x = theFolder().path;
				const thePath = x.substring(0,x.lastIndexOf('/'));
				const theDoc = x.substring(x.lastIndexOf('/')+1,x.length);
				db.collection(thePath).doc(theDoc).update({
					folderName: newFolderName,
				}).then(response => {
					//Update the nav bar
					theFolder().folderName=newFolderName;
					//Save the changes in the previous snapshot of the folder history
					const folderID = theFolder().path.substring(theFolder().path.lastIndexOf('/')+1,theFolder().path.length);
					folderHistory[folderHistory.length-2].folders.forEach((folder,index)=>{
						if(folder.id==folderID)folderHistory[folderHistory.length-2].folders[index].name=newFolderName;
					});
				}).catch(error => {
					alert('There was an error');
				});
			}
		}
		
		const addNewFolder=async()=>{
			let name = prompt("New Folder:");
			if (name!=null&&name.trim()!="") {
				if(folderHistory.length==1){
					db.collection('users').doc(uid).collection('WebFolder').add({
						folderName:name,
					}).then(x=>{
						theFolder().folders.push({id:x.id,name:name});
					}).catch(e=>{
						alert('An error happened');
					});
				} else {
					db.collection(theFolder().path+'/content').add({
						folderName:name,
					}).then(x=>{
						theFolder().folders.push({id:x.id,name:name});
					}).catch(e=>{
						alert('An error happened');
					});
				}
			}
		}
		
		const addNewNote=async()=>{
			let title = prompt("New Note:");
			if (title!=null&&title.trim()!="") {
				let x = await db.collection('users').doc(uid).collection('WebNotes').add({text:''});
				if(folderHistory.length==1){
					if(theFolder().notes.length==0){
						await db.collection('users').doc(uid).collection('WebFolder').doc('MonkeyNotes').set({[x.id]:title});
					} else {
						await db.collection('users').doc(uid).collection('WebFolder').doc('MonkeyNotes').update({[x.id]:title});
					}
				} else {
					if(theFolder().notes.length==0){
						await db.collection(theFolder().path+'/content').doc('MonkeyNotes').set({[x.id]:title});
					} else {
						await db.collection(theFolder().path+'/content').doc('MonkeyNotes').update({[x.id]:title});
					}
				}
				//Update the changes in the UI
				theFolder().notes.push({id:x.id,title:title});
			}
			saveWebNoteAlert();
		}

		const folderMenu=id=>{
			//Clear the previous content
			while (options.length) {
				options.pop();
			}
			//Add the options
			options.push("Delete folder");
			//Clear the previous content
			while (functions.length) {
				functions.pop();
			}
			//Add the options
			functions.push(async()=>{
				isLoading.value=true;
				if(await deleteFolder(id)){
					let index;
					theFolder().folders.forEach((folder,i)=>{
						if(folder.id==id)index=i;
					});
					theFolder().folders.splice(index,1);
				}
				isLoading.value=false;
				modalVisible.value=false;
			});
			//Show the modal
			toggleModal();
		}

		const noteMenu=id=>{
			//Clear the previous content
			while (options.length) {
				options.pop();
			}
			//Add the options
			options.push("Delete note");
			//Clear the previous content
			while (functions.length) {
				functions.pop();
			}
			//Add the options
			functions.push(async()=>{
				isLoading.value=true;
				if(await deleteNote(id)){
					let index;
					theFolder().notes.forEach((note,i)=>{
						if(note.id==id)index=i;
					});
					theFolder().notes.splice(index,1);
				}
				isLoading.value=false;
				modalVisible.value=false;
			});
			//Show the modal
			toggleModal();
		}

		const deleteCollection=async(ref,bodyIDsToDelete)=>{
			//Delete also the notes in the top of the hierarchy (directly inside ref)
			if(bodyIDsToDelete.length==0){
				try {
					const x = await ref.doc('MonkeyNotes').get();
					for (const [bodyID,title] of Object.entries(x.data())) {
						bodyIDsToDelete.push(bodyID);
					}
				} catch(e) {
					console.log('Empty doc 1');
				}
			}

			const z = await ref.get();
			const docs = z.docs;
			for(let c=0;c<docs.length;c++){
				try {
					try {
						//Save the bodyIDs to delete
						const w=await docs[c].ref.collection('content').doc('MonkeyNotes').get();
						for (const [bodyID,title] of Object.entries(w.data())) {
							bodyIDsToDelete.push(bodyID);
						}
					} catch(e) {
						console.log('Empty doc 2');
					}
					await deleteCollection(docs[c].ref.collection('content'),bodyIDsToDelete);
				} catch(e) {
					console.log('Empty doc 3');
				}
				await docs[c].ref.delete();
			}
		}

		const deleteBodyIDs=async(bodyIDsToDelete,uid)=>{
			for(let c=0;c<bodyIDsToDelete.length;c++){
				await db.collection('users').doc(uid).collection('WebNotes').doc(bodyIDsToDelete[c]).delete();
			}
		}

		const deleteFolder=async(folderID)=>{
			if(confirm('Are you sure you want to delete the folder?')){
				if(confirm('Are you sure? There will be no way to recover the content')){
					let bodyIDsToDelete = [];
					let thePath;
					if(folderHistory.length==1){
						thePath = theFolder().path;
					} else {
						thePath = theFolder().path+'/content';
					}
          await deleteCollection(db.collection(thePath).doc(folderID).collection('content'),bodyIDsToDelete);
          await deleteBodyIDs(bodyIDsToDelete,uid);
          await db.collection(thePath).doc(folderID).delete();
          return true;
				}
			}
			return false;
		}

		const deleteNote=async(noteID)=>{
			if(confirm('Are you sure you want to delete the note?')){
				if(confirm('Are you sure? There will be no way to recover the content')){
					modalVisible.value=true;
					isLoading.value=true;
					try {
						await db.collection('users').doc(uid).collection('WebNotes').doc(noteID).delete();
						if(folderHistory.length==1){
							await db.collection('users').doc(uid).collection('WebFolder').doc('MonkeyNotes').update({[noteID]:firebase.firestore.FieldValue.delete()});
						} else {
							await db.collection(theFolder().path+'/content').doc('MonkeyNotes').update({[noteID]:firebase.firestore.FieldValue.delete()});
						}
						theFolder().notes.forEach((note,i)=>{
							if(note.id==noteID){
								theFolder().notes.splice(i,1);
							}
						});
						modalVisible.value=false;
						isLoading.value=false;
					} catch(e) {
						alert('An error happened');
						modalVisible.value=false;
						isLoading.value=false;
					}
				}
			}
		}

		const saveWebNoteAlert=()=>{
			const x = localStorage.getItem('saveWebNoteAlert');
			if(x==undefined||x==null){
				localStorage.setItem('saveWebNoteAlert','1');
				alert('To save web note changes, close it before leaving the app.');
				return;
			} else {
				if(x=='1'){
					localStorage.setItem('saveWebNoteAlert','2');
					alert('To save web note changes, close it before leaving the app.');
					return;
				}
			}
		}

		// Shortcuts
		const isKeyPressed = { 
			'f': false,//folder
			'n': false,//Note
			'c': false,//Create
			//Indexes
			'1': false,
			'2': false,
			'3': false,
			'4': false,
			'5': false,
			'6': false,
			'7': false,
			'8': false,
			'9': false,
		}
		onMounted(()=>{
			document.onkeydown = (keyDownEvent) => {
				try {
					isKeyPressed[keyDownEvent.key] = true;
					//The shortcuts
					if(isKeyPressed["0"])goBack();
					if(isKeyPressed["c"]&&isKeyPressed["f"]){
						addNewFolder()
						isKeyPressed['c']=false;
						isKeyPressed['f']=false;
					}
					if(isKeyPressed["c"]&&isKeyPressed["n"]){
						addNewNote()
						isKeyPressed['c']=false;
						isKeyPressed['n']=false;
					}
					if(isKeyPressed["Escape"]){
						modalVisible.value=false;
					}
					if (isKeyPressed["f"]) {
						if(isKeyPressed["1"]){
							let folderToGo=folderHistory[folderHistory.length-1].folders[0];
							goTo(folderToGo.id,folderToGo.name);
						} else if (isKeyPressed["2"]) {
							let folderToGo=folderHistory[folderHistory.length-1].folders[1];
							goTo(folderToGo.id,folderToGo.name);
						} else if (isKeyPressed["3"]) {
							let folderToGo=folderHistory[folderHistory.length-1].folders[2];
							goTo(folderToGo.id,folderToGo.name);
						} else if (isKeyPressed["4"]) {
							let folderToGo=folderHistory[folderHistory.length-1].folders[3];
							goTo(folderToGo.id,folderToGo.name);
						} else if (isKeyPressed["5"]) {
							let folderToGo=folderHistory[folderHistory.length-1].folders[4];
							goTo(folderToGo.id,folderToGo.name);
						} else if (isKeyPressed["6"]) {
							let folderToGo=folderHistory[folderHistory.length-1].folders[5];
							goTo(folderToGo.id,folderToGo.name);
						} else if (isKeyPressed["7"]) {
							let folderToGo=folderHistory[folderHistory.length-1].folders[6];
							goTo(folderToGo.id,folderToGo.name);
						} else if (isKeyPressed["8"]) {
							let folderToGo=folderHistory[folderHistory.length-1].folders[7];
							goTo(folderToGo.id,folderToGo.name);
						} else if (isKeyPressed["9"]) {
							let folderToGo=folderHistory[folderHistory.length-1].folders[8];
							goTo(folderToGo.id,folderToGo.name);
						}
					} else if (isKeyPressed["n"]) {
						if(isKeyPressed["1"]){
							let noteToGo=folderHistory[folderHistory.length-1].notes[0];
							saveFolderHistory(noteToGo.title);
							router.push('/webnote/'+noteToGo.id);
						} else if (isKeyPressed["2"]){
							let noteToGo=folderHistory[folderHistory.length-1].notes[1];
							saveFolderHistory(noteToGo.title);
							router.push('/webnote/'+noteToGo.id);
						} else if (isKeyPressed["3"]){
							let noteToGo=folderHistory[folderHistory.length-1].notes[2];
							saveFolderHistory(noteToGo.title);
							router.push('/webnote/'+noteToGo.id);
						} else if (isKeyPressed["4"]){
							let noteToGo=folderHistory[folderHistory.length-1].notes[3];
							saveFolderHistory(noteToGo.title);
							router.push('/webnote/'+noteToGo.id);
						} else if (isKeyPressed["5"]){
							let noteToGo=folderHistory[folderHistory.length-1].notes[4];
							saveFolderHistory(noteToGo.title);
							router.push('/webnote/'+noteToGo.id);
						} else if (isKeyPressed["6"]){
							let noteToGo=folderHistory[folderHistory.length-1].notes[5];
							saveFolderHistory(noteToGo.title);
							router.push('/webnote/'+noteToGo.id);
						} else if (isKeyPressed["7"]){
							let noteToGo=folderHistory[folderHistory.length-1].notes[6];
							saveFolderHistory(noteToGo.title);
							router.push('/webnote/'+noteToGo.id);
						} else if (isKeyPressed["8"]){
							let noteToGo=folderHistory[folderHistory.length-1].notes[7];
							saveFolderHistory(noteToGo.title);
							router.push('/webnote/'+noteToGo.id);
						} else if (isKeyPressed["9"]){
							let noteToGo=folderHistory[folderHistory.length-1].notes[8];
							saveFolderHistory(noteToGo.title);
							router.push('/webnote/'+noteToGo.id);
						}
					} else {
						let currentFolders=folderHistory[folderHistory.length-1].folders;
						let currentNotes=folderHistory[folderHistory.length-1].notes;
						if(isKeyPressed["1"]){
							if(1<=currentFolders.length){
								goTo(currentFolders[0].id,currentFolders[0].name);
							} else {
								saveFolderHistory(currentNotes[0-currentFolders.length].title);
								router.push('/webnote/'+currentNotes[0-currentFolders.length].id);
							}
						} else if (isKeyPressed["2"]) {
							if(2<=currentFolders.length){
								goTo(currentFolders[1].id,currentFolders[1].name);
							} else {
								saveFolderHistory(currentNotes[1-currentFolders.length].title);
								router.push('/webnote/'+currentNotes[1-currentFolders.length].id);
							}
						} else if (isKeyPressed["3"]) {
							if(3<=currentFolders.length){
								goTo(currentFolders[2].id,currentFolders[2].name);
							} else {
								saveFolderHistory(currentNotes[2-currentFolders.length].title);
								router.push('/webnote/'+currentNotes[2-currentFolders.length].id);
							}
						} else if (isKeyPressed["4"]) {
							if(4<=currentFolders.length){
								goTo(currentFolders[3].id,currentFolders[3].name);
							} else {
								saveFolderHistory(currentNotes[3-currentFolders.length].title);
								router.push('/webnote/'+currentNotes[3-currentFolders.length].id);
							}
						} else if (isKeyPressed["5"]) {
							if(5<=currentFolders.length){
								goTo(currentFolders[4].id,currentFolders[4].name);
							} else {
								saveFolderHistory(currentNotes[4-currentFolders.length].title);
								router.push('/webnote/'+currentNotes[4-currentFolders.length].id);
							}
						} else if (isKeyPressed["6"]) {
							if(6<=currentFolders.length){
								goTo(currentFolders[5].id,currentFolders[5].name);
							} else {
								saveFolderHistory(currentNotes[5-currentFolders.length].title);
								router.push('/webnote/'+currentNotes[5-currentFolders.length].id);
							}
						} else if (isKeyPressed["7"]) {
							if(7<=currentFolders.length){
								goTo(currentFolders[6].id,currentFolders[6].name);
							} else {
								saveFolderHistory(currentNotes[6-currentFolders.length].title);
								router.push('/webnote/'+currentNotes[6-currentFolders.length].id);
							}
						} else if (isKeyPressed["8"]) {
							if(8<=currentFolders.length){
								goTo(currentFolders[7].id,currentFolders[7].name);
							} else {
								saveFolderHistory(currentNotes[7-currentFolders.length].title);
								router.push('/webnote/'+currentNotes[7-currentFolders.length].id);
							}
						} else if (isKeyPressed["9"]) {
							if(9<=currentFolders.length){
								goTo(currentFolders[8].id,currentFolders[8].name);
							} else {
								saveFolderHistory(currentNotes[8-currentFolders.length].title);
								router.push('/webnote/'+currentNotes[8-currentFolders.length].id);
							}
						}
					}
				} catch(e) {
					console.log('Item not found 🐵');
				}
			}
			document.onkeyup = (keyUpEvent) => {
				isKeyPressed[keyUpEvent.key] = false;
			};
		});
		onUnmounted(()=>{
			document.onkeydown = null;
			document.onkeyup = null;
		});

		return {
			customText,
			toggleModal,
			modalVisible,
			options,
			functions,
			folderMenu,
			noteMenu,
			addNewFolder,
			addNewNote,
			changeFolderName,
			folderHistory,
			goTo,
			goBack,
			isLoading,
			saveFolderHistory,
			dark,
		}
  }
}
</script>

<style scoped>
	@import '../styles/nav.css';

	#theNav > div:first-child h2 {
		padding: 0;
		margin: 0;
	}
	.folderAddButtons {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.folderAddButtons	.circularButton {
		margin: 0px;
	}
</style>