<template>
	<h1 class="titleText" :style="dark?'color:white;':'color:black;'">Política de privacidad - DeSotoApp</h1>
	<div class="contentPadding" :style="dark?'color:white;':'color:black;'">
		<p class="bodyText">El presente Política de Privacidad establece los términos en que DeSotoApp usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su aplicación móvil. Esta compañía está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo, esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.</p>
		<h2 class="titleText">Información que es recogida</h2>
		<p class="bodyText">Nuestra Aplicación Móvil podrá recoger información personal, por ejemplo: Nombre, información de contacto como su dirección de correo electrónico e información demográfica. Pero esta recolección de información del usuario es siempre voluntaria por parte de este al momento de crearse una cuenta, ya que son los datos que se le solicita para creársela.</p>
		<h2 class="titleText">Uso de la información recogida</h2>
		<p class="bodyText">Nuestra Aplicación Móvil emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios, de pedidos en caso de que aplique, y mejorar nuestros productos y servicios. Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos productos y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio, estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.</p>
		<p class="bodyText">DeSotoApp está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.</p>
		<h2 class="titleText">Envío de Notificaciones</h2>
		<p class="bodyText">DeSotoApp enviará notificaciones a todos los teléfonos celulares de todos los usuarios que han descargado nuestra aplicación móvil para anunciar noticias que consideremos importantes sobre la política peruana y que tengan alguna relación con Hernando de Soto o el partido Avanza País.</p>
		<h2 class="titleText">Enlaces a Terceros</h2>
		<p class="bodyText">Esta aplicación móvil pudiera contener enlaces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.</p>
		<h2 class="titleText">Control de su información personal</h2>
		<p class="bodyText">En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestra aplicación móvil. Cada vez que se le solicite rellenar un formulario, como el de alta de usuario, puede marcar o desmarcar la opción de recibir información por correo electrónico. En caso de que haya marcado la opción de recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento.</p>
		<p class="bodyText">Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.</p>
		<p class="bodyText">DeSotoApp para almacenar los datos en su cuenta, para la autenticación de usuarios y el servicio de notificaciones, usa los servicios de Firebase y Firebase Firestore.</p>
		<p class="bodyText">DeSotoApp se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.</p>
	</div>
</template>

<script>
	export default {
		name: 'DeSotoAppPrivacyPolicy',
		setup(props,context) {
			const dark = localStorage.getItem('darkTheme')=="true";
			return {dark}
		}
	}
</script>

<style scoped>
	h1 {
		text-align: center;
	}
	h2 {
		text-decoration: underline;
	}
</style>