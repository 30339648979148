<template>
	<Modal :isLoading="true" :isVisible="loading" :dark="dark"/>
	<div id="userPanel">
		<div @click="signOutForSmallScreens()" :style="dark?'background-color:rgb(72,72,72);color:white;':'color:white;'"><p>{{getMonkeyAvatar(email)}}</p></div>
		<div>
			<div id="userButtons">
				<i @click="deleteAccount()" class="material-icons">delete_forever</i>
				<i @click="signOut()" class="material-icons">close</i>
			</div>
			<h2 id="userName" class="titleText unselectable">{{email.split('@')[0]}}</h2>
			<br>
			<div>
				<button @click="$router.push('/webfolder');" :style="dark?'background-color:rgb(72,72,72)':''" class="monkeyButton titleText unselectable">Web Folder</button>
				<button @click="$router.push('/fastnote');" :style="dark?'background-color:rgb(72,72,72)':''" class="monkeyButton titleText unselectable">Fast Note</button>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref } from "vue";
	import firebase from 'firebase/app';
	import 'firebase/auth';
	import 'firebase/firestore';
	import Modal from '../components/Modal.vue';
	export default {
		name: 'UserPanel',
		components: {Modal},
		props: {
			dark: Boolean,
			email: String,
			close: Function,
		},
		setup(props,context){

			const auth = firebase.auth();
			const loading = ref(false);

			const getMonkeyAvatar=(x)=>{
				switch (x[0]) {
					case 'a': return '🎷🐵';
					case 'b': return '🎤🐵';
					case 'c': return '🐵📱';
					case 'd': return '🐵🔭';
					case 'e': return '🍍🐵';
					case 'f': return '🐵🌱';
					case 'g': return '🐵⚽';
					case 'h': return '🐵🔥';
					case 'i': return '🍭🐵';
					case 'j': return '🍾🐵';
					case 'k': return '🐵🍉';
					case 'l': return '🐵🔬';
					case 'm': return '🐵🍌';
					case 'n': return '🐵📷';
					case 'o': return '🐵🍩';
					case 'p': return '🐵💻';
					case 'q': return '🐵🍫';
					case 'r': return '📝🐵';
					case 's': return '🐵🍹';
					case 't': return '🐵🍽️';
					case 'u': return '🐵🍪';
					case 'v': return '☕🐵';
					case 'w': return '🐵🏈';
					case 'x': return '🐵🕯️';
					case 'y': return '🐵🌳';
					case 'z': return '🐵🏀';
					case '1': return '🐵🥗';
					case '2': return '🐵🏁';
					case '3': return '🐵🕯️';
					case '4': return '🎺🐵';
					case '5': return '🍧🐵';
					case '6': return '🍨🐵';
					case '7': return '🐵🧯';
					case '8': return '🍭🐵';
					case '9': return '🐵🔧';
					case '0': return '📝🐵';
					default: return '🐵🍌';
				}
			}

			const deleteCollection=async(ref)=>{
				const z = await ref.get();
				const docs = z.docs;
				for(let c=0;c<docs.length;c++){
					try {
						await deleteCollection(docs[c].ref.collection('content'));
					} catch(e) {
						console.log('Empty doc');
					}
					await docs[c].ref.delete();
				}
			}

			const deleteUserData=async()=>{
				const db = firebase.firestore();
				//Delete the WebNotes collection
				const y = await db.collection('users').doc(auth.currentUser.uid).collection('WebNotes').get();
				const docs = y.docs;
				for (let i = 0; i < docs.length; i++) {
					await docs[i].ref.delete();
				}
				//Delete the WebFolder collection
				await deleteCollection(db.collection('users').doc(auth.currentUser.uid).collection('WebFolder'));
				//Delete the document itself, including the fast note
				await db.collection('users').doc(auth.currentUser.uid).delete();
			}

			const deleteAccount=async()=>{
				if (confirm("Are you sure you want to delete your account?")) {
					if (confirm("There will be no way to recover your account after it's deleted, Do you want to continue?")) {
						loading.value=true;
						try {
							//Sign in again with credentials for security reasons
							let emailAgain = prompt('Email:');
							let passwordAgain = prompt('Password:');
							const credential = firebase.auth.EmailAuthProvider.credential(
								emailAgain,
								passwordAgain
							);
							const result = await auth.currentUser.reauthenticateWithCredential(credential);
							//Delete the user data
							await deleteUserData(result.user.uid);
							//Delete the account
							await result.user.delete();
							alert("Account deleted");
							loading.value=false;
							props.close();
						} catch(e) {
							alert('Error when deleting the account');
							loading.value=false;
						}
					}
				}
			}

			const signOut=async()=>{
				try {
					await auth.signOut();
					props.close();
				} catch(e) {
					alert("Error when signing out");
				}
			}

			const signOutForSmallScreens=()=>{
				console.log('width: '+window.innerWidth);
				if(window.innerWidth<=370){
					if(confirm('Sign out?')){
						signOut();
					}
				}
			}

			return {
				getMonkeyAvatar,
				deleteAccount,
				signOut,
				loading,
				signOutForSmallScreens,
			}

		}
	}
</script>

<style scoped>
	#userName {
		margin: 0;
		padding: 0;
	}
	#userPanel{
		margin: 23px auto;
		width: 90%;
		display: grid;
		grid-template-columns: 80px auto;
		grid-gap: 15px;
		align-items: center;
	}
	#userPanel > div:nth-child(1){
		/*user avatar*/
		background-color: rgb(121,85,72);
		border: none;
		text-align: center;
		align-content: center;
		display: inline-block;
		height: 75px;
		width: 75px;
		border-radius: 50%;
		font-size: 23px;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	}
	#userPanel > div:nth-child(2) {
		overflow: hidden;
	}
	#userButtons {
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-end;
	}
	#userButtons i {
		font-size:1.8em;
	}
	#userPanel > div:nth-child(2) p {
		font-weight: bold;
		margin: 0px;
		font-size: 16px;
		word-wrap: break-word;
		overflow-wrap: break-word;
		width: 100%;
	}
	#userPanel i {
		cursor: pointer;
	}
	#userPanel i:nth-child(2) {
		margin-left: 23px;
	}
	#userPanel > div:nth-child(2) div:last-child {
		/*the buttons for Web Folder and Fast Note*/
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-around;
		flex-direction: row;
	}
	#userPanel .monkeyButton {
		padding: 20px;
	}

	@media screen and (max-width: 770px) {/*For tablets*/
		#userPanel .monkeyButton {
			padding: 10px;
		}
		#userPanel i:nth-child(2) {
			margin-left: 13px;
		}
	}

	@media screen and (max-width: 370px) {/*For mobile*/
		#userPanel > div:nth-child(1){
			cursor: pointer;
		}
		#userPanel > div:nth-child(2) div:last-child {
			/*the buttons for Web Folder and Fast Note*/
			flex-direction: column;
		}
		#userPanel > div:nth-child(2) div:last-child button:last-child {
			margin-top: 10px;
		}
		#userButtons {
			display: none;
		}
	}
</style>