<template>
	<div :style="dark?'color:white;':'color:black;'">
		<br>
		<div style="display:flex;justify-content:center;">
			<img style="width:30%;" src="https://capacity-upgrade.web.app/img/clumsy_monkey.396c178c.svg">
		</div>
		<h1 style="text-align:center;">Ups! page not found</h1>
		<button @click="$router.push('/');" :class="'monkeyButton'+(dark?' darkCard':'')">
			<p class="titleText">Go to MonkeyNote</p>
		</button>
	</div>
</template>

<script>
	export default {
		name: 'NotFound',
		setup(props,context) {
			const dark = localStorage.getItem('darkTheme')=="true";
			return {dark}
		}
	}
</script>