<template>
<div :class="dark?'monkeyBar darkCard':'monkeyBar'">
	<i @click="$router.go(-1)" class="material-icons" aria-hidden="true">arrow_back</i>
	<h3 class="titleText unselectable">Backup</h3>
</div>
<Modal :isLoading="true" :isVisible="loading"/>
<br>
<SelectFolder :isVisible="selectFolder" :selectFolderID="selectFolderID" :close="close" :foldersToAvoid="[]" :dark="dark"/>
<div class="contentPadding" :style="dark?'color:white;':'color:black;'">
	<h2 class="titleText unselectable">All</h2>
	<button @click="backupAll()" :class="dark?'monkeyButton titleText darkCard':'monkeyButton titleText'">Backup all into Downloads</button>
	<br>
	<h2 class="titleText unselectable">A portion</h2>
	<button @click="selectFolder=true;" :class="'monkeyButton titleText'+(dark?' darkCard':'')">Backup a folder into Downloads</button>
	<br>
	<h2 class="titleText unselectable">Restore</h2>
	<button @click="pickTheFile()" :class="dark?'monkeyButton titleText darkCard':'monkeyButton titleText'">Restore from a file</button>
</div>
</template>

<script>
import {ref} from "vue";
import Localbase from 'localbase';
import SelectFolder from '../components/SelectFolder.vue';
import Modal from '../components/Modal.vue';
import {getFolderFullContent,fixIndexedIssue,restore} from '../modules/mf';
export default {
  name: 'Backup',
  components: {SelectFolder,Modal},
  setup(props,context){

    const loading = ref(false);

    const selectFolder = ref(false);
    const selectFolderID=(folderID)=>{
      close();
      if(folderID&&folderID.trim()!=''){
        backupFolder(folderID);
      }
    }
    const close=()=>selectFolder.value=false;

    let alwaysTrue=true;
    
    const db = new Localbase('monkeynote');
    
    //Dark theme code
    let dark = false;
    if(localStorage.getItem('darkTheme')=="true")dark=true;

    const backupAll=async()=>{

      /* Structure of the backup
      * A line to identify whether it's a whole database backup (1) or just a portion (0)
      * folders
      * empty line
      * notes (without their bodies)
      * empty line
      * separator in 1 line
      * bodies in order and separated by the separator
      */

      loading.value=true;
      try {

        await fixIndexedIssue();

        //Get the notes in the recycle bin to avoid including them in the backup
        let deletedNotes=localStorage.getItem('deletedNotes');
        if(deletedNotes&&deletedNotes.trim()!=''){
          deletedNotes=localStorage.getItem('deletedNotes').split(' ');
        } else {
          deletedNotes=[];
        }

        //Get the list of folders and notes instances
        let folders = await db.collection("folders").get();
        let notes = await db.collection("notes").get();
        notes=notes.filter((note)=>{
          return deletedNotes.indexOf(note.id)==-1;
        });

        //Get the correct separator
        let separator = '<MonkeyNote>';//The initial separator
        while(alwaysTrue){
          let isCorrect=true;
          //If the separator isn't in any body, is correct
          for(let c=0;c<notes.length;c++){
            if(notes[c].body==null?false:notes[c].body.indexOf(separator)!=-1){
              isCorrect=false;
              break;
            }
          }
          if(isCorrect){
            break;
          } else {
            separator = '<' + separator + '>';
          }
        }

        //Now get the backup as a string
        let backup = '';
        //Add the first line to know if it's a whole backup or just a portion
        backup = '1\n';
        //Add the folders
        for(let c=0;c<folders.length;c++){
          backup = backup + `${folders[c].id}\n${folders[c].parentID}\n${folders[c].name}\n${folders[c].favorite?'1':'0'}\n${folders[c].foldersID}\n${folders[c].notesID}\n`;
        }
        //Add a new line
        backup = backup + '\n';
        //Add the notes
        for(let c=0;c<notes.length;c++){
          backup = backup + `${notes[c].id}\n${notes[c].folderID}\n${notes[c].title}\n${notes[c].favorite?'1':'0'}\n${notes[c].creationDate.toString()}\n${notes[c].lastModified.toString()}\n`;
        }
        //Add a new line
        backup = backup + '\n';
        //The separator in one line
        backup = backup + `${separator}\n`;
        //Add all the bodies
        let bodies = [];
        for(let c=0;c<notes.length;c++){
          bodies.push(notes[c].body==null?'':notes[c].body);
        }
        backup = backup + bodies.join(separator);

        //Now the backup string is ready, create the file
        let now = new Date();
        let fileName = `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()}-${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.monkeynote`;
        
        //Download the file
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(backup));
        element.setAttribute('download',fileName);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

        loading.value=false;
      } catch(e) {
        alert('An error happened during the backup process 🐒');
        console.log(e);
        loading.value=false;
      }
    }
    
    const backupFolder=async(topFolderID)=>{
      /* Structure of the backup
      * A integer to identify whether it's a whole database backup (1) or just a portion (0)
      * folders (the first one is the highest in the folder hierarchy)
      * empty line
      * notes (without their bodies)
      * empty line
      * separator in 1 line
      * bodies in order and separated by the separator
      */

      loading.value=true;
      try {
        
        //Get the top folder which will be the first to be backup
        let topFolder = await db.collection('folders').doc(topFolderID).get();

        //Get all the folders and notes below the hierarchy
        let folders = [];
        let notes = [];
        await getFolderFullContent(topFolder.id,folders,notes);

        //Get the correct separator
        let separator = '<MonkeyNote>';//The initial separator
        while(alwaysTrue){
          let isCorrect = true;
          //If the separator isn't in any body, is correct
          for(let c=0;c<notes.length;c++){
            if(notes[c].body==null?false:notes[c].body.indexOf(separator)!=-1){
              isCorrect=false;
              break;
            }
          }
          if(isCorrect){
            break;
          } else {
            separator = '<' + separator + '>';
          }
        }

        //Now get the backup as a string
        let backup = '';
        //Add the first line to know if it's a whole backup or just a portion
        backup = '0\n';
        //Add the highest folder in the hierarchy
        backup = backup + `${topFolder.id}\n${topFolder.parentID}\n${topFolder.name}\n${topFolder.favorite?'1':'0'}\n${topFolder.foldersID}\n${topFolder.notesID}\n`;
        //Add the folders
        for(let c=0;c<folders.length;c++){
          backup = backup + `${folders[c].id}\n${folders[c].parentID}\n${folders[c].name}\n${folders[c].favorite?'1':'0'}\n${folders[c].foldersID}\n${folders[c].notesID}\n`;
        }
        //Add a new line
        backup = backup + '\n';
        //Add the notes
        for(let c=0;c<notes.length;c++){
          backup = backup + `${notes[c].id}\n${notes[c].folderID}\n${notes[c].title}\n${notes[c].favorite?'1':'0'}\n${notes[c].creationDate.toString()}\n${notes[c].lastModified.toString()}\n`;
        }
        //Add a new line
        backup = backup + '\n';
        //The separator in one line
        backup = backup + `${separator}\n`;
        //Add all the bodies
        let bodies = [];
        for(let c=0;c<notes.length;c++){
          bodies.push(notes[c].body==null?'':notes[c].body);
        }
        backup = backup + bodies.join(separator);

        //Now the backup string is ready, create the file
        let fileName = `${topFolder.name}.monkeynote`;
        
        //Download the file
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(backup));
        element.setAttribute('download',fileName);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

        loading.value=false;
      } catch(e) {
        alert('An error happened during the backup process 🐒');
        console.log(e);
        loading.value=false;
      }
    }

    const pickTheFile=()=>{
      if(window.File && window.FileReader && window.FileList && window.Blob){
        let element = document.createElement('input');
        element.setAttribute("type","file");
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        element.addEventListener("change",()=>{
          const file = element.files[0];
          if(file){
            if(file.name.indexOf(".monkeynote")==-1){
              alert("File not supported 🐒");
              return;
            }
            let reader = new FileReader();
            reader.onload = e=>{
              restore(e.target.result,db,loading);
            };
            reader.readAsText(file,"UTF-8");
          } else {
            alert("Choose a file 🐒");
            return;
          }
        });
      } else {
        alert('This feature is not fully supported by your browser :(');
      }
    }

    return {
      backupAll,
      backupFolder,
      pickTheFile,
      dark,
      selectFolder,
      selectFolderID,
      close,
      loading,
    }
  }
}
</script>