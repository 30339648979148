<template>
	<Modal :isLoading="true" :isVisible="loading"/>
	<div :class="dark?'monkeyBar darkCard':'monkeyBar'">
		<h2 style="margin-left:16px;" class="titleText unselectable">MonkeyNote Templates</h2>
	</div>
	<div :style="dark?'color:white;':''" class="addPadding">
		<p class="bodyText">A folder hierarchy almost all the time is the solution for time consuming projects. So here are the best folder structures (or templates) for specific tasks that you will probably need in the future.</p>
		<p class="bodyText">Each template have 2 languages: English and Spanish</p>
		<br>
		<div v-for="(template,i) in templates" :key="i" class="template">
			<strong class="titleText">{{template[0]}}</strong>
			<p class="bodyText">{{template[1]}}</p>
			<button @click="download(i)" class="monkeyButton" :class="dark?'darkCard':''">
				<p class="titleText" style="color:white;margin:0;">Download in my Main Folder</p>
			</button>
		</div>
	</div>
</template>

<script>
	import {fixIndexedIssue,restore} from '../modules/mf';
	import {ref} from "vue";
	import Localbase from 'localbase';
	import Modal from '../components/Modal.vue';
	export default {
		name: 'Templates',
		components: {Modal},
		setup(props,context) {
			const dark = localStorage.getItem('darkTheme')=="true";
			const loading = ref(false);
			let alwaysTrue=true;
			const db = new Localbase('monkeynote');
			const templates = [
				["Books","Write down important data about the book like the author's name and publishing date. And organize the information by chapters."],
				["Job interview template","Prepare for your interview by practising all the answers for the most common questions asked by interviewers."],
				["University","One of the key parts of the life in college is to manage well your homeworks and learned knowledges in order to pass the courses without too much effort and stress. This MonkeyNote template will help you organize everything in a cleaner way."],
				["Course","To never forget what you learn in a course, you must understand it. To undestand it well you have to abstract the subject complexity into simpler topics. And no tool is better than MonkeyNote to make everything simpler by using its folder hierarchy."],
				["Curriculum Vitae","Organize all your skills and work experience data in a folder hierarchy to easily fetch specific information when needed."],
				["Project","MonkeyNote software was made using this folder structure. Thus this template definitely will make the difference in your productivity when launching new projects or managing the existing ones. And yes, we use MonkeyNote to improve MonkeyNote, interesting fact."],
				["YouTube channel","Having a YouTube channel is hard and very time consuming if you don't know how to organize the new content. Ideas come into your mind and then they leave as fast as they suddenly appeared. So you need a tool to quickly write down what you're thinking in the moment. And that's MonkeyNote speciality."],
			];
			const templateCodes = [
				`0
11ebd79a74f300b0b853c952053bb4b8
11ebd79a74e32230b853c952053bb4b8
Books
0
11ebd79a74fcebc0b853c952053bb4b8 11ebd79a753512c0b853c952053bb4b8

11ebd79a74fcebc0b853c952053bb4b8
11ebd79a74f300b0b853c952053bb4b8
🇺🇲 Book title
0
11ebd79a75104cb0b853c952053bb4b8
11ebd79a7b310e40b853c952053bb4b8 11ebd79a7b37ec10b853c952053bb4b8 11ebd79a7b3ec9e0b853c952053bb4b8
11ebd79a75104cb0b853c952053bb4b8
11ebd79a74fcebc0b853c952053bb4b8
Chapters
0
11ebd79a751a37c0b853c952053bb4b8 11ebd79a75229c30b853c952053bb4b8 11ebd79a752b75d0b853c952053bb4b8

11ebd79a751a37c0b853c952053bb4b8
11ebd79a75104cb0b853c952053bb4b8
1. The beginning
0


11ebd79a75229c30b853c952053bb4b8
11ebd79a75104cb0b853c952053bb4b8
2. The middle
0


11ebd79a752b75d0b853c952053bb4b8
11ebd79a75104cb0b853c952053bb4b8
3. The end
0


11ebd79a753512c0b853c952053bb4b8
11ebd79a74f300b0b853c952053bb4b8
🇪🇸 Título del libro
0
11ebd79a753dec60b853c952053bb4b8
11ebd79a7b472e50b853c952053bb4b8 11ebd79a7b4fb9d0b853c952053bb4b8 11ebd79a7b57d020b853c952053bb4b8
11ebd79a753dec60b853c952053bb4b8
11ebd79a753512c0b853c952053bb4b8
Capítulos
0
11ebd79a7547fe80b853c952053bb4b8 11ebd79a755210a0b853c952053bb4b8 11ebd79a755b8680b853c952053bb4b8

11ebd79a7547fe80b853c952053bb4b8
11ebd79a753dec60b853c952053bb4b8
1. Inicio
0


11ebd79a755210a0b853c952053bb4b8
11ebd79a753dec60b853c952053bb4b8
2. Cuerpo
0


11ebd79a755b8680b853c952053bb4b8
11ebd79a753dec60b853c952053bb4b8
3. Desenlace
0



11ebd79a7b310e40b853c952053bb4b8
11ebd79a74fcebc0b853c952053bb4b8
About the author
0
1620082402634
1620082402634
11ebd79a7b37ec10b853c952053bb4b8
11ebd79a74fcebc0b853c952053bb4b8
About the book
0
1620082559696
1620082559696
11ebd79a7b3ec9e0b853c952053bb4b8
11ebd79a74fcebc0b853c952053bb4b8
Summary
0
1620082568310
1620082568310
11ebd79a7b472e50b853c952053bb4b8
11ebd79a753512c0b853c952053bb4b8
Sobre el autor
0
1620082641590
1620082641590
11ebd79a7b4fb9d0b853c952053bb4b8
11ebd79a753512c0b853c952053bb4b8
Sobre el libro
0
1620082641627
1620082641627
11ebd79a7b57d020b853c952053bb4b8
11ebd79a753512c0b853c952053bb4b8
Resumen
0
1620082641660
1620082641660

<MonkeyNote>
• Name and surname:

• Vocation:

• Country of origin:

• Date of birth:

• Additional Information:<MonkeyNote>• Publication date:

• Editorial:

• Category:

• Additional Information:<MonkeyNote><MonkeyNote>• Nombre y apellidos:

• Vocación:

• País de origen: 

• Fecha de nacimiento:

• Información adicional:<MonkeyNote>• Fecha de publicación:

• Editorial:

• Categoría: 

• Información adicional:<MonkeyNote>`,
				`0
11ebd79a75676d60b853c952053bb4b8
11ebd79a74e32230b853c952053bb4b8
Job interview template
0
11ebd79a756ff8e0b853c952053bb4b8 11ebd79a75ac8cb0b853c952053bb4b8

11ebd79a756ff8e0b853c952053bb4b8
11ebd79a75676d60b853c952053bb4b8
Español 🇪🇦
0
11ebd79a757f3b20b853c952053bb4b8 11ebd79a75894d40b853c952053bb4b8 11ebd79a75a47660b853c952053bb4b8
11ebd79a7b5eadf0b853c952053bb4b8
11ebd79a757f3b20b853c952053bb4b8
11ebd79a756ff8e0b853c952053bb4b8
Preguntas de la entrevista sobre la empresa
0

11ebd79a7b653da0b853c952053bb4b8 11ebd79a7b6c6990b853c952053bb4b8 11ebd79a7b74ce00b853c952053bb4b8 11ebd79a7b7d5980b853c952053bb4b8
11ebd79a75894d40b853c952053bb4b8
11ebd79a756ff8e0b853c952053bb4b8
Acerca de mis proyectos
0
11ebd79a75933850b853c952053bb4b8 11ebd79a759a3d30b853c952053bb4b8

11ebd79a75933850b853c952053bb4b8
11ebd79a75894d40b853c952053bb4b8
MonkeyNote app
0

11ebd79a7b8e9790b853c952053bb4b8 11ebd79a7b980d70b853c952053bb4b8 11ebd79a7b9f1250b853c952053bb4b8
11ebd79a759a3d30b853c952053bb4b8
11ebd79a75894d40b853c952053bb4b8
MonkeyNote web
0

11ebd79a7ba79dd0b853c952053bb4b8 11ebd79a7bb02950b853c952053bb4b8 11ebd79a7bb9ed50b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
11ebd79a756ff8e0b853c952053bb4b8
Preguntas comunes de la entrevista
0

11ebd79a7bc14050b853c952053bb4b8 11ebd79a7bc97db0b853c952053bb4b8 11ebd79a7bd1e220b853c952053bb4b8 11ebd79a7bda6da0b853c952053bb4b8 11ebd79a7bf6a830b853c952053bb4b8 11ebd79a7c00ba50b853c952053bb4b8 11ebd79a7c079820b853c952053bb4b8 11ebd79a7c0e75f0b853c952053bb4b8 11ebd79a7c16b350b853c952053bb4b8 11ebd79a7c1d6a10b853c952053bb4b8 11ebd79a7c246ef0b853c952053bb4b8 11ebd79a7c2cfa70b853c952053bb4b8 11ebd79a7c355ee0b853c952053bb4b8 11ebd79a7c3dc350b853c952053bb4b8
11ebd79a75ac8cb0b853c952053bb4b8
11ebd79a75676d60b853c952053bb4b8
English 🇺🇸
0
11ebd79a75b69ed0b853c952053bb4b8 11ebd79a75bf2a50b853c952053bb4b8 11ebd79a75df3570b853c952053bb4b8
11ebd79a7c4600b0b853c952053bb4b8
11ebd79a75b69ed0b853c952053bb4b8
11ebd79a75ac8cb0b853c952053bb4b8
Interview questions about the company
0

11ebd79a7c4cb770b853c952053bb4b8 11ebd79a7c5765d0b853c952053bb4b8 11ebd79a7c5fca40b853c952053bb4b8 11ebd79a7c75c340b853c952053bb4b8
11ebd79a75bf2a50b853c952053bb4b8
11ebd79a75ac8cb0b853c952053bb4b8
About my projects
0
11ebd79a75c5e110b853c952053bb4b8 11ebd79a75d59880b853c952053bb4b8

11ebd79a75c5e110b853c952053bb4b8
11ebd79a75bf2a50b853c952053bb4b8
MonkeyNote app
0

11ebd79a7c8357d0b853c952053bb4b8 11ebd79a7c8ef090b853c952053bb4b8 11ebd79a7c98dba0b853c952053bb4b8
11ebd79a75d59880b853c952053bb4b8
11ebd79a75bf2a50b853c952053bb4b8
MonkeyNote web
0

11ebd79a7caa67d0b853c952053bb4b8 11ebd79a7cb5d980b853c952053bb4b8 11ebd79a7cbe16e0b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
11ebd79a75ac8cb0b853c952053bb4b8
Common interview questions
0

11ebd79a7cc4cda0b853c952053bb4b8 11ebd79a7ccd3210b853c952053bb4b8 11ebd79a7cd45e00b853c952053bb4b8 11ebd79a7cdcc270b853c952053bb4b8 11ebd79a7ce4ffd0b853c952053bb4b8 11ebd79a7ced8b50b853c952053bb4b8 11ebd79a7cf5c8b0b853c952053bb4b8 11ebd79a7cfe5430b853c952053bb4b8 11ebd79a7d06b8a0b853c952053bb4b8 11ebd79a7d113ff0b853c952053bb4b8 11ebd79a7d19a460b853c952053bb4b8 11ebd79a7d222fe0b853c952053bb4b8 11ebd79a7d2a9450b853c952053bb4b8 11ebd79a7d331fd0b853c952053bb4b8

11ebd79a7b5eadf0b853c952053bb4b8
11ebd79a756ff8e0b853c952053bb4b8
Fecha: 23/03/2016
0
1620094747199
1620094747199
11ebd79a7b653da0b853c952053bb4b8
11ebd79a757f3b20b853c952053bb4b8
¿Por qué deberíamos contratarte?
0
1620094745958
1620094745958
11ebd79a7b6c6990b853c952053bb4b8
11ebd79a757f3b20b853c952053bb4b8
¿Por qué quiere trabajar aquí?
0
1620094745989
1620094745989
11ebd79a7b74ce00b853c952053bb4b8
11ebd79a757f3b20b853c952053bb4b8
¿Tienes alguna pregunta para mí?
0
1620094746021
1620094746021
11ebd79a7b7d5980b853c952053bb4b8
11ebd79a757f3b20b853c952053bb4b8
¿Cuáles son tus expectativas salariales?
0
1620094746059
1620094746059
11ebd79a7b8e9790b853c952053bb4b8
11ebd79a75933850b853c952053bb4b8
¿Qué hace?
0
1620094746242
1620094746242
11ebd79a7b980d70b853c952053bb4b8
11ebd79a75933850b853c952053bb4b8
¿Cómo lo hice?
0
1620094746265
1620094746265
11ebd79a7b9f1250b853c952053bb4b8
11ebd79a75933850b853c952053bb4b8
¿Cómo está yendo?
0
1620094746315
1620094746315
11ebd79a7ba79dd0b853c952053bb4b8
11ebd79a759a3d30b853c952053bb4b8
¿Cómo está yendo?
0
1620094883417
1620094883417
11ebd79a7bb02950b853c952053bb4b8
11ebd79a759a3d30b853c952053bb4b8
¿Cómo lo hice?
0
1620094883476
1620094883476
11ebd79a7bb9ed50b853c952053bb4b8
11ebd79a759a3d30b853c952053bb4b8
¿Qué hace?
0
1620094883541
1620094883541
11ebd79a7bc14050b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
Cuéntame sobre ti
0
1620094746633
1620094746633
11ebd79a7bc97db0b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
¿Cuál es tu mayor fortaleza?
0
1620094746681
1620094746681
11ebd79a7bd1e220b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
¿Cuál es tu mayor debilidad?
0
1620094746727
1620094746727
11ebd79a7bda6da0b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
Háblame de alguna vez que mostraste liderazgo
0
1620094746762
1620094746762
11ebd79a7bf6a830b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
Háblame de un momento en el que tuviste éxito en un equipo
0
1620094746795
1620094746795
11ebd79a7c00ba50b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
¿Qué dirían tus compañeros de trabajo sobre ti?
0
1620094746853
1620094746853
11ebd79a7c079820b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
¿Por qué querías dejar tu puesto actual?
0
1620094746904
1620094746904
11ebd79a7c0e75f0b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
Describe tu proyecto más desafiante
0
1620094746933
1620094746933
11ebd79a7c16b350b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
Cuéntame algo que hayas logrado y de lo que estés orgulloso.
0
1620094746967
1620094746967
11ebd79a7c1d6a10b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
¿Puede explicar su brecha laboral?
0
1620094746999
1620094746999
11ebd79a7c246ef0b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
¿Qué le gusta hacer fuera del trabajo?
0
1620094747036
1620094747036
11ebd79a7c2cfa70b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
¿Dónde te ves en 5 años?
0
1620094747067
1620094747067
11ebd79a7c355ee0b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
Cuéntame sobre la vez que fallaste o cometiste un error.
0
1620094747116
1620094747116
11ebd79a7c3dc350b853c952053bb4b8
11ebd79a75a47660b853c952053bb4b8
Háblame de un momento en el que tuviste que manejar la presión
0
1620094747159
1620094747159
11ebd79a7c4600b0b853c952053bb4b8
11ebd79a75ac8cb0b853c952053bb4b8
Date: 23/03/2016
0
1620094730540
1620094730540
11ebd79a7c4cb770b853c952053bb4b8
11ebd79a75b69ed0b853c952053bb4b8
Why should we hire you
0
1617258032997
1617258032997
11ebd79a7c5765d0b853c952053bb4b8
11ebd79a75b69ed0b853c952053bb4b8
Why do you want to work here
0
1617258033028
1617258033028
11ebd79a7c5fca40b853c952053bb4b8
11ebd79a75b69ed0b853c952053bb4b8
Do you have any questions for me
0
1617258033061
1617258033061
11ebd79a7c75c340b853c952053bb4b8
11ebd79a75b69ed0b853c952053bb4b8
What are your salary expectations
0
1617258032591
1617258032591
11ebd79a7c8357d0b853c952053bb4b8
11ebd79a75c5e110b853c952053bb4b8
What it does?
0
1620094667170
1620094667170
11ebd79a7c8ef090b853c952053bb4b8
11ebd79a75c5e110b853c952053bb4b8
How I did it?
0
1620094667257
1620094667257
11ebd79a7c98dba0b853c952053bb4b8
11ebd79a75c5e110b853c952053bb4b8
How is it going?
0
1620094667318
1620094667318
11ebd79a7caa67d0b853c952053bb4b8
11ebd79a75d59880b853c952053bb4b8
What it does?
0
1620094607987
1620094607987
11ebd79a7cb5d980b853c952053bb4b8
11ebd79a75d59880b853c952053bb4b8
How I did it?
0
1620094630758
1620094630758
11ebd79a7cbe16e0b853c952053bb4b8
11ebd79a75d59880b853c952053bb4b8
How is it going?
0
1620094654774
1620094654774
11ebd79a7cc4cda0b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
Tell me about yourself
0
1617258032217
1617258032217
11ebd79a7ccd3210b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
What is your greatest strength
0
1617258032251
1617258032251
11ebd79a7cd45e00b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
What is your greatest weakness
0
1617258032282
1617258032282
11ebd79a7cdcc270b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
Tell me about the time you showed leadership
0
1617258032318
1617258032318
11ebd79a7ce4ffd0b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
Tell me about a time you were successful on a team
0
1617258032361
1617258032361
11ebd79a7ced8b50b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
What would your coworkers say about you
0
1617258032388
1617258032388
11ebd79a7cf5c8b0b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
Why did you want to leave your current role
0
1617258032431
1617258032431
11ebd79a7cfe5430b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
Describe your most challenging project
0
1617258032489
1617258032489
11ebd79a7d06b8a0b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
Tell me about something you have accomplished that you are proud of
0
1617258032520
1617258032520
11ebd79a7d113ff0b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
Can you explain your employment gap
0
1617258032548
1617258032548
11ebd79a7d19a460b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
What do you like to do outside of work
0
1617258032623
1617258032623
11ebd79a7d222fe0b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
Where do you see yourself in 5 years
0
1617258032660
1617258032660
11ebd79a7d2a9450b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
Tell me about the time you failed or made a mistake
0
1617258032695
1617258032695
11ebd79a7d331fd0b853c952053bb4b8
11ebd79a75df3570b853c952053bb4b8
Tell me about a time you had to handle pressure
0
1617258032725
1617258032725

<MonkeyNote>
<MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote><MonkeyNote>`,
				`0
11ebd79a75e5ec30b853c952053bb4b8
11ebd79a74e32230b853c952053bb4b8
University
0
11ebd79a75ecf110b853c952053bb4b8 11ebd79a76ed9830b853c952053bb4b8

11ebd79a75ecf110b853c952053bb4b8
11ebd79a75e5ec30b853c952053bb4b8
🇪🇸 Universidad
0
11ebd79a75f52e70b853c952053bb4b8 11ebd79a75fc0c40b853c952053bb4b8 11ebd79a7609c7e0b853c952053bb4b8 11ebd79a76a348c0b853c952053bb4b8

11ebd79a75f52e70b853c952053bb4b8
11ebd79a75ecf110b853c952053bb4b8
Información básica
0

11ebd79a7d3b5d30b853c952053bb4b8 11ebd79a7d41ece0b853c952053bb4b8
11ebd79a75fc0c40b853c952053bb4b8
11ebd79a75ecf110b853c952053bb4b8
Ciclos pasados
0
11ebd79a7602c300b853c952053bb4b8

11ebd79a7602c300b853c952053bb4b8
11ebd79a75fc0c40b853c952053bb4b8
Ciclo 1
0


11ebd79a7609c7e0b853c952053bb4b8
11ebd79a75ecf110b853c952053bb4b8
Ciclo 2 (ciclo actual)
0
11ebd79a76122c50b853c952053bb4b8

11ebd79a76122c50b853c952053bb4b8
11ebd79a7609c7e0b853c952053bb4b8
Curso 1
0
11ebd79a761ab7d0b853c952053bb4b8 11ebd79a7633e520b853c952053bb4b8 11ebd79a76761e40b853c952053bb4b8
11ebd79a7d48cab0b853c952053bb4b8 11ebd79a7d51a450b853c952053bb4b8 11ebd79a7d59e1b0b853c952053bb4b8
11ebd79a761ab7d0b853c952053bb4b8
11ebd79a76122c50b853c952053bb4b8
Tareas
0
11ebd79a762b59a0b853c952053bb4b8
11ebd79a7d624620b853c952053bb4b8
11ebd79a762b59a0b853c952053bb4b8
11ebd79a761ab7d0b853c952053bb4b8
Grupales
0


11ebd79a7633e520b853c952053bb4b8
11ebd79a76122c50b853c952053bb4b8
Temas tocados en clase por semana
0
11ebd79a7644fc20b853c952053bb4b8 11ebd79a764ee730b853c952053bb4b8 11ebd79a765af520b853c952053bb4b8 11ebd79a76657c70b853c952053bb4b8 11ebd79a766e07f0b853c952053bb4b8

11ebd79a7644fc20b853c952053bb4b8
11ebd79a7633e520b853c952053bb4b8
Semana 1
0


11ebd79a764ee730b853c952053bb4b8
11ebd79a7633e520b853c952053bb4b8
Semana 2
0


11ebd79a765af520b853c952053bb4b8
11ebd79a7633e520b853c952053bb4b8
Semana 3
0


11ebd79a76657c70b853c952053bb4b8
11ebd79a7633e520b853c952053bb4b8
Semana 4
0


11ebd79a766e07f0b853c952053bb4b8
11ebd79a7633e520b853c952053bb4b8
Semana 5
0


11ebd79a76761e40b853c952053bb4b8
11ebd79a76122c50b853c952053bb4b8
Resumen del curso
0
11ebd79a767fe240b853c952053bb4b8 11ebd79a768846b0b853c952053bb4b8 11ebd79a7690ab20b853c952053bb4b8 11ebd79a769936a0b853c952053bb4b8

11ebd79a767fe240b853c952053bb4b8
11ebd79a76761e40b853c952053bb4b8
Tema 1
0


11ebd79a768846b0b853c952053bb4b8
11ebd79a76761e40b853c952053bb4b8
Tema 2
0


11ebd79a7690ab20b853c952053bb4b8
11ebd79a76761e40b853c952053bb4b8
Tema 3
0


11ebd79a769936a0b853c952053bb4b8
11ebd79a76761e40b853c952053bb4b8
Información adicional
0


11ebd79a76a348c0b853c952053bb4b8
11ebd79a75ecf110b853c952053bb4b8
Horario semanal
0
11ebd79a76abad30b853c952053bb4b8 11ebd79a76b45fc0b853c952053bb4b8 11ebd79a76be98f0b853c952053bb4b8 11ebd79a76c8ab10b853c952053bb4b8 11ebd79a76d2bd30b853c952053bb4b8 11ebd79a76dd1d70b853c952053bb4b8 11ebd79a76e50cb0b853c952053bb4b8

11ebd79a76abad30b853c952053bb4b8
11ebd79a76a348c0b853c952053bb4b8
Lunes
0

11ebd79a7d6ad1a0b853c952053bb4b8
11ebd79a76b45fc0b853c952053bb4b8
11ebd79a76a348c0b853c952053bb4b8
Martes
0


11ebd79a76be98f0b853c952053bb4b8
11ebd79a76a348c0b853c952053bb4b8
Miércoles
0


11ebd79a76c8ab10b853c952053bb4b8
11ebd79a76a348c0b853c952053bb4b8
Jueves
0


11ebd79a76d2bd30b853c952053bb4b8
11ebd79a76a348c0b853c952053bb4b8
Viernes
0


11ebd79a76dd1d70b853c952053bb4b8
11ebd79a76a348c0b853c952053bb4b8
Sábado
0


11ebd79a76e50cb0b853c952053bb4b8
11ebd79a76a348c0b853c952053bb4b8
Domingo
0


11ebd79a76ed9830b853c952053bb4b8
11ebd79a75e5ec30b853c952053bb4b8
🇺🇲 University
0
11ebd79a76f81f80b853c952053bb4b8 11ebd79a77020a90b853c952053bb4b8 11ebd79a77167cf0b853c952053bb4b8 11ebd79a77aec550b853c952053bb4b8

11ebd79a76f81f80b853c952053bb4b8
11ebd79a76ed9830b853c952053bb4b8
Basic data
0

11ebd79a7d733610b853c952053bb4b8 11ebd79a7d7b9a80b853c952053bb4b8
11ebd79a77020a90b853c952053bb4b8
11ebd79a76ed9830b853c952053bb4b8
Previous cycles
0
11ebd79a770c43c0b853c952053bb4b8

11ebd79a770c43c0b853c952053bb4b8
11ebd79a77020a90b853c952053bb4b8
Cycle 1
0


11ebd79a77167cf0b853c952053bb4b8
11ebd79a76ed9830b853c952053bb4b8
Cycle 2 (current one)
0
11ebd79a77208f10b853c952053bb4b8

11ebd79a77208f10b853c952053bb4b8
11ebd79a77167cf0b853c952053bb4b8
Course
0
11ebd79a772ac840b853c952053bb4b8 11ebd79a773ec570b853c952053bb4b8 11ebd79a77828530b853c952053bb4b8
11ebd79a7d842600b853c952053bb4b8 11ebd79a7d8c8a70b853c952053bb4b8 11ebd79a7d969c90b853c952053bb4b8
11ebd79a772ac840b853c952053bb4b8
11ebd79a77208f10b853c952053bb4b8
Homeworks
0
11ebd79a7734da60b853c952053bb4b8
11ebd79a7d9e64c0b853c952053bb4b8
11ebd79a7734da60b853c952053bb4b8
11ebd79a772ac840b853c952053bb4b8
Team
0


11ebd79a773ec570b853c952053bb4b8
11ebd79a77208f10b853c952053bb4b8
Weeks
0
11ebd79a774750f0b853c952053bb4b8 11ebd79a774f8e50b853c952053bb4b8 11ebd79a775f45c0b853c952053bb4b8 11ebd79a77662390b853c952053bb4b8 11ebd79a77753ec0b853c952053bb4b8

11ebd79a774750f0b853c952053bb4b8
11ebd79a773ec570b853c952053bb4b8
Week 1
0


11ebd79a774f8e50b853c952053bb4b8
11ebd79a773ec570b853c952053bb4b8
Week 2
0


11ebd79a775f45c0b853c952053bb4b8
11ebd79a773ec570b853c952053bb4b8
Week 3
0


11ebd79a77662390b853c952053bb4b8
11ebd79a773ec570b853c952053bb4b8
Week 4
0


11ebd79a77753ec0b853c952053bb4b8
11ebd79a773ec570b853c952053bb4b8
Week 5
0


11ebd79a77828530b853c952053bb4b8
11ebd79a77208f10b853c952053bb4b8
Course summary
0
11ebd79a778c7040b853c952053bb4b8 11ebd79a7794d4b0b853c952053bb4b8 11ebd79a779d3920b853c952053bb4b8 11ebd79a77a5c4a0b853c952053bb4b8

11ebd79a778c7040b853c952053bb4b8
11ebd79a77828530b853c952053bb4b8
Topic 1
0


11ebd79a7794d4b0b853c952053bb4b8
11ebd79a77828530b853c952053bb4b8
Topic 2
0


11ebd79a779d3920b853c952053bb4b8
11ebd79a77828530b853c952053bb4b8
Topic 3
0


11ebd79a77a5c4a0b853c952053bb4b8
11ebd79a77828530b853c952053bb4b8
Additional information
0


11ebd79a77aec550b853c952053bb4b8
11ebd79a76ed9830b853c952053bb4b8
Weekly schedule
0
11ebd79a77b55500b853c952053bb4b8 11ebd79a77bc32d0b853c952053bb4b8 11ebd79a77c47030b853c952053bb4b8 11ebd79a77ccd4a0b853c952053bb4b8 11ebd79a77d56020b853c952053bb4b8 11ebd79a77dfe770b853c952053bb4b8 11ebd79a77e872f0b853c952053bb4b8

11ebd79a77b55500b853c952053bb4b8
11ebd79a77aec550b853c952053bb4b8
Monday
0

11ebd79a7da54290b853c952053bb4b8
11ebd79a77bc32d0b853c952053bb4b8
11ebd79a77aec550b853c952053bb4b8
Tuesday
0


11ebd79a77c47030b853c952053bb4b8
11ebd79a77aec550b853c952053bb4b8
Wednesday
0


11ebd79a77ccd4a0b853c952053bb4b8
11ebd79a77aec550b853c952053bb4b8
Thursday
0


11ebd79a77d56020b853c952053bb4b8
11ebd79a77aec550b853c952053bb4b8
Friday
0


11ebd79a77dfe770b853c952053bb4b8
11ebd79a77aec550b853c952053bb4b8
Saturday
0


11ebd79a77e872f0b853c952053bb4b8
11ebd79a77aec550b853c952053bb4b8
Sunday
0



11ebd79a7d3b5d30b853c952053bb4b8
11ebd79a75f52e70b853c952053bb4b8
Código de estudiante
0
1620091752672
1620091752672
11ebd79a7d41ece0b853c952053bb4b8
11ebd79a75f52e70b853c952053bb4b8
Usuario en la biblioteca
0
1620092875783
1620092875783
11ebd79a7d48cab0b853c952053bb4b8
11ebd79a76122c50b853c952053bb4b8
Zoom ID
0
1620092580496
1620092580496
11ebd79a7d51a450b853c952053bb4b8
11ebd79a76122c50b853c952053bb4b8
Personas conocidas
0
1620092580554
1620092580554
11ebd79a7d59e1b0b853c952053bb4b8
11ebd79a76122c50b853c952053bb4b8
Integrantes de grupo
0
1620093070737
1620093070737
11ebd79a7d624620b853c952053bb4b8
11ebd79a761ab7d0b853c952053bb4b8
Tarea 1 - Presentación: 23/03 - 3:45 pm
0
1620092580653
1620092580653
11ebd79a7d6ad1a0b853c952053bb4b8
11ebd79a76abad30b853c952053bb4b8
3pm - 5pm: Física cuántica
0
1620093844026
1620093844026
11ebd79a7d733610b853c952053bb4b8
11ebd79a76f81f80b853c952053bb4b8
Student ID
0
1620093221467
1620093221467
11ebd79a7d7b9a80b853c952053bb4b8
11ebd79a76f81f80b853c952053bb4b8
Library account
0
1620093221532
1620093221532
11ebd79a7d842600b853c952053bb4b8
11ebd79a77208f10b853c952053bb4b8
Zoom ID
0
1620093221793
1620093221793
11ebd79a7d8c8a70b853c952053bb4b8
11ebd79a77208f10b853c952053bb4b8
Known people
0
1620093221876
1620093221876
11ebd79a7d969c90b853c952053bb4b8
11ebd79a77208f10b853c952053bb4b8
Team members
0
1620093221939
1620093221939
11ebd79a7d9e64c0b853c952053bb4b8
11ebd79a772ac840b853c952053bb4b8
Homework 1 - deadline: 23/03 - 3:45 pm
0
1620093222233
1620093222233
11ebd79a7da54290b853c952053bb4b8
11ebd79a77b55500b853c952053bb4b8
3pm - 5pm: Quantic physics
0
1620093871925
1620093871925

<MonkeyNote>
2016 0323<MonkeyNote>• Usuario: MonkeyLautee@uni.com

• Contraseña: 12345<MonkeyNote>9274728163828<MonkeyNote>Monkey 1 🐵
Monkey 2 🐵<MonkeyNote>Monkey 1 🐵
Monkey 2 🐵
Monkey 3 🐵<MonkeyNote>• Descripción:<MonkeyNote><MonkeyNote>2016 0323<MonkeyNote>• User: MonkeyLautee@uni.com

• Password: 12345<MonkeyNote>9274728163828<MonkeyNote>Monkey 1 🐵
Monkey 2 🐵<MonkeyNote>Monkey 1 🐵
Monkey 2 🐵
Monkey 3 🐵<MonkeyNote>• Description:<MonkeyNote>`,
				`0
11ebd79a77f08940b853c952053bb4b8
11ebd79a74e32230b853c952053bb4b8
Course
0
11ebd79a77f76710b853c952053bb4b8 11ebd79a78835360b853c952053bb4b8

11ebd79a77f76710b853c952053bb4b8
11ebd79a77f08940b853c952053bb4b8
English 🇺🇸
0
11ebd79a77ff2f40b853c952053bb4b8

11ebd79a77ff2f40b853c952053bb4b8
11ebd79a77f76710b853c952053bb4b8
Course 1
0
11ebd79a78060d10b853c952053bb4b8 11ebd79a7818aab0b853c952053bb4b8 11ebd79a7849f3e0b853c952053bb4b8
11ebd79a7dabf950b853c952053bb4b8 11ebd79a7db2d720b853c952053bb4b8 11ebd79a7db966d0b853c952053bb4b8
11ebd79a78060d10b853c952053bb4b8
11ebd79a77ff2f40b853c952053bb4b8
Homeworks
0
11ebd79a780ceae0b853c952053bb4b8
11ebd79a7dc61100b853c952053bb4b8
11ebd79a780ceae0b853c952053bb4b8
11ebd79a78060d10b853c952053bb4b8
Team
0


11ebd79a7818aab0b853c952053bb4b8
11ebd79a77ff2f40b853c952053bb4b8
Weeks
0
11ebd79a7822e3e0b853c952053bb4b8 11ebd79a782b6f60b853c952053bb4b8 11ebd79a7833acc0b853c952053bb4b8 11ebd79a783a6380b853c952053bb4b8 11ebd79a78416860b853c952053bb4b8

11ebd79a7822e3e0b853c952053bb4b8
11ebd79a7818aab0b853c952053bb4b8
Week 1
0


11ebd79a782b6f60b853c952053bb4b8
11ebd79a7818aab0b853c952053bb4b8
Week 2
0


11ebd79a7833acc0b853c952053bb4b8
11ebd79a7818aab0b853c952053bb4b8
Week 3
0


11ebd79a783a6380b853c952053bb4b8
11ebd79a7818aab0b853c952053bb4b8
Week 4
0


11ebd79a78416860b853c952053bb4b8
11ebd79a7818aab0b853c952053bb4b8
Week 5
0


11ebd79a7849f3e0b853c952053bb4b8
11ebd79a77ff2f40b853c952053bb4b8
Course summary
0
11ebd79a78525850b853c952053bb4b8 11ebd79a785cdfa0b853c952053bb4b8 11ebd79a78656b20b853c952053bb4b8 11ebd79a786dcf90b853c952053bb4b8

11ebd79a78525850b853c952053bb4b8
11ebd79a7849f3e0b853c952053bb4b8
Topic 1
0


11ebd79a785cdfa0b853c952053bb4b8
11ebd79a7849f3e0b853c952053bb4b8
Topic 2
0


11ebd79a78656b20b853c952053bb4b8
11ebd79a7849f3e0b853c952053bb4b8
Topic 3
0


11ebd79a786dcf90b853c952053bb4b8
11ebd79a7849f3e0b853c952053bb4b8
Additional information
0


11ebd79a78835360b853c952053bb4b8
11ebd79a77f08940b853c952053bb4b8
Spanish 🇪🇦
0
11ebd79a788c5410b853c952053bb4b8

11ebd79a788c5410b853c952053bb4b8
11ebd79a78835360b853c952053bb4b8
Curso 1
0
11ebd79a7895f100b853c952053bb4b8 11ebd79a78a4e520b853c952053bb4b8 11ebd79a78d7b4f0b853c952053bb4b8
11ebd79a7dd8aea0b853c952053bb4b8 11ebd79a7de22480b853c952053bb4b8 11ebd79a7e2966b0b853c952053bb4b8
11ebd79a7895f100b853c952053bb4b8
11ebd79a788c5410b853c952053bb4b8
Tareas
0
11ebd79a789e0750b853c952053bb4b8
11ebd79a7e317d00b853c952053bb4b8
11ebd79a789e0750b853c952053bb4b8
11ebd79a7895f100b853c952053bb4b8
Grupales
0


11ebd79a78a4e520b853c952053bb4b8
11ebd79a788c5410b853c952053bb4b8
Temas tocados en clase por semana
0
11ebd79a78ab74d0b853c952053bb4b8 11ebd79a78b5fc20b853c952053bb4b8 11ebd79a78bed5c0b853c952053bb4b8 11ebd79a78c73a30b853c952053bb4b8 11ebd79a78cf7790b853c952053bb4b8

11ebd79a78ab74d0b853c952053bb4b8
11ebd79a78a4e520b853c952053bb4b8
Semana 1
0


11ebd79a78b5fc20b853c952053bb4b8
11ebd79a78a4e520b853c952053bb4b8
Semana 2
0


11ebd79a78bed5c0b853c952053bb4b8
11ebd79a78a4e520b853c952053bb4b8
Semana 3
0


11ebd79a78c73a30b853c952053bb4b8
11ebd79a78a4e520b853c952053bb4b8
Semana 4
0


11ebd79a78cf7790b853c952053bb4b8
11ebd79a78a4e520b853c952053bb4b8
Semana 5
0


11ebd79a78d7b4f0b853c952053bb4b8
11ebd79a788c5410b853c952053bb4b8
Resumen del curso
0
11ebd79a78e01960b853c952053bb4b8 11ebd79a78e87dd0b853c952053bb4b8 11ebd79a78ef5ba0b853c952053bb4b8 11ebd79a78f5eb50b853c952053bb4b8

11ebd79a78e01960b853c952053bb4b8
11ebd79a78d7b4f0b853c952053bb4b8
Tema 1
0


11ebd79a78e87dd0b853c952053bb4b8
11ebd79a78d7b4f0b853c952053bb4b8
Tema 2
0


11ebd79a78ef5ba0b853c952053bb4b8
11ebd79a78d7b4f0b853c952053bb4b8
Tema 3
0


11ebd79a78f5eb50b853c952053bb4b8
11ebd79a78d7b4f0b853c952053bb4b8
Información adicional
0



11ebd79a7dabf950b853c952053bb4b8
11ebd79a77ff2f40b853c952053bb4b8
Zoom ID
0
1620094000317
1620094000317
11ebd79a7db2d720b853c952053bb4b8
11ebd79a77ff2f40b853c952053bb4b8
Known people
0
1620094000356
1620094000356
11ebd79a7db966d0b853c952053bb4b8
11ebd79a77ff2f40b853c952053bb4b8
Team members
0
1620094000420
1620094000420
11ebd79a7dc61100b853c952053bb4b8
11ebd79a78060d10b853c952053bb4b8
Homework 1 - deadline: 23/03 - 3:45 pm
0
1620094000522
1620094000522
11ebd79a7dd8aea0b853c952053bb4b8
11ebd79a788c5410b853c952053bb4b8
Zoom ID
0
1620094013851
1620094013851
11ebd79a7de22480b853c952053bb4b8
11ebd79a788c5410b853c952053bb4b8
Personas conocidas
0
1620094013927
1620094013927
11ebd79a7e2966b0b853c952053bb4b8
11ebd79a788c5410b853c952053bb4b8
Integrantes de grupo
0
1620094013992
1620094013992
11ebd79a7e317d00b853c952053bb4b8
11ebd79a7895f100b853c952053bb4b8
Tarea 1 - Presentación: 23/03 - 3:45 pm
0
1620094014127
1620094014127

<MonkeyNote>
9274728163828<MonkeyNote>Monkey 1 🐵
Monkey 2 🐵<MonkeyNote>Monkey 1 🐵
Monkey 2 🐵
Monkey 3 🐵<MonkeyNote>• Description:<MonkeyNote>9274728163828<MonkeyNote>Monkey 1 🐵
Monkey 2 🐵<MonkeyNote>Monkey 1 🐵
Monkey 2 🐵
Monkey 3 🐵<MonkeyNote>• Descripción:`,
				`0
11ebd79a79044330b853c952053bb4b8
11ebd79a74e32230b853c952053bb4b8
Curriculum Vitae
0
11ebd79a7927a9b0b853c952053bb4b8 11ebd79a795d5fb0b853c952053bb4b8

11ebd79a7927a9b0b853c952053bb4b8
11ebd79a79044330b853c952053bb4b8
English 🇺🇸
0
11ebd79a7936ebf0b853c952053bb4b8

11ebd79a7936ebf0b853c952053bb4b8
11ebd79a7927a9b0b853c952053bb4b8
CV
0
11ebd79a793f5060b853c952053bb4b8 11ebd79a794766b0b853c952053bb4b8 11ebd79a794e1d70b853c952053bb4b8 11ebd79a79552250b853c952053bb4b8
11ebd79a7e3a0880b853c952053bb4b8
11ebd79a793f5060b853c952053bb4b8
11ebd79a7936ebf0b853c952053bb4b8
Work experience
0

11ebd79a7e426cf0b853c952053bb4b8 11ebd79a7e4aaa50b853c952053bb4b8 11ebd79a7e51af30b853c952053bb4b8
11ebd79a794766b0b853c952053bb4b8
11ebd79a7936ebf0b853c952053bb4b8
Skills
0

11ebd79a7e5865f0b853c952053bb4b8 11ebd79a7e5f1cb0b853c952053bb4b8 11ebd79a7e65d370b853c952053bb4b8
11ebd79a794e1d70b853c952053bb4b8
11ebd79a7936ebf0b853c952053bb4b8
Volunteering
0

11ebd79a7e6cb140b853c952053bb4b8
11ebd79a79552250b853c952053bb4b8
11ebd79a7936ebf0b853c952053bb4b8
Titles and certificates
0


11ebd79a795d5fb0b853c952053bb4b8
11ebd79a79044330b853c952053bb4b8
Spanish 🇪🇦
0
11ebd79a7965c420b853c952053bb4b8

11ebd79a7965c420b853c952053bb4b8
11ebd79a795d5fb0b853c952053bb4b8
CV
0
11ebd79a796e0180b853c952053bb4b8 11ebd79a7974df50b853c952053bb4b8 11ebd79a797d6ad0b853c952053bb4b8 11ebd79a7985cf40b853c952053bb4b8
11ebd79a7e738f10b853c952053bb4b8
11ebd79a796e0180b853c952053bb4b8
11ebd79a7965c420b853c952053bb4b8
Experiencia laboral
0

11ebd79a7e7bcc70b853c952053bb4b8 11ebd79a7e828330b853c952053bb4b8 11ebd79a7e8939f0b853c952053bb4b8
11ebd79a7974df50b853c952053bb4b8
11ebd79a7965c420b853c952053bb4b8
Habilidades
0

11ebd79a7e9017c0b853c952053bb4b8 11ebd79a7e96ce80b853c952053bb4b8 11ebd79a7e9dfa70b853c952053bb4b8
11ebd79a797d6ad0b853c952053bb4b8
11ebd79a7965c420b853c952053bb4b8
Voluntariados
0

11ebd79a7ea65ee0b853c952053bb4b8
11ebd79a7985cf40b853c952053bb4b8
11ebd79a7965c420b853c952053bb4b8
Títulos y certificados
0



11ebd79a7e3a0880b853c952053bb4b8
11ebd79a7936ebf0b853c952053bb4b8
Professional profile
0
1620321667546
1620321667546
11ebd79a7e426cf0b853c952053bb4b8
11ebd79a793f5060b853c952053bb4b8
Launch of the MonkeyNote preview web page - 28/02/2021 to 20/03/2021
0
1620278504920
1620278504920
11ebd79a7e4aaa50b853c952053bb4b8
11ebd79a793f5060b853c952053bb4b8
Launch of MonkeyNote, an app to organize information - 04/2020 to 08/2020
0
1620278504852
1620278504852
11ebd79a7e51af30b853c952053bb4b8
11ebd79a793f5060b853c952053bb4b8
Launch of the MonkeyNote web app - 21/03/2021 to 01/05/2021
0
1620278504970
1620278504970
11ebd79a7e5865f0b853c952053bb4b8
11ebd79a794766b0b853c952053bb4b8
Technology
0
1620321668425
1620321668425
11ebd79a7e5f1cb0b853c952053bb4b8
11ebd79a794766b0b853c952053bb4b8
General
0
1620321668495
1620321668495
11ebd79a7e65d370b853c952053bb4b8
11ebd79a794766b0b853c952053bb4b8
Soft skills
0
1620321668566
1620321668566
11ebd79a7e6cb140b853c952053bb4b8
11ebd79a794e1d70b853c952053bb4b8
Volunteer to clean the beach - 06/2019 to 08/2019
0
1620321668751
1620321668751
11ebd79a7e738f10b853c952053bb4b8
11ebd79a7965c420b853c952053bb4b8
Perfil profesional
0
1620164162831
1620164162831
11ebd79a7e7bcc70b853c952053bb4b8
11ebd79a796e0180b853c952053bb4b8
Lanzamiento de MonkeyNote, una app para organizar información - 04/2020 al 08/2020
0
1620164163026
1620164163026
11ebd79a7e828330b853c952053bb4b8
11ebd79a796e0180b853c952053bb4b8
Lanzamiento de la página web preview de MonkeyNote - 28/02/2021 al 20/03/2021
0
1620164163077
1620164163077
11ebd79a7e8939f0b853c952053bb4b8
11ebd79a796e0180b853c952053bb4b8
Lanzamiento de la web app de MonkeyNote - 21/03/2021 al 01/05/2021
0
1620232203794
1620232203794
11ebd79a7e9017c0b853c952053bb4b8
11ebd79a7974df50b853c952053bb4b8
Sistemas
0
1620164163137
1620164163137
11ebd79a7e96ce80b853c952053bb4b8
11ebd79a7974df50b853c952053bb4b8
General
0
1620164163161
1620164163161
11ebd79a7e9dfa70b853c952053bb4b8
11ebd79a7974df50b853c952053bb4b8
Blandas
0
1620244212410
1620244212410
11ebd79a7ea65ee0b853c952053bb4b8
11ebd79a797d6ad0b853c952053bb4b8
Voluntario en limpiar la playa - 06/2019 al 08/2019
0
1620164163249
1620164163249

<MonkeyNote>
• Name: MonkeyLautee
• Phone: +51 xxx xxx xxx
• Email: monkey@monkey.monkey
• LinkedIn: linkedIn.com/lautee23

• Brief description:

Mobile app and web app developer. Java programmer. MEVN Stack developer<MonkeyNote>The first version of MonkeyNote web available on the Internet. With only the basic functions of the mobile app for the user to try MonkeyNote.<MonkeyNote>An application that organizes notes in a hierarchy of folders. It has night mode, text to speech, backup, account registration, advanced search, and much more.<MonkeyNote>An exact copy of the Android application but on the web, so that everyone can use it from any device to exchange information through the account.<MonkeyNote>- Mobile application development.

- Front-end web development.

- Web stack MEVN:
MongoDB
Express
VueJS
NodeJS<MonkeyNote>- Mandarin Chinese junior level.

- Agile project management.

- Experience in digital marketing campaigns in networks.<MonkeyNote>- Teamwork.

- Work under pressure.<MonkeyNote>I picked up trash on the beach.<MonkeyNote>• Nombre: MonkeyLautee
• Celular: +51 xxx xxx xxx
• Correo: monkey@monkey.monkey
• LinkedIn: linkedIn.com/lautee23

• Descripción breve:

Desarrollador de aplicaciones móviles y de páginas web. Programador en Java. Programador en el Stack MEVN.<MonkeyNote>Una aplicación que organiza notas en jerarquía de folders. Tiene modo nocturno, texto a voz, copia de seguridad, registro de cuenta, búsqueda avanzada, y mucho más.<MonkeyNote>La primera versión de MonkeyNote web disponible en Internet. Con solo las funciones básicas de la app móvil para que el usuario pruebe MonkeyNote.<MonkeyNote>Una copia exacta de la aplicación de Android pero en la web, para que todos puedan usarla desde cualquier dispositivo para intercambiar información a través de la cuenta.<MonkeyNote>- Desarrollo de aplicaciones móviles.

- Desarrollo web front-end.

- Web stack MEVN:
MongoDB
Express
VueJS
NodeJS<MonkeyNote>- Inglés (certificado del FCE).

- Chino mandarín nivel junior.

- Gestión ágil de proyectos.

- Experiencia en campañas de marketing digital en redes.<MonkeyNote>- Trabajo en equipo.

- Trabajo bajo presión.<MonkeyNote>Recogí basura en la playa.`,
				`0
11ebd79a798e0ca0b853c952053bb4b8
11ebd79a74e32230b853c952053bb4b8
Project
0
11ebd79a79967110b853c952053bb4b8 11ebd79a79ff6c10b853c952053bb4b8

11ebd79a79967110b853c952053bb4b8
11ebd79a798e0ca0b853c952053bb4b8
English 🇺🇸
0
11ebd79a799d27d0b853c952053bb4b8

11ebd79a799d27d0b853c952053bb4b8
11ebd79a79967110b853c952053bb4b8
MoneyNote
0
11ebd79a79acdf40b853c952053bb4b8 11ebd79a79cac780b853c952053bb4b8 11ebd79a79e0c080b853c952053bb4b8 11ebd79a79f86730b853c952053bb4b8
11ebd79a7eb8fc80b853c952053bb4b8 11ebd79a7ec383d0b853c952053bb4b8
11ebd79a79acdf40b853c952053bb4b8
11ebd79a799d27d0b853c952053bb4b8
Competence
0
11ebd79a79b4ce80b853c952053bb4b8 11ebd79a79c26310b853c952053bb4b8

11ebd79a79b4ce80b853c952053bb4b8
11ebd79a79acdf40b853c952053bb4b8
✅ Beaten
0
11ebd79a79bbac50b853c952053bb4b8

11ebd79a79bbac50b853c952053bb4b8
11ebd79a79b4ce80b853c952053bb4b8
GoodNotes
0

11ebd79a7ecb7310b853c952053bb4b8
11ebd79a79c26310b853c952053bb4b8
11ebd79a79acdf40b853c952053bb4b8
VeryGoodNotes
0

11ebd79a7ed250e0b853c952053bb4b8
11ebd79a79cac780b853c952053bb4b8
11ebd79a799d27d0b853c952053bb4b8
Marketing campaign
0
11ebd79a79d17e40b853c952053bb4b8 11ebd79a79d8aa30b853c952053bb4b8

11ebd79a79d17e40b853c952053bb4b8
11ebd79a79cac780b853c952053bb4b8
Social media
0


11ebd79a79d8aa30b853c952053bb4b8
11ebd79a79cac780b853c952053bb4b8
YouTube channel
0


11ebd79a79e0c080b853c952053bb4b8
11ebd79a799d27d0b853c952053bb4b8
Platforms
0
11ebd79a79e81380b853c952053bb4b8 11ebd79a79f002c0b853c952053bb4b8

11ebd79a79e81380b853c952053bb4b8
11ebd79a79e0c080b853c952053bb4b8
Web app
0


11ebd79a79f002c0b853c952053bb4b8
11ebd79a79e0c080b853c952053bb4b8
Mobile app
0


11ebd79a79f86730b853c952053bb4b8
11ebd79a799d27d0b853c952053bb4b8
Business model
0

11ebd79a7edb0370b853c952053bb4b8 11ebd79a7ee3b600b853c952053bb4b8
11ebd79a79ff6c10b853c952053bb4b8
11ebd79a798e0ca0b853c952053bb4b8
Spanish 🇪🇦
0
11ebd79a7a09f360b853c952053bb4b8

11ebd79a7a09f360b853c952053bb4b8
11ebd79a79ff6c10b853c952053bb4b8
MoneyNote
0
11ebd79a7a127ee0b853c952053bb4b8 11ebd79a7a3410a0b853c952053bb4b8 11ebd79a7a4a30b0b853c952053bb4b8 11ebd79a7a62e8d0b853c952053bb4b8
11ebd79a7eebf360b853c952053bb4b8 11ebd79a7ef3e2a0b853c952053bb4b8
11ebd79a7a127ee0b853c952053bb4b8
11ebd79a7a09f360b853c952053bb4b8
Competencia
0
11ebd79a7a1ae350b853c952053bb4b8 11ebd79a7a2bd340b853c952053bb4b8

11ebd79a7a1ae350b853c952053bb4b8
11ebd79a7a127ee0b853c952053bb4b8
✅ Vencidos
0
11ebd79a7a2347c0b853c952053bb4b8

11ebd79a7a2347c0b853c952053bb4b8
11ebd79a7a1ae350b853c952053bb4b8
GoodNotes
0

11ebd79a7efa9960b853c952053bb4b8
11ebd79a7a2bd340b853c952053bb4b8
11ebd79a7a127ee0b853c952053bb4b8
VeryGoodNotes
0

11ebd79a7f019e40b853c952053bb4b8
11ebd79a7a3410a0b853c952053bb4b8
11ebd79a7a09f360b853c952053bb4b8
Campaña de Marketing
0
11ebd79a7a3bffe0b853c952053bb4b8 11ebd79a7a42b6a0b853c952053bb4b8

11ebd79a7a3bffe0b853c952053bb4b8
11ebd79a7a3410a0b853c952053bb4b8
Redes sociales
0


11ebd79a7a42b6a0b853c952053bb4b8
11ebd79a7a3410a0b853c952053bb4b8
Canal de Youtube
0


11ebd79a7a4a30b0b853c952053bb4b8
11ebd79a7a09f360b853c952053bb4b8
Plataformas
0
11ebd79a7a524700b853c952053bb4b8 11ebd79a7a5aab70b853c952053bb4b8

11ebd79a7a524700b853c952053bb4b8
11ebd79a7a4a30b0b853c952053bb4b8
Aplicación web
0


11ebd79a7a5aab70b853c952053bb4b8
11ebd79a7a4a30b0b853c952053bb4b8
Aplicación móvil
0


11ebd79a7a62e8d0b853c952053bb4b8
11ebd79a7a09f360b853c952053bb4b8
Modelo de negocio
0

11ebd79a7f0a29c0b853c952053bb4b8 11ebd79a7f12b540b853c952053bb4b8

11ebd79a7eb8fc80b853c952053bb4b8
11ebd79a799d27d0b853c952053bb4b8
Philosophy
0
1620361158719
1620361158719
11ebd79a7ec383d0b853c952053bb4b8
11ebd79a799d27d0b853c952053bb4b8
Story
0
1620526989633
1620526989633
11ebd79a7ecb7310b853c952053bb4b8
11ebd79a79bbac50b853c952053bb4b8
Features
0
1620526901436
1620526901436
11ebd79a7ed250e0b853c952053bb4b8
11ebd79a79c26310b853c952053bb4b8
Features
0
1620526861714
1620526861714
11ebd79a7edb0370b853c952053bb4b8
11ebd79a79f86730b853c952053bb4b8
Our income
0
1620524565829
1620524565829
11ebd79a7ee3b600b853c952053bb4b8
11ebd79a79f86730b853c952053bb4b8
Our product
0
1620527356235
1620527356235
11ebd79a7eebf360b853c952053bb4b8
11ebd79a7a09f360b853c952053bb4b8
Filosofía
0
1620524636457
1620524636457
11ebd79a7ef3e2a0b853c952053bb4b8
11ebd79a7a09f360b853c952053bb4b8
Historia
0
1620527517454
1620527517454
11ebd79a7efa9960b853c952053bb4b8
11ebd79a7a2347c0b853c952053bb4b8
Funcionalidades
0
1620527516530
1620527516530
11ebd79a7f019e40b853c952053bb4b8
11ebd79a7a2bd340b853c952053bb4b8
Funcionalidades
0
1620527516682
1620527516682
11ebd79a7f0a29c0b853c952053bb4b8
11ebd79a7a62e8d0b853c952053bb4b8
Nuestros ingresos
0
1620527517315
1620527517315
11ebd79a7f12b540b853c952053bb4b8
11ebd79a7a62e8d0b853c952053bb4b8
Nuestro producto
0
1620527517381
1620527517381

<MonkeyNote>
The tyranny of the templates is over ✊🏻🐵

From now on the user have the power of folder hierarchy 🐒 which empower them to choose individually the exact folder-note structure they want, instead of praying for a platform to fit their needs.

In MonkeyNote the user is his own master 🐵👍 through usage he discover his perfect structure. 

Other note taking apps pretends to know what you want. On the other hand, MonkeyNote gives you FREEDOM to choose your right structure 📂🐵👌🏻

Other apps think they know what you want to backup, when and where to store it 🙉 MonkeyNote gives you FREEDOM to choose to backup all your data, or only 1 folder, or 2, or 7. You choose when and where it's stored, whether it's saved on your device or in the cloud service you want.

Unlike other apps that give you a predefined structure or template, you begin MonkeyNote with just an empty folder 📂🐵 We know it's impossible for us to know what you need 🐒 because you're all unique individuals with different dreams and preferences ⚒️🐵 We only give you the best tools for you to build your perfect platform 📂

MonkeyNote impose you no limits, you're FREE to built the most complex structures to organize an entire career or course 💻🐒 or you can simply make a to-do list for your day 📝🐵 you don't need to switch between apps for different tasks, because in MonkeyNote you choose how complex or simple the app is.

That's why you will never find 2 equal MonkeyNote apps, because everyone is unique 📱🐵📱🐵📱🐵 And we love that.

Thus never more feel yourself forced to adapt to a predefined template designed by a self-called expert. You are a free monkey 🐒 and only you know what is best for you 🐵🍌<MonkeyNote>1. 🍌🐒

2. 💻🐒

3. 📱🐵👍🏻

4. 💻🐒

5. 📱💻🐵👍🏻<MonkeyNote>Can Backup notes.<MonkeyNote>You can write notes with your mind.<MonkeyNote>Earn through ads.<MonkeyNote>Offer the best data organizer platform.<MonkeyNote>Se acabó la tiranía de las plantillas ✊🏻🐵

A partir de ahora, el usuario tiene el poder de la jerarquía de fólderes 📂🐒lo que les empodera para elegir libremente la estructura exacta de carpetas y notas que necesiten, en lugar de rogar para que la plataforma de turno se adapte a sus necesidades.

En MonkeyNote el usuario es su propio amo y señor 🐵👍 a través de la práctica y el error descubre su estructura perfecta.

Otras aplicaciones pretenden saber lo que tú necesitas. Por otro lado, MonkeyNote te da la LIBERTAD para elegir tu estructura correcta 📂🐵👌🏻

Otras aplicaciones creen que saben lo que deseas guardar en una copia se seguridad, cuándo y dónde almacenarlo. 🙉 MonkeyNote te da la libertad de escoger qué guardar en tu copia de seguridad, ya sea toda tu información, sólo 1 fólder, 2 fólderes, o 7 fólderes. Tu eliges cuándo y dónde se almacena, ya sea en tu dispositivo o en el servicio en la nube que más te guste.

A diferencia de otras aplicaciones que te brindan una estructura o plantilla predefinida, en MonkeyNote comienzas con solo una carpeta vacía  📂🐵 porque sabemos que es imposible para nosotros saber lo que necesitas 🐒 porque todos ustedes son individuos únicos con diferentes sueños y gustos  ⚒️🐵 Solo te brindamos las mejores herramientas para que construyas tú mismo tu plataforma perfecta 📂

MonkeyNote no te impone límites, eres LIBRE para construir las estructuras más complejas para organizar toda una carrera o curso 💻🐒 o puedes hacer una sencilla lista de quehaceres diarios 📝🐵  No tienes que saltar de aplicación en la aplicación para diferentes tareas, en MonkeyNote tú decides qué tan complejo o qué en tan simple es tu app.

Es por eso que nunca encontrarás 2 aplicaciones MonkeyNote iguales, porque todos somos individuos únicos y especiales 📱🐵📱🐵📱🐵 Y nosotros valoramos eso.

Por lo tanto, nunca más te sientas limitado a adaptarte a una plantilla predeterminada diseñada por un supuesto experto. En MonkeyNote tú eres un mono libre 🐒 y sólo tú decides qué es lo mejor para ti.<MonkeyNote>1. 🍌🐒

2. 💻🐒

3. 📱🐵👍🏻

4. 💻🐒

5. 📱💻🐵👍🏻<MonkeyNote>Puede hacer una copia de seguridad de las notas.<MonkeyNote>Puedes escribir notas con tu mente.<MonkeyNote>Ganamos a través de anuncios.<MonkeyNote>Ofrecemos la mejor plataforma de organización de datos.`,
				`0
11ebd79a7a6b4d40b853c952053bb4b8
11ebd79a74e32230b853c952053bb4b8
YouTube channel
0
11ebd79a7a720400b853c952053bb4b8 11ebd79a7adb9b40b853c952053bb4b8

11ebd79a7a720400b853c952053bb4b8
11ebd79a7a6b4d40b853c952053bb4b8
🇺🇲 English
0
11ebd79a7a78e1d0b853c952053bb4b8 11ebd79a7a864f50b853c952053bb4b8 11ebd79a7ad2e8b0b853c952053bb4b8
11ebd79a7f1acb90b853c952053bb4b8
11ebd79a7a78e1d0b853c952053bb4b8
11ebd79a7a720400b853c952053bb4b8
Videos
0
11ebd79a7a7f9890b853c952053bb4b8

11ebd79a7a7f9890b853c952053bb4b8
11ebd79a7a78e1d0b853c952053bb4b8
📝🐵 Best note taking app 📱💻 MonkeyNote
0

11ebd79a7f218250b853c952053bb4b8 11ebd79a7f2aaa10b853c952053bb4b8
11ebd79a7a864f50b853c952053bb4b8
11ebd79a7a720400b853c952053bb4b8
Playlists
0
11ebd79a7a8d2d20b853c952053bb4b8

11ebd79a7a8d2d20b853c952053bb4b8
11ebd79a7a864f50b853c952053bb4b8
MonkeyNote tutorial 🐒📲
0
11ebd79a7a93e3e0b853c952053bb4b8 11ebd79a7aadad70b853c952053bb4b8 11ebd79a7ab7bf90b853c952053bb4b8 11ebd79a7ac02400b853c952053bb4b8 11ebd79a7aca8440b853c952053bb4b8
11ebd79a7f34bc30b853c952053bb4b8
11ebd79a7a93e3e0b853c952053bb4b8
11ebd79a7a8d2d20b853c952053bb4b8
Basics 📝🐵 MonkeyNote 📱🐒
0

11ebd79a7f3cab70b853c952053bb4b8
11ebd79a7aadad70b853c952053bb4b8
11ebd79a7a8d2d20b853c952053bb4b8
Restore file and backup ☁️📁☁️ MonkeyNote 📱🐵👍
0

11ebd79a7f436230b853c952053bb4b8
11ebd79a7ab7bf90b853c952053bb4b8
11ebd79a7a8d2d20b853c952053bb4b8
Secret folder 🔐📁 MonkeyNote 📱🐒
0

11ebd79a7f4a4000b853c952053bb4b8
11ebd79a7ac02400b853c952053bb4b8
11ebd79a7a8d2d20b853c952053bb4b8
Create a monkey account 🐒 MonkeyNote 📱🐵👍
0

11ebd79a7f527d60b853c952053bb4b8
11ebd79a7aca8440b853c952053bb4b8
11ebd79a7a8d2d20b853c952053bb4b8
MonkeyNote web app 💻🐒 tutorial 🐵🍌
0

11ebd79a7f5b2ff0b853c952053bb4b8
11ebd79a7ad2e8b0b853c952053bb4b8
11ebd79a7a720400b853c952053bb4b8
In process 📝🐵
0


11ebd79a7adb9b40b853c952053bb4b8
11ebd79a7a6b4d40b853c952053bb4b8
🇪🇸 Spanish
0
11ebd79a7ae3d8a0b853c952053bb4b8 11ebd79a7af2a5b0b853c952053bb4b8 11ebd79a7b2a5780b853c952053bb4b8
11ebd79a7f659030b853c952053bb4b8
11ebd79a7ae3d8a0b853c952053bb4b8
11ebd79a7adb9b40b853c952053bb4b8
Vídeos
0
11ebd79a7aeba0d0b853c952053bb4b8

11ebd79a7aeba0d0b853c952053bb4b8
11ebd79a7ae3d8a0b853c952053bb4b8
📝🐵 La mejor app de notas 📱💻 MonkeyNote
0

11ebd79a7f6f2d20b853c952053bb4b8 11ebd79a7f782dd0b853c952053bb4b8
11ebd79a7af2a5b0b853c952053bb4b8
11ebd79a7adb9b40b853c952053bb4b8
Listas de reproducción
0
11ebd79a7afb3130b853c952053bb4b8

11ebd79a7afb3130b853c952053bb4b8
11ebd79a7af2a5b0b853c952053bb4b8
Tutorial de MonkeyNote 🐒📲
0
11ebd79a7b0395a0b853c952053bb4b8 11ebd79a7b0bfa10b853c952053bb4b8 11ebd79a7b14aca0b853c952053bb4b8 11ebd79a7b1c9be0b853c952053bb4b8 11ebd79a7b23a0c0b853c952053bb4b8
11ebd79a7f85c260b853c952053bb4b8
11ebd79a7b0395a0b853c952053bb4b8
11ebd79a7afb3130b853c952053bb4b8
Básico 📝🐵 MonkeyNote 📱🐒
0

11ebd79a7f8f8660b853c952053bb4b8
11ebd79a7b0bfa10b853c952053bb4b8
11ebd79a7afb3130b853c952053bb4b8
Restaurar y crear copias de seguridad ☁️📁☁️ MonkeyNote 📱🐵👍
0

11ebd79a7f9a34c0b853c952053bb4b8
11ebd79a7b14aca0b853c952053bb4b8
11ebd79a7afb3130b853c952053bb4b8
Fólder Secreto 🔐📁 MonkeyNote 📱🐒
0

11ebd79a7fa38390b853c952053bb4b8
11ebd79a7b1c9be0b853c952053bb4b8
11ebd79a7afb3130b853c952053bb4b8
Crea una monkey cuenta 🐒 MonkeyNote 📱🐵👍
0

11ebd79a7facab50b853c952053bb4b8
11ebd79a7b23a0c0b853c952053bb4b8
11ebd79a7afb3130b853c952053bb4b8
MonkeyNote web app 💻🐒 tutorial 🐵🍌
0

11ebd79a7fb69660b853c952053bb4b8
11ebd79a7b2a5780b853c952053bb4b8
11ebd79a7adb9b40b853c952053bb4b8
En proceso 📝🐵
0



11ebd79a7f1acb90b853c952053bb4b8
11ebd79a7a720400b853c952053bb4b8
How to generate voice and background sound for the videos
0
1624833592457
1624833834461
11ebd79a7f218250b853c952053bb4b8
11ebd79a7a7f9890b853c952053bb4b8
Script
0
1624833592392
1624833841372
11ebd79a7f2aaa10b853c952053bb4b8
11ebd79a7a7f9890b853c952053bb4b8
Description
0
1624833742734
1624833783636
11ebd79a7f34bc30b853c952053bb4b8
11ebd79a7a8d2d20b853c952053bb4b8
Link
0
1624833591927
1624833700591
11ebd79a7f3cab70b853c952053bb4b8
11ebd79a7a93e3e0b853c952053bb4b8
Script
0
1624833592024
1624833685944
11ebd79a7f436230b853c952053bb4b8
11ebd79a7aadad70b853c952053bb4b8
Script
0
1624833592083
1624833688141
11ebd79a7f4a4000b853c952053bb4b8
11ebd79a7ab7bf90b853c952053bb4b8
Script
0
1624833592154
1624833691600
11ebd79a7f527d60b853c952053bb4b8
11ebd79a7ac02400b853c952053bb4b8
Script
0
1624833592217
1624833694347
11ebd79a7f5b2ff0b853c952053bb4b8
11ebd79a7aca8440b853c952053bb4b8
Script
0
1624833592270
1624833697676
11ebd79a7f659030b853c952053bb4b8
11ebd79a7adb9b40b853c952053bb4b8
Cómo generar voz y sonido de fondo para los videos
0
1624833849084
1624834115702
11ebd79a7f6f2d20b853c952053bb4b8
11ebd79a7aeba0d0b853c952053bb4b8
Guión
0
1624833848593
1624834122683
11ebd79a7f782dd0b853c952053bb4b8
11ebd79a7aeba0d0b853c952053bb4b8
Descripción
0
1624833848637
1624834121860
11ebd79a7f85c260b853c952053bb4b8
11ebd79a7afb3130b853c952053bb4b8
Link
0
1624833848737
1624833973342
11ebd79a7f8f8660b853c952053bb4b8
11ebd79a7b0395a0b853c952053bb4b8
Guión
0
1624833848792
1624833904625
11ebd79a7f9a34c0b853c952053bb4b8
11ebd79a7b0bfa10b853c952053bb4b8
Guión
0
1624833848847
1624833930242
11ebd79a7fa38390b853c952053bb4b8
11ebd79a7b14aca0b853c952053bb4b8
Guión
0
1624833848903
1624833947271
11ebd79a7facab50b853c952053bb4b8
11ebd79a7b1c9be0b853c952053bb4b8
Guión
0
1624833848966
1624833962068
11ebd79a7fb69660b853c952053bb4b8
11ebd79a7b23a0c0b853c952053bb4b8
Guión
0
1624833849018
1624833970854

<MonkeyNote>
🐒<MonkeyNote>It's likely that you have struggled in the past when organizing your tasks, projects, courses or job data because the software you used didn't fit your requirements.

Maybe it lets you change your note's color to organize them in categories, but it becomes a mess when you need to set subcategories.

Maybe you create long notes to summarize a subject, but you can't search a specific topic because there are no searching tools.

Or you have to study the night before your exam but your old note taking app doesn't have a dark mode to protect your eyes or at least a tool to read aloud the summaries for you.

Maybe you misspelled a word and you needed to correct it word by word because your old app didn't have word replacing tools.

Maybe you need to hide some information but your old note taking app doesn't have an option to protect your notes with a password and optional secret questions.

Maybe you accidentally deleted a note, and your old app didn't have a recycle bin.

Or maybe your phone was destroyed in an accident and you couldn't recover your data because it didn't have a backup tool.

Or in the best case scenario you found all those features mentioned before, but you end with many note taking apps in your phone. One for the backup feature, another for the dark theme, another for long notes, another for short notes, and so on. That was our case for many many years.

Until we the monkeys joined together and think about creating one app to migrate to.

An app that treat us better

An app that gives us security with an advanced and custom backup system.

An app that doesn't have a predefined template, but rather gives us the tools to create templates ourselves.

An app that take care of our sight by having a dark mode and a tool to read aloud our notes.

And with that idea and motivation in mind, we created MonkeyNote.

An app to migrate to in search of better opportunities.

An app in which you can reach higher levels of efficiency, thanks to its simplicity.

That's MonkeyNote, and more.

And MonkeyNote promises you to keep improving over the time.

You can send us feedback and ideas to improve the app efficiency.

By using the app you become part of the Monkey community, and everyone in the community will be listened.

So what are you waiting for?

Migrate to MonkeyNote and boost your productivity above your limits.

Keep it simple<MonkeyNote>Mobile app 📱
https://play.google.com/store/apps/details?id=com.lautee.monkeynote

Web app 💻
https://monkeynote-83ca2.web.app

📝🐵 MonkeyNote features:

✅ Folder hierarchy 📁

✅ Night mode 📱🐵💤

✅ Advanced and customizable backup system ☁️📁☁️

✅ Advanced word searching tools 🐵🔎🗒️ 📁

✅ Replace a word in a note 🗒️🐵👌🏻

✅ Share notes or folders with your friends 📝🐵    📝🐵

✅ Text-to-speech in different tones, voices, languages and speeds 🗒️🐵💬 

✅ Secret folder protected by a password and secret questions in case you forget your password 🗒️🔐

✅ Favorites section ⭐📂⭐🗒️

✅ Recycle bin, in case you accidentally delete a note 🐵🔎🗑️

✅ Create a MonkeyNote account to access your data anywhere 🌐 📁📝

✅ Available as a web app too 💻🐵


🐒🐒🐒🐒 Use MonkeyNote 🐒🐒🐒🐒<MonkeyNote>https://youtube.com/playlist?list=PL9odQ4B5jTZTlhwSAIeRKMfTrxAs8l406<MonkeyNote>In MonkeyNote your information is organized in folders and notes.

A note have a title and a body.

A folder store notes or other folders.

And so on.

These 2 simple rules creates the folder hierarchy.

And in the top of the folder hierarchy is the main folder which contains all your data.

This is everything about MonkeyNote. The next are just complementary tools.

Sometimes you have favorite folder and notes that are very deep inside your folder hierarchy. And you have to take many steps to reach them.

To quickly access them, set them as favorite, so that they appear in the homepage screen.

Sometimes you use MonkeyNote at night, to protect your eyes change the theme to dark.

Sometime we can accidentally delete important data. That's not a problem in MonkeyNote, just go to the recycle bin and restore it.

MonkeyNote can read aloud your notes. Just open the note and select read aloud. You can choose your language, the voice, the pitch and the speed.

You can copy a note or a folder.

And you can move your folders or notes to different locations.

MonkeyNote has advanced searching tools.

Inside a note you can search a word. You will see how many times it was written and where.

In a folder you can also search a word.

You can search it in the Main Folder, or in any other folder.

The word can be searched only in folder names, only in note titles, only in note bodies, or any combination you want.

Or only in the favorite items.

As you can see, in MonkeyNote you will always find what you want, everything is organized in your own way, and you have a lot of advanced tools to manage your data.<MonkeyNote>Information in MonkeyNote is stored as a dot monkeynote file extension.

You can backup all your data, in other words the main folder.

Or you can backup only one folder.

Just go to the backup screen and choose what you want to do.

The backup can be downloaded locally in your device.

Or you can share it.

Also you can save it in your preferred cloud service, for example Google drive.

If you want to store your backup in Google drive, just create a folder, then press "Share backup", select google drive and finally select the folder you've just created.

As simple as that.

To restore file go to the backup screen, press restore and finally choose the file.

As you can see the backup system is very flexible and you can do what you want with your monkeynote files.<MonkeyNote>It is just like any other folder. The only difference is that it is protected by a password and optional secret questions made by you.

And the Secret Folder content is never included in the backup, for security reasons.

It's impossible to see the content if you don't know the password or the answer of at least one secret question.

But what if you forgot your password? It shouldn't happen because that's why we created the secret questions. But in the case you can't remember your password or at least the answer of one of the secret questions. The only way to use again the secret folder is to reset MonkeyNote app in your phone, but the content cannot be recovered.

First of all backup all your data somewhere, because what we are going to do is to delete all the data inside the app.

To download all your data, go to the backup screen
Select backup all into downloads
And wait until the backup is created

Only after you downloaded all your data, follow the next steps:

Go to your phone Settings
Go to the Apps section
Select MonkeyNote
Select Storage
And finally select Clear Data.

If you followed the instructions, MonkeyNote is completely empty and you can use the secret folder again.

And to recover the previous data in the main folder, just go to the backup screen
Select restore file
And choose the backup file created previously, which was saved in the Downloads directory.

As you can see the secret folder content maybe is too much protected.

Just try to never forget your password.

And create at least one good secret question, just in case.<MonkeyNote>To create an account select sign in, and then press in create account.

To sign in just enter your email and password.

If you forgot your password just press here.

To sign out press here.

And to delete your account press here.

With your account you have access to your Fast note, which is a simple note but it's stored in your account so that you can quickly use it in all your devices.

And also the web folder which is just a simple folder but stored in your account.

Oh, and this is your monkey account profile picture.<MonkeyNote>The web app is an exact copy of the mobile app, except that it doesn't have the secret folder.

But there is one thing that only the web app has that will boost your productivity above your limits.

The shortcuts.

With the shortcuts you will navigate through your folders and notes at such speed that people around you will see only random flashes in your screen.

They're extremely easy to use and they are very intuitive.

Let's see for example the you want to open the favorite item one, which in this case is the favorite folder 1. Just press 1. To open the favorite item 2, press 2; and so on.

But what if you specifically want to open the favorite note number one?

Just press N followed by the number. N plus one opens the favorite Note 1. N plus  2 opens the favorite note 2 and so on.

The same rule applies to folders but instead of the letter n, it is the letter f.

F of folder.

But what happen if we want to open items in the main folder?
Yeah, you guessed it. All the rules are the same but you will have to press M at the beginning.

M of main folder.

What if you want to create a note?
Yeah, you guessed it again. Just press c plus n.

And to create a folder c + f. As you may expected.

What if you are working at night and want to change the theme?
Yes, press the letter T.
T of theme.

What if you want to back up your information?
Press the letter B.
B of backup.

What if you want to search a word?
Press the letter S.
S of search.
This works also inside the folder.

What if you want to open the recycle bin?
Press the letter r.
R of recycle bin.
And if you want to clear the content of the recycle bin, press the letter c.
C of clear.

These shortcuts work inside any other folder, and not only in the homepage.

There are also shortcuts for notes.
Press alt plus letter v, to activate the voice typing inside a note. As you can see the tools are displayed.
Press alt plus letter l, to change the language.
Press alt plus letter v once more to start typing with your voice. When this icon is red, MonkeyNote is ready to listen to your voice.

And to close all the voice typing tools just press alt plus x.

There are shortcuts when reading aloud a note.

Move between the paragraphs with upwards arrow and the downwards arrow keys.

Play and pause with the space key.
Reset or stop with R key.

To display the list of languages, press L.
To display the list of voices, press V.
To change the pitch, press P.
To change the speed, press S.

And they are all the shortcuts in monkeynote web app.

The more you use them, the fast you're going to open your notes and your folders, and the productive you will be.

Hope it helps you to ease your life.

Keep it simple.<MonkeyNote>🐒<MonkeyNote>It's likely that you have struggled in the past when organizing your tasks, projects, courses or job data because the software you used didn't fit your requirements.

Maybe it lets you change your note's color to organize them in categories, but it becomes a mess when you need to set subcategories.

Maybe you create long notes to summarize a subject, but you can't search a specific topic because there are no searching tools.

Or you have to study the night before your exam but your old note taking app doesn't have a dark mode to protect your eyes or at least a tool to read aloud the summaries for you.

Maybe you misspelled a word and you needed to correct it word by word because your old app didn't have word replacing tools.

Maybe you need to hide some information but your old note taking app doesn't have an option to protect your notes with a password and optional secret questions.

Maybe you accidentally deleted a note, and your old app didn't have a recycle bin.

Or maybe your phone was destroyed in an accident and you couldn't recover your data because it didn't have a backup tool.

Or in the best case scenario you found all those features mentioned before, but you end with many note taking apps in your phone. One for the backup feature, another for the dark theme, another for long notes, another for short notes, and so on. That was our case for many many years.

Until we the monkeys joined together and think about creating one app to migrate to.

An app that treat us better

An app that gives us security with an advanced and custom backup system.

An app that doesn't have a predefined template, but rather gives us the tools to create templates ourselves.

An app that take care of our sight by having a dark mode and a tool to read aloud our notes.

And with that idea and motivation in mind, we created MonkeyNote.

An app to migrate to in search of better opportunities.

An app in which you can reach higher levels of efficiency, thanks to its simplicity.

That's MonkeyNote, and more.

And MonkeyNote promises you to keep improving over the time.

You can send us feedback and ideas to improve the app efficiency.

By using the app you become part of the Monkey community, and everyone in the community will be listened.

So what are you waiting for?

Migrate to MonkeyNote and boost your productivity above your limits.

Keep it simple<MonkeyNote>Mobile app 📱
https://play.google.com/store/apps/details?id=com.lautee.monkeynote

Web app 💻
https://monkeynote-83ca2.web.app

📝🐵 MonkeyNote features:

✅ Folder hierarchy 📁

✅ Night mode 📱🐵💤

✅ Advanced and customizable backup system ☁️📁☁️

✅ Advanced word searching tools 🐵🔎🗒️ 📁

✅ Replace a word in a note 🗒️🐵👌🏻

✅ Share notes or folders with your friends 📝🐵    📝🐵

✅ Text-to-speech in different tones, voices, languages and speeds 🗒️🐵💬 

✅ Secret folder protected by a password and secret questions in case you forget your password 🗒️🔐

✅ Favorites section ⭐📂⭐🗒️

✅ Recycle bin, in case you accidentally delete a note 🐵🔎🗑️

✅ Create a MonkeyNote account to access your data anywhere 🌐 📁📝

✅ Available as a web app too 💻🐵


🐒🐒🐒🐒 Use MonkeyNote 🐒🐒🐒🐒<MonkeyNote>https://youtube.com/playlist?list=PL9odQ4B5jTZTlhwSAIeRKMfTrxAs8l406<MonkeyNote>In MonkeyNote your information is organized in folders and notes.

A note have a title and a body.

A folder store notes or other folders.

And so on.

These 2 simple rules creates the folder hierarchy.

And in the top of the folder hierarchy is the main folder which contains all your data.

This is everything about MonkeyNote. The next are just complementary tools.

Sometimes you have favorite folder and notes that are very deep inside your folder hierarchy. And you have to take many steps to reach them.

To quickly access them, set them as favorite, so that they appear in the homepage screen.

Sometimes you use MonkeyNote at night, to protect your eyes change the theme to dark.

Sometime we can accidentally delete important data. That's not a problem in MonkeyNote, just go to the recycle bin and restore it.

MonkeyNote can read aloud your notes. Just open the note and select read aloud. You can choose your language, the voice, the pitch and the speed.

You can copy a note or a folder.

And you can move your folders or notes to different locations.

MonkeyNote has advanced searching tools.

Inside a note you can search a word. You will see how many times it was written and where.

In a folder you can also search a word.

You can search it in the Main Folder, or in any other folder.

The word can be searched only in folder names, only in note titles, only in note bodies, or any combination you want.

Or only in the favorite items.

As you can see, in MonkeyNote you will always find what you want, everything is organized in your own way, and you have a lot of advanced tools to manage your data.<MonkeyNote>Information in MonkeyNote is stored as a dot monkeynote file extension.

You can backup all your data, in other words the main folder.

Or you can backup only one folder.

Just go to the backup screen and choose what you want to do.

The backup can be downloaded locally in your device.

Or you can share it.

Also you can save it in your preferred cloud service, for example Google drive.

If you want to store your backup in Google drive, just create a folder, then press "Share backup", select google drive and finally select the folder you've just created.

As simple as that.

To restore file go to the backup screen, press restore and finally choose the file.

As you can see the backup system is very flexible and you can do what you want with your monkeynote files.<MonkeyNote>It is just like any other folder. The only difference is that it is protected by a password and optional secret questions made by you.

And the Secret Folder content is never included in the backup, for security reasons.

It's impossible to see the content if you don't know the password or the answer of at least one secret question.

But what if you forgot your password? It shouldn't happen because that's why we created the secret questions. But in the case you can't remember your password or at least the answer of one of the secret questions. The only way to use again the secret folder is to reset MonkeyNote app in your phone, but the content cannot be recovered.

First of all backup all your data somewhere, because what we are going to do is to delete all the data inside the app.

To download all your data, go to the backup screen
Select backup all into downloads
And wait until the backup is created

Only after you downloaded all your data, follow the next steps:

Go to your phone Settings
Go to the Apps section
Select MonkeyNote
Select Storage
And finally select Clear Data.

If you followed the instructions, MonkeyNote is completely empty and you can use the secret folder again.

And to recover the previous data in the main folder, just go to the backup screen
Select restore file
And choose the backup file created previously, which was saved in the Downloads directory.

As you can see the secret folder content maybe is too much protected.

Just try to never forget your password.

And create at least one good secret question, just in case.<MonkeyNote>To create an account select sign in, and then press in create account.

To sign in just enter your email and password.

If you forgot your password just press here.

To sign out press here.

And to delete your account press here.

With your account you have access to your Fast note, which is a simple note but it's stored in your account so that you can quickly use it in all your devices.

And also the web folder which is just a simple folder but stored in your account.

Oh, and this is your monkey account profile picture.<MonkeyNote>The web app is an exact copy of the mobile app, except that it doesn't have the secret folder.

But there is one thing that only the web app has that will boost your productivity above your limits.

The shortcuts.

With the shortcuts you will navigate through your folders and notes at such speed that people around you will see only random flashes in your screen.

They're extremely easy to use and they are very intuitive.

Let's see for example the you want to open the favorite item one, which in this case is the favorite folder 1. Just press 1. To open the favorite item 2, press 2; and so on.

But what if you specifically want to open the favorite note number one?

Just press N followed by the number. N plus one opens the favorite Note 1. N plus  2 opens the favorite note 2 and so on.

The same rule applies to folders but instead of the letter n, it is the letter f.

F of folder.

But what happen if we want to open items in the main folder?
Yeah, you guessed it. All the rules are the same but you will have to press M at the beginning.

M of main folder.

What if you want to create a note?
Yeah, you guessed it again. Just press c plus n.

And to create a folder c + f. As you may expected.

What if you are working at night and want to change the theme?
Yes, press the letter T.
T of theme.

What if you want to back up your information?
Press the letter B.
B of backup.

What if you want to search a word?
Press the letter S.
S of search.
This works also inside the folder.

What if you want to open the recycle bin?
Press the letter r.
R of recycle bin.
And if you want to clear the content of the recycle bin, press the letter c.
C of clear.

These shortcuts work inside any other folder, and not only in the homepage.

There are also shortcuts for notes.
Press alt plus letter v, to activate the voice typing inside a note. As you can see the tools are displayed.
Press alt plus letter l, to change the language.
Press alt plus letter v once more to start typing with your voice. When this icon is red, MonkeyNote is ready to listen to your voice.

And to close all the voice typing tools just press alt plus x.

There are shortcuts when reading aloud a note.

Move between the paragraphs with upwards arrow and the downwards arrow keys.

Play and pause with the space key.
Reset or stop with R key.

To display the list of languages, press L.
To display the list of voices, press V.
To change the pitch, press P.
To change the speed, press S.

And they are all the shortcuts in monkeynote web app.

The more you use them, the fast you're going to open your notes and your folders, and the productive you will be.

Hope it helps you to ease your life.

Keep it simple.`,
			];
			const download=index=>{
				if(confirm("Download the template?")){
					restore(templateCodes[index],db,loading);
				}
			}
			return {dark,templates,download,loading}
		}
	}
</script>

<style scoped>
	span {
		font-size:1.6em;
	}
	.addPadding {
		padding: 16px;
	}
	.template {
		margin:16px;
		padding:16px;
	}
	.template:hover {
		background-color:rgba(0,0,0,0.06);
	}
</style>