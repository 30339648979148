<template>
	<div :class="dark?'monkeyBar darkCard':'monkeyBar'">
		<i @click="$router.go(-1)" class="material-icons" aria-hidden="true">arrow_back</i>
		<h3 class="titleText unselectable">Help us</h3>
	</div>
	<div class="contentPadding" :style="dark?'color:white;':'color:black;'">
		<p class="bodyText">You can support us via PayPal, to keep developing new features for MonkeyNote.</p>
		<button @click="share()" class="monkeyButton" :class="dark?'darkCard':''">
			<a class="titleText" style="color:white;">Share MonkeyNote</a>
		</button>
		<p class="bodyText">We really appreciate your help.</p>
		<button class="monkeyButton" :class="dark?'darkCard':''">
			<a class="titleText" style="text-decoration:none;color:white;" href="https://paypal.me/lautee23">
				Donate via paypal
			</a>
		</button>
		<br>
		<p class="bodyText">You can also send us feedback:</p>
		<button class="monkeyButton" :class="dark?'darkCard':''">
			<a class="titleText" style="text-decoration:none;color:white;" href="mailto:monkeylautee@gmail.com">monkeylautee@gmail.com</a>
		</button>
		<br>
		<p class="bodyText">Touch the monkey to give him a banana...</p>
		<p @click="encourageMonkey()" id="happyMonkey" style="-webkit-tap-highlight-color: transparent;">🐵</p>
	</div>
	<div id="snackbar" class="bodyText">🐵 Text copied...</div>
</template>

<script>
	export default {
		name: 'HelpUs',
		setup(props,context){
			const dark = localStorage.getItem('darkTheme')=="true";
			const encourageMonkey=()=>{
				const happyMonkey = document.getElementById("happyMonkey");
				happyMonkey.innerHTML = happyMonkey.innerHTML + '🍌';
			}
			const share=()=>{
				const input = document.createElement('input');
				input.setAttribute('value',"Use MonkeyNote 📝🐵 the best note taking app 🎦 https://youtu.be/YqZN2zjgF8w");
				document.body.appendChild(input);
				input.select();
				const result = document.execCommand('copy');
				document.body.removeChild(input);
				//Show the snackbar
				const x=document.getElementById("snackbar");
				x.className="show";
				setTimeout(function(){x.className=x.className.replace("show","");},3000);
			}
			return {dark,encourageMonkey,share}
		}
	}
</script>

<style scoped>
	#happyMonkey {
		font-size:66px;
		text-align:right;
		cursor:pointer;
		-webkit-tap-highlight-color: transparent;
		/*Avoid  the text to be selectable*/
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	button a:hover {
		background-color: rgba(0,0,0,0) !important;
	}
	/*Snackbar*/
	#snackbar {
		visibility: hidden;
		min-width: 250px;
		margin-left: -125px;
		background-color: #333;
		color: #fff;
		text-align: center;
		border-radius: 2px;
		padding: 16px;
		position: fixed;
		z-index: 1;
		left: 50%;
		bottom: 30px;
		font-size: 17px;
	}
	#snackbar.show {
		visibility: visible;
		-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
		animation: fadein 0.5s, fadeout 0.5s 2.5s;
	}
	@-webkit-keyframes fadein {
		from {bottom: 0; opacity: 0;} 
		to {bottom: 30px; opacity: 1;}
	}
	@keyframes fadein {
		from {bottom: 0; opacity: 0;}
		to {bottom: 30px; opacity: 1;}
	}
	@-webkit-keyframes fadeout {
		from {bottom: 30px; opacity: 1;} 
		to {bottom: 0; opacity: 0;}
	}
	@keyframes fadeout {
		from {bottom: 30px; opacity: 1;}
		to {bottom: 0; opacity: 0;}
	}
</style>