import Localbase from 'localbase';

const db = new Localbase('monkeynote');
let alwaysTrue=true;

const deleteFolder=async(folderID)=>{
	try {
		//Get the folder to be deleted
		let folderToBeDeleted = await db.collection("folders").doc(folderID).get();
		//Remove from favorites
		if(folderToBeDeleted.favorite){
			let x = localStorage.getItem("favoriteFolders").split(" ");
			x.splice(x.indexOf(folderID),1);
			while(alwaysTrue){//Delete the empty items
				if(x.indexOf("")!=-1){
					x.splice(x.indexOf(""),1);
				} else {
					break;
				}
			}
			localStorage.setItem("favoriteFolders",x.join(" "));
		}
		//Remove it from its parent folder list of folders
		if(folderToBeDeleted.parentID==null){
			//It's in the main folder
			let y = localStorage.getItem("mainFolderFolders").split(" ");
			y.splice(y.indexOf(folderID),1);
			while(alwaysTrue){//Delete the empty items
				if(y.indexOf("")!=-1){
					y.splice(y.indexOf(""),1);
				} else {
					break;
				}
			}
			localStorage.setItem("mainFolderFolders",y.join(" "));
		} else {
			//It's not in the main folder
			let parentFolder = await db.collection("folders").doc(folderToBeDeleted.parentID).get();
			let z = parentFolder.foldersID.split(" ");
			z.splice(z.indexOf(folderID),1);
			await db.collection("folders").doc(folderToBeDeleted.parentID).update({
				foldersID: z.join(" "),
			});
		}
		//Delete all notes inside the folder to be deleted
		if(folderToBeDeleted.notesID!=""&&folderToBeDeleted.notesID!=" "){
			let w = folderToBeDeleted.notesID.split(" ");
			for (let i = w.length - 1; i >= 0; i--) {
				await deleteNote(w[i],db,true);
			}
		}
		//Delete all folders inside
		if(folderToBeDeleted.foldersID!=""&&folderToBeDeleted.foldersID!=" "){
			let k = folderToBeDeleted.foldersID.split(" ");
			for (let i = k.length - 1; i >= 0; i--) {
				await deleteFolder(k[i],db,true);
			}
		}
		//Delete the folder itself from localbase
		await db.collection("folders").doc(folderID).delete();
	} catch(e) {
		alert("There was an error");
    console.log(e);
	}
}

const deleteNote=async(noteID)=>{
	//Get the note
	let noteToBeDeleted = await db.collection("notes").doc(noteID).get();
	//Remove it from favorites if favorite
	if(noteToBeDeleted.favorite){
		let x = localStorage.getItem("favoriteNotes").split(" ");
		x.splice(x.indexOf(noteID),1);
		while(alwaysTrue){//Delete the empty items
			if(x.indexOf("")!=-1){
				x.splice(x.indexOf(""),1);
			} else {
				break;
			}
		}
		localStorage.setItem("favoriteNotes",x.join(" "));
	}
	//Remove it from its parent folder list of notes
	let parentFolderID = noteToBeDeleted.folderID;
	if(parentFolderID==null||parentFolderID==undefined){//If it's inside the main folder
		let x = localStorage.getItem("mainFolderNotes").split(" ");
		x.splice(x.indexOf(noteID),1);
		while(alwaysTrue){//Delete the empty items
			if(x.indexOf("")!=-1){
				x.splice(x.indexOf(""),1);
			} else {
				break;
			}
		}
		localStorage.setItem("mainFolderNotes",x.join(" "));
	} else {//If it's not inside the main folder
		let parentFolderNoteIDs = (await db.collection("folders").doc(parentFolderID).get()).notesID.split(" ");
		parentFolderNoteIDs.splice(parentFolderNoteIDs.indexOf(noteID),1);
		await db.collection('folders').doc(parentFolderID).update({
			notesID: parentFolderNoteIDs.join(" "),
		})
	}
	//Update the info in the note
	await db.collection('notes').doc(noteID).update({folderID:null,favorite:false});
	//Add it to the recycle bin
	if(localStorage.getItem("deletedNotes")==null||localStorage.getItem("deletedNotes")==""){
		localStorage.setItem("deletedNotes",noteID);
	} else {
		let x = localStorage.getItem("deletedNotes").split(" ");
		x.push(noteID);
		while(alwaysTrue){//Delete the empty items
			if(x.indexOf("")!=-1){
				x.splice(x.indexOf(""),1);
			} else {
				break;
			}
		}
		localStorage.setItem("deletedNotes",x.join(" "));
	}
}

export const getFolderFullContent=async(topFolderID,folders,notes)=>{
  await getAllFoldersBelow(topFolderID,folders);
  await getAllNotesBelow(topFolderID,notes);
}

export const getAllFoldersBelow=async(topFolderID,folders)=>{
  const topFolder = await db.collection("folders").doc(topFolderID).get();
  let topFolderFoldersID;
  if(topFolder.foldersID&&topFolder.foldersID.trim()!=""){
    topFolderFoldersID=topFolder.foldersID.split(" ");
  } else {
    topFolderFoldersID=[];
  }
  for(let c=0;c<topFolderFoldersID.length;c++){
    let y = await db.collection("folders").doc(topFolderFoldersID[c]).get();
    //This is just to see if it throws an error
    try {
      if(y.id)folders.push(y);
    } catch(e) {
      //If the folder doesn't exists, ignore it
      console.log(e);
    }
    await getAllFoldersBelow(topFolderFoldersID[c],folders);
  }
}

export const getAllNotesBelow=async(topFolderID,notes)=>{
  let topFolder = await db.collection("folders").doc(topFolderID).get();
  //Get the top folder list of notes IDs
  let topFolderNotesID;
  if(topFolder.notesID&&topFolder.notesID.trim()!=""){
    topFolderNotesID=topFolder.notesID.split(" ");
  } else {
    topFolderNotesID=[];
  }
  //Add the notes in the folder
  for(let c=0;c<topFolderNotesID.length;c++){
    let x = await db.collection("notes").doc(topFolderNotesID[c]).get();
    //This is just to see if it throws an error
    try {
      if(x.id)notes.push(x);
    } catch(e) {
      //If the note doesn't exists, ignore it
      console.log(e);
    }
  }
  //Get the top folder list of folder IDs
  let topFolderFoldersID;
  if(topFolder.foldersID&&topFolder.foldersID.trim()!=""){
    topFolderFoldersID=topFolder.foldersID.split(" ");
  } else {
    topFolderFoldersID=[];
  }
  //Add the notes inside each of the folders below
  for(let c=0;c<topFolderFoldersID.length;c++){
    await getAllNotesBelow(topFolderFoldersID[c],notes);
  }
}

export const fixIndexedIssue=async()=>{
  //Get the deleted notes IDs
  console.log("These are the deleted notes:");
  const deletedNotes = [];
  const deletedNoteIDs = localStorage.getItem("deletedNotes");
  const deletedNoteIDsThatDoesNotExist = [];
  if(deletedNoteIDs!=null&&deletedNoteIDs.trim()!=""){
    const a = deletedNoteIDs.split(" ");
    for (let i = a.length - 1; i >= 0; i--) {
      try {
        let x = await db.collection("notes").doc(a[i]).get();
        deletedNotes.push({id:a[i],title:x.title});
      } catch(e) {
        console.log("The note with the ID: "+a[i]+" does not exists");
        deletedNoteIDsThatDoesNotExist.push(a[i]);
      }
    }
    //Remove the deleted note IDs that doesn't exist
    for (let i = 0; i < deletedNoteIDsThatDoesNotExist.length; i++) {
      a.splice(a.indexOf(deletedNoteIDsThatDoesNotExist[i]),1);
    }
    localStorage.setItem("deletedNotes",a.join(" "));
  }
  for(let c=0;c<deletedNotes.length;c++){
    console.log(`#${c+1}: ${deletedNotes[c].title}`);
  }

  //Get all the Folder and notes IDs
  const allFolderIDs = [];
  const allFolders = await db.collection('folders').get();
  for (let i = allFolders.length - 1; i >= 0; i--) {
    allFolderIDs.push(allFolders[i].id);
  }
  const allNoteIDs = [];
  const allNotes = await db.collection('notes').get();
  for (let i = allNotes.length - 1; i >= 0; i--) {
    allNoteIDs.push(allNotes[i].id);
  }
  
  //Get the main folder and note IDs
  let mainFolderFolderIDs = [];
  const mainFolderFolderIDsAsString = localStorage.getItem("mainFolderFolders");
  if(mainFolderFolderIDsAsString!=null&&mainFolderFolderIDsAsString.trim()!=""){
    mainFolderFolderIDs=mainFolderFolderIDsAsString.split(" ");
  } else {
    mainFolderFolderIDs = [];
  }
  let mainFolderNoteIDs = [];
  const mainFolderNoteIDsAsString = localStorage.getItem("mainFolderNotes");
  if(mainFolderNoteIDsAsString!=null&&mainFolderNoteIDsAsString.trim()!=""){
    mainFolderNoteIDs=mainFolderNoteIDsAsString.split(" ");
  } else {
    mainFolderNoteIDs = [];
  }

  //Get all the folders and notes that can be accessible
  const folders = [];
  const notes = [];
  for(let c=0;c<mainFolderFolderIDs.length;c++){
    folders.push(await db.collection("folders").doc(mainFolderFolderIDs[c]).get());
    await getAllFoldersBelow(mainFolderFolderIDs[c],folders);
    await getAllNotesBelow(mainFolderFolderIDs[c],notes);
  }
  for(let c=0;c<mainFolderNoteIDs.length;c++){
    notes.push(await db.collection("notes").doc(mainFolderNoteIDs[c]).get());
  }

  const reachableFolderIDs = [];
  for(let c=0;c<folders.length;c++){
    reachableFolderIDs.push(folders[c].id);
  }
  const reachableNoteIDs = [];
  for(let c=0;c<notes.length;c++){
    reachableNoteIDs.push(notes[c].id);
  }

  console.log("Number of all notes is: "+allNoteIDs.length.toString());
  console.log("Number of all folders is: "+allFolderIDs.length.toString());
  console.log("Number of all reachable notes is: "+reachableNoteIDs.length.toString());
  console.log("Number of all reachable folders is: "+reachableFolderIDs.length.toString());

  console.log("");

  //Get the unreachable folder IDs
  const unreachableFolderIDs = [];
  for(let c=0;c<allFolderIDs.length;c++){
    if(reachableFolderIDs.indexOf(allFolderIDs[c])==-1){
      unreachableFolderIDs.push(allFolderIDs[c]);
    }
  }

  //Get the unreachable note IDs
  const unreachableNoteIDs = [];
  for(let c=0;c<allNoteIDs.length;c++){
    if(reachableNoteIDs.indexOf(allNoteIDs[c])==-1){
      unreachableNoteIDs.push(allNoteIDs[c]);
    }
  }

  //Removing the delete notes from unreachableNoteIDs
  for(let c=0;c<deletedNotes.length;c++){
    console.log("Removing one deleted note from unreachableNoteIDs");
    unreachableNoteIDs.splice(unreachableNoteIDs.indexOf(deletedNotes[c].id),1);
  }

  //Unreachable folders and notes numbers
  console.log("");
  console.log("Unreachable folders: "+unreachableFolderIDs.length.toString());
  console.log("Unreachable notes: "+unreachableNoteIDs.length.toString());

  //Delete unreachable elements
  console.log("");
  console.log("Deleting the unreachable elements...");
  for(let c=0;c<unreachableFolderIDs.length;c++){
    await db.collection("folders").doc(unreachableFolderIDs[c]).delete();
  }
  for(let c=0;c<unreachableNoteIDs.length;c++){
    await db.collection("notes").doc(unreachableNoteIDs[c]).delete();
  }
}

export const restore=async(fileContent,db,loading)=>{
  loading.value=true;
  try {
    
    //Get the lines of the backup as a list of Strings
    let lines = fileContent.split('\n');

    //See whether it's a whole database backup or just a portion
    let isPortion = lines[0]=='0';

    //Get all the folders and notes
    let index;
    let folders = [];
    for(let c=1;alwaysTrue;c=c+6){
      if(lines[c]==''){//End of the folder section
        index = c;
        break;
      }
      folders.push({
        id: lines[c],
        parentID: lines[c+1]=='null'?null:lines[c+1],
        name: lines[c+2],
        favorite: lines[c+3]=='1',
        foldersID: lines[c+4].trim(),
        notesID: lines[c+5].trim(),
      });
    }
    let notes = [];
    index++;//Go the the section of the notes, just below the empty line after the folder section
    for(let c=index;alwaysTrue;c=c+6){
      if(lines[c]==''){//End of the note section
        index = c;
        break;
      }
      notes.push({
        id: lines[c],
        folderID: lines[c+1]=='null'?null:lines[c+1],
        title: lines[c+2],
        favorite: lines[c+3]=='1',
        creationDate: parseInt(lines[c+4]),
        lastModified: lines[c+5].trim()==''||lines[c+5].toLowerCase()=='null'?parseInt(lines[c+4]):parseInt(lines[c+5]),
      });
    }

    //Get the separator
    index++;//Go to the line of the separator
    let separator = lines[index];

    if(notes.length>0){
      //Get the list of bodies
      index++;//Go to the bodies section
      let bodies = [];
      let rawBodies = [];
      for(let c=index;c<lines.length;c++){
        rawBodies.push(lines[c]);
      }
      bodies = (rawBodies.join('\n')).split(separator);

      //Add all the bodies to their respective notes
      for(let c=0;c<bodies.length;c++){
        notes[c].body = bodies[c];
      }
    }

    //Now that all folders & notes are ready, add them to the database and track the ID changes

    //As the ID will change, we must track their changes
    let folderIDsChanges = new Map();
    let noteIDsChanges = new Map();

    //Add all the folders
    for(let c=0;c<folders.length;c++){
      //Add it into the database
      let response = await db.collection('folders').add({
        parentID:folders[c].parentID,
        name:folders[c].name,
        favorite:false,
        foldersID:folders[c].foldersID,
        notesID:folders[c].notesID,
      });
      let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
      //Add the key to the folder itself
      await db.collection('folders').doc(key).update({id:key});
      //Save the ID change
      folderIDsChanges.set(folders[c].id,key);
    }
    //Add all the notes
    for(let c=0;c<notes.length;c++){
      //Add it into the database
      let response = await db.collection('notes').add({
        folderID:notes[c].folderID,
        title:notes[c].title,
        body:notes[c].body,
        favorite:false,
        creationDate:notes[c].creationDate,
        lastModified:notes[c].lastModified,
      });
      let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
      //Add the key to the note itself
      await db.collection('notes').doc(key).update({id:key});
      //Save the ID change
      noteIDsChanges.set(notes[c].id,key);
    }

    //Now that they are all in the database, process the ID changes
    //For all the folders
    for(let c=0;c<folders.length;c++){
      let folder = await db.collection('folders').doc(folderIDsChanges.get(folders[c].id)).get();
      //Change the parent folder ID
      if(c==0&&isPortion){
        //Do nothing
      } else {
        if(folder.parentID!=null){
          folder.parentID = folderIDsChanges.get(folder.parentID);
        }
      }
      //Change the folder ID list
      if(folder.foldersID.trim()!=""){
        let x = folder.foldersID.split(" ");
        for(let e=0;e<x.length;e++){
          x[e] = folderIDsChanges.get(x[e]);
        }
        folder.foldersID=x.join(" ");
      }
      //Change the notes ID list
      if(folder.notesID.trim()!=""){
        let y = folder.notesID.split(" ");
        for(let r=0;r<y.length;r++){
          y[r] = noteIDsChanges.get(y[r]);
        }
        folder.notesID=y.join(" ");
      }
      //And finally save the changes into the database
      await db.collection('folders').doc(folder.id).update({
        parentID:folder.parentID,
        foldersID:folder.foldersID,
        notesID:folder.notesID,
      });
    }
    //For all the notes
    for(let c=0;c<notes.length;c++){
      //Change the folderID
      if(notes[c].folderID!=null){
        await db.collection('notes').doc(noteIDsChanges.get(notes[c].id)).update({
          folderID:folderIDsChanges.get(notes[c].folderID),
        });
      }
    }

    //Get the folder name
    let now = new Date();
    let folderName = `backup - ${now.getFullYear()}/${now.getMonth()+1}/${now.getDate()} - ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;

    //And finally make the top items in the hierarchy be accessible in the app
    if(isPortion){

      //Just add the first folder into the folder created for the backup in the main folder
      let response = await db.collection('folders').add({
        parentID: null,
        name: folderName,
        foldersID:folderIDsChanges.get(folders[0].id),
        notesID:"",
        favorite:false,
      });
      let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
      //Add the key to the folder itself
      await db.collection('folders').doc(key).update({id:key});
      //Add the folder ID as the parent folder of the top folder
      await db.collection('folders').doc(folderIDsChanges.get(folders[0].id)).update({
        parentID:key,
      });
      //Add that folder into the main folder list of folder IDs
      let mainFolderFolders = localStorage.getItem('mainFolderFolders');
      if(mainFolderFolders&&mainFolderFolders.trim()!=''){
        let x = mainFolderFolders.split(' ');
        x.push(key);
        localStorage.setItem('mainFolderFolders',x.join(' '));
      } else {
        localStorage.setItem('mainFolderFolders',key);
      }

    } else {

      //Get the folders and notes which were in the main folder
      let idOfFoldersThatWereInTheMainFolder = [];
      let idOfNotesThatWereInTheMainFolder = [];
      folders.forEach((folder)=>{
        if(folder.parentID==null){
          idOfFoldersThatWereInTheMainFolder.push(folderIDsChanges.get(folder.id));
        }
      });
      notes.forEach((note)=>{
        if(note.folderID==null){
          idOfNotesThatWereInTheMainFolder.push(noteIDsChanges.get(note.id));
        }
      });
      //Create the folder and add the folder and notes IDs
      let response = await db.collection('folders').add({
        parentID: null,
        name: folderName,
        foldersID:idOfFoldersThatWereInTheMainFolder.join(' '),
        notesID:idOfNotesThatWereInTheMainFolder.join(' '),
        favorite:false,
      });
      let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
      //Add the key to the folder itself
      await db.collection('folders').doc(key).update({id:key});
      //Add that folder into the main folder list of folder IDs
      let mainFolderFolders = localStorage.getItem('mainFolderFolders');
      if(mainFolderFolders&&mainFolderFolders.trim()!=''){
        let x = mainFolderFolders.split(' ');
        x.push(key);
        localStorage.setItem('mainFolderFolders',x.join(' '));
      } else {
        localStorage.setItem('mainFolderFolders',key);
      }
      //And finally set the parent folder ID for the folders and the notes
      for(let c=0;c<idOfFoldersThatWereInTheMainFolder.length;c++){
        await db.collection('folders').doc(idOfFoldersThatWereInTheMainFolder[c]).update({
          parentID:key,
        });
      }
      for(let c=0;c<idOfNotesThatWereInTheMainFolder.length;c++){
        await db.collection('notes').doc(idOfNotesThatWereInTheMainFolder[c]).update({
          folderID:key,
        });
      }

    }

    await fixIndexedIssue();

    //Success message
    alert('Restoring successful 🐒');
    loading.value=false;
  } catch(e) {
    alert('Restoring unsuccessful 🐒');
    console.log(e);
    loading.value=false;
  }
}

export default {
	deleteFolder,
	deleteNote,
}