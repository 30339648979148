<template>
	<div v-if="isVisible" id="overlay">
		<div id="modal" :class="dark?'darkModal':'lightModal'">
			<div v-if="content.folderName!=''" @click="goToParentFolder()" id="parentButton">
				<i class="material-icons" aria-hidden="true">arrow_upward</i>
				<div style="width:10px;"></div>
				<h4 :style="dark?'color:white;':''" class="bodyText">Parent folder</h4>
			</div>
			<h2 style="color:white;text-align:center;">{{content.folderName}}</h2>
			<div v-for="(folder,i) in content.folders" :key="i">
				<div v-if="(foldersToAvoid.indexOf(folder.id)==-1)" @click="goTo(folder.id)" class="folder_card" :style="dark?'background-color:rgb(50,50,50);':''">
					<i class="material-icons" aria-hidden="true">folder</i>
					<div style="width:10px;"></div>
					<h2 :style="dark?'color:white;':''" class="bodyText">{{folder.name}}</h2>
				</div>
			</div>
			<div class="centerItems">
				<h3 @click="selectFolderID(content.folderID)">Select</h3>
				<div style="width:18px;"></div>
				<h3 @click="close()">Cancel</h3>
			</div>
		</div>
	</div>
</template>

<script>
	import {reactive} from 'vue';
	import Localbase from 'localbase';
	export default {
		name: 'SelectFolder',
		props: {
			isVisible: Boolean,
			initialFolderID: String,
			foldersToAvoid: Array,
			selectFolderID: Function,
			close: Function,
			dark: Boolean,
		},
		setup(props,context){

			let db = new Localbase('monkeynote');

			const dark = localStorage.getItem('darkTheme')=="true";

			const content=reactive({
				parentFolderID:null,
				folderID:"",
				folderName:"",
				folders: [],
			});

			const goToMainFolder=async()=>{
				content.folders=[];
				const x = localStorage.getItem("mainFolderFolders");
				let mainFolderFolderIDs;
				if(x&&x.trim()!=''){
					mainFolderFolderIDs = localStorage.getItem("mainFolderFolders").split(' ');
				} else {
					mainFolderFolderIDs=[];
				}
				for (let i = 0; i < mainFolderFolderIDs.length; i++) {
					let y = await db.collection('folders').doc(mainFolderFolderIDs[i]).get();
					content.folders.push({id:y.id,name:y.name});
				}
				content.parentFolderID=null;
				content.folderName="";
				content.folderID=null;
			}
			
			const goTo=async(newFolderID)=>{
				let newFolder = await db.collection('folders').doc(newFolderID).get();
				//Change the folder ID & the parent folder ID
				content.folderID=newFolderID;
				content.parentFolderID=newFolder.parentID;
				//Change the folder name
				content.folderName=newFolder.name;
				//Get the folders
				while(content.folders.length > 0) {
					content.folders.pop();
				}
				let folderIDs;
				if(newFolder.foldersID!=null&&newFolder.foldersID.trim()!=''){
					folderIDs = newFolder.foldersID.split(' ');
				} else {
					folderIDs = [];
				}
				for (let i=0;i<folderIDs.length;i++) {
					let x = await db.collection('folders').doc(folderIDs[i]).get();
					content.folders.push({id:x.id,name:x.name});
				}
			}

			const goToParentFolder=async()=>{
				if(content.parentFolderID&&content.parentFolderID.trim()!=''){
					goTo(content.parentFolderID);
				} else {
					goToMainFolder();
				}
			}

			//Get the initial content
			if(props.initialFolderID){
				goTo(props.initialFolderID);
			} else {
				goToMainFolder();
			}
			
			return {
				content,
				goTo,
				goToParentFolder,
			}
		}
	}
</script>

<style scoped>

	h3 {
		color:white;
		padding:16px;
		margin:0;
		cursor: pointer;
	}

	.folder_card:hover {
    background-color: #E4C872;
  }
  .folder_card {
    background-color: rgb(255,224,130);
    color: black;
    font-size: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: none;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19);
    width: 85%;
    margin: 20px auto 20px auto;
  }
  .folder_card i {
    color: rgb(140,105,0);
    font-size:1.9em;
    display:inline;
    margin:0px;
    padding:0px;
  }
  .folder_card h2 {
		overflow:hidden;
		text-overflow:ellipsis;
    display:inline;
    margin:0px;
    padding:0px;
  }
	#overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0,0,0,0.3);
		z-index: 23;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#modal {
		overflow: scroll;
		width: 66%;
		height: 90%;
		max-width: 350px;
		box-shadow: 1px 2px 4px rgba(153,155,168,0.12);
		border-radius: 4px;
		border-radius: 10px;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		box-shadow: 0px 16px 32px 0px rgba(0,0,0,0.2);
		padding: 15px;
		text-align: center;
		font-weight: bold;
		font-size: 15px;
	}
	#modal p {
		cursor: pointer;
		color: white;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
	}

	.lightModal {
		background-color: rgb(121,85,72);
	}
	.lightModal h3:hover {
		background-color: rgb(88,62,52);
	}
	.darkModal {
		background-color: rgb(72,72,72);
	}
	.darkModal h3:hover {
		background-color: rgb(33,33,33);
	}

	#parentButton {
		color:white;
		cursor: pointer;
		padding: 16px;
	}
	#parentButton:hover {
		background-color: rgba(0,0,0,0.3);
	}
	#parentButton h4, #parentButton div {
		display: inline;
	}
	#parentButton i {
		margin-right: 18px;
	}
</style>