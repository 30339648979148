<template>
	<div :class="dark?'monkeyBar darkCard':'monkeyBar'">
		<i @click="$router.go(-1)" class="material-icons" aria-hidden="true">arrow_back</i>
		<h3 class="titleText unselectable">Tutorial</h3>
	</div>
	<br>
	<div class="contentPadding" :style="dark?'color:white;':'color:black;'">
		<p style="text-align:center;font-size:66px;padding:0;margin:0 0 18px 0;">📝🐒</p>
		<a class="bodyText" :style="dark?'color:white;':''" target="_blank" href="https://youtube.com/playlist?list=PL9odQ4B5jTZTlhwSAIeRKMfTrxAs8l406">
			<p style="text-align: center;">MonkeyNote YouTube tutorial</p>
		</a>
		<p class="bodyText">MonkeyNote consists of a hierarchy of folders which can store notes <span>📝📂</span> One folder can contain notes or another folders.</p>
		<p class="bodyText">This hierarchy begins in the main folder <span>📂</span> where all the other folders are contained.</p>
		<br>
		<h2 class="titleText" style="text-align:center;">Shortcuts</h2>
		<div :class="dark?'separator background-white':'separator'"></div>
		<p class="bodyText">Navigate between folders, notes and screens at the speed of thunder <span>⚡️</span> with the shortcuts. You will move so fast that your friends will see only random flashes on your screen.</p>
		<p class="bodyText">New shortcut: 0 to go one folder back</p>
		<a class="bodyText" :style="dark?'color:white;':''" target="_blank" href="https://youtu.be/N_SRK2mXgn4">
			<p style="text-align: center;">Shortcuts tutorial</p>
		</a>
		<p class="bodyText" style="text-align:center;"><span>💻🐒    🙉🙉🙉</span></p>
		<br>
		<h2 class="titleText" style="text-align:center;">Favorites</h2>
		<div :class="dark?'separator background-white':'separator'"></div>
		<p class="bodyText">To quickly access your favorite notes/folders you may set them as favorites <span></span> so that they appear in the home page when the app starts.</p>
		<div id="favorite_banner">
      <div>
        <i class="material-icons" aria-hidden="true">star</i>
        <h2 style="margin-left:10px;" class="titleText noMargin">Favorites</h2>
      </div>
      <i id="searchInFavoritesButton" class="material-icons" aria-hidden="true">search</i>
    </div>
		<br>
		<h2 class="titleText" style="text-align:center;">Theme</h2>
		<div :class="dark?'separator background-white':'separator'"></div>
		<p class="bodyText">Change the app theme <span>☀️</span> to the one that makes you feel more comfortable <span>🌛</span></p>
		<div id="themeIcons">
      <div>
				<i class="material-icons" aria-hidden="true">brightness_7</i>
				<p class="bodyText">Day</p>
      </div>
      <div>
				<i class="material-icons" aria-hidden="true">brightness_3</i>
				<p class="bodyText">night</p>
      </div>
		</div>
		<br>
		<h2 class="titleText" style="text-align:center;">Recycle bin</h2>
		<div :class="dark?'separator background-white':'separator'"></div>
		<p class="bodyText">A deleted note goes to the recycle bin. If you delete it twice, it will be unrecoverable <span>🗑️</span> If a note is restored, it will be placed in the main folder. A deleted note cannot be set as a favorite note.</p>
		<button id="recycleBinButton">
			<div :style="dark?'color:white;':''" class="centerItems">
				<i class="material-icons" aria-hidden="true">delete</i>
				<h2 style="margin-left:10px;font-size:22px;" class="titleText">Recycle bin</h2>
			</div>
		</button>
		<br>
		<h2 class="titleText" style="text-align:center;">Copy or move a folder or note</h2>
		<div :class="dark?'separator background-white':'separator'"></div>
		<p class="bodyText">Copying and cutting folders/notes is available, just hold down any item inside a folder.</p>
		<div id="themeIcons">
      <div>
				<i class="material-icons" aria-hidden="true">content_copy</i>
				<p class="bodyText">Copy</p>
      </div>
      <div>
				<i class="material-icons" aria-hidden="true">content_cut</i>
				<p class="bodyText">Move</p>
      </div>
		</div>
		<br>
		<h2 class="titleText" style="text-align:center;">Read aloud a note</h2>
		<div :class="dark?'separator background-white':'separator'"></div>
		<p class="bodyText">The app can read aloud a note content <span>🐵💬</span> you can set the rate, pitch and the language. Double tap a paragraph to read it.</p>
		<div id="readAloudButtons">
			<i class="material-icons" aria-hidden="true">arrow_back_ios</i>
			<i class="material-icons" aria-hidden="true">play_circle_filled</i>
			<i class="material-icons" aria-hidden="true">arrow_forward_ios</i>
			<i class="material-icons" aria-hidden="true">stop</i>
		</div>
		<br>
		<h2 class="titleText" style="text-align:center;">Backup your folders and notes</h2>
		<div :class="dark?'separator background-white':'separator'"></div>
		<p class="bodyText">You can create a backup of the main folder or any folder to save it as a file with .monkeynote extension <span>☁️🗂️☁️</span> This file is downloaded in the default Downloads directory of your phone. Sharing directly the backup is also available.</p>
		<div id="backup">
			<i class="material-icons" aria-hidden="true">backup</i>
			<p class="titleText">my_data.monkeynote</p>
		</div>
		<br>
		<h2 class="titleText" style="text-align:center;">Create an account</h2>
		<div :class="dark?'separator background-white':'separator'"></div>
		<p class="bodyText">You can create an account to access 3 services:</p>
		<p class="bodyText">1. <span>🌐 📂</span> WebFolder: A folder which is stored in your account just like a normal folder.</p>
		<p class="bodyText">2. <span>🌐 📝</span> Fast note: Allows you to share information between your logged-in devices.</p>
		<p class="bodyText">3. You will have a random monkey avatar <span>🍌🐵👌🏻</span></p>
		<div id="account">
			<div :style="dark?'background-color:rgb(72,72,72);':'background-color:rgb(121,85,72);'">
				<p>🐵</p>
			</div>
			<p class="titleText">MonkeyLautee</p>
		</div>
		<br>
		<br>
		<a class="bodyText" :style="dark?'color:white;':''" target="_blank" @click="$router.push('/privacy')">
			<p style="text-align: center;text-decoration: underline;">MonkeyNote privacy policy</p>
		</a>
	</div>
</template>

<script>
	export default {
		name: 'Tutorial',
		setup(props,context) {
			const dark = localStorage.getItem('darkTheme')=="true";
			return {dark}
		}
	}
</script>

<style scoped>
	span {
		font-size:1.5em;
	}
	/*Favorite section*/
	#favorite_banner {
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin: 10px;
	}
	#favorite_banner i {
		cursor: pointer;
	}
	#favorite_banner div {
		display: inline;
		align-items: center;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	#searchInFavoritesButton {
		padding-left:15px;
		padding-right:15px;
	}
	/*Theme section*/
	#themeIcons {
		display: flex;
		align-items: center;
		justify-content: space-around;
		text-align: center;
	}
	#themeIcons i {
		font-size: 33px;
	}
	#themeIcons p {
		font-weight: bold;
	}
	/*Recycle bin section*/
	#recycleBinButton {
		background-color: rgba(0,0,0,0.0);/*transparent*/
		border: none;
		padding-left: 13px;
		padding-right: 13px;
		text-align: center;
		text-decoration: none;
		display: block;
		font-size: 16px;
		margin: auto;
		cursor: pointer;
		box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
	}
	/*Read Aloud section*/
	#readAloudButtons {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
	#readAloudButtons i {
		font-size: 60px;
		margin: 0;
		padding: 13px;
	}
	#readAloudButtons i:hover {
		background-color: grey;
	}
	/*BAckup section*/
	#backup {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#backup i {
		display: inline;
		font-size: 50px;
	}
	#backup p {
		margin-left: 18px;
		display: inline;
		font-weight: bold;
		font-size: 20px;
	}
	/*Create account section*/
	#account {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 10px;
	}
	#account div {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		align-items: center;
		align-content: center;
		border-radius: 50%;
		height: 80px;
		width: 80px;
	}
	#account div p {
		font-size:40px;
		margin: 0;
		padding: 0;
	}
	#account p {
		margin-left: 18px;
		display: inline;
		font-size: 23px;
	}
</style>