<template>
	<Modal :isLoading="true" :isVisible="!noteReady" :dark="dark"/>
	<nav id="theNav" :class="dark?'darkCard':''">
		<div>
			<i @click="unsubscribe();$router.go(-1)" class="material-icons" aria-hidden="true">arrow_back</i>
			<h2 style="cursor:pointer;" @click="changeTitle()" id="noteTitle"></h2>
		</div>
		<div>
			<i v-if="microVisible" @click="changeLang()" class="material-icons" aria-hidden="true">translate</i>
			<i @click="showDropdownNote()" id="dropdown_icon" class="material-icons" aria-hidden="true">arrow_drop_down</i>
		</div>
	</nav>
	<div id="dropdown-content-note" :class="dark?'dropdown-content-note darkCard':'dropdown-content-note'">
		<a @click="closeWithoutSaving()" class="titleText">Close without saving</a>
		<a @click="readAloud();$router.push('/readNote');" class="titleText">Read aloud</a>
		<a @click="exportAsTxt()" class="titleText">Export as txt</a>
		<a @click="displayMicro()" class="titleText">Voice typing</a>
		<a @click="replaceWord()" class="titleText">Replace word</a>
		<a @click="displayNoteInformation()" class="titleText">Information</a>
	</div>
	<div v-if="microVisible" id="speechToText">
		<div @click="speechToText()" class="circularButton" :style="dark?'background-color:rgb(72,72,72);':'background-color:rgb(255,194,13);'">
			<i id="microIcon" :style="dark?'color:white;':'color:black;'" class="material-icons" aria-hidden="true">keyboard_voice</i>
		</div>
	</div>
	<div v-if="selectLang" id="languagesPanel">
		<label for="language">Language:</label>
		<select name="language" id="language" :onchange="setLanguage">
			<option v-for="(lang,index) in monkey.langs" :key="index" :value="monkey.langCodes[index]">{{lang}}</option>
		</select>
	</div>
	<div style="padding:10px;">
		<textarea :style="dark?'color:white;':'color:black;'" :disabled="!noteReady" id="noteBody" class="bodyText" spellcheck="false"></textarea>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import { ref } from "vue";
import { useRoute } from 'vue-router'
import { onMounted } from "vue";
import { onUnmounted } from "vue";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Modal from '../components/Modal.vue';
import monkey from '../modules/monkey';

export default {
  name: 'WebNote',
  components: {Modal},
	setup(props,context){

		const dark = localStorage.getItem('darkTheme')=="true";

		let alwaysTrue = true;
		const noteReady = ref(false);

		//Save the note when the tab is closed 
		window.addEventListener("beforeunload", function (e) {
			firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).collection('WebNotes').doc(localStorage.getItem("currentNote")).update({
				body: document.getElementById("noteBody").value.trim(),
			}).then(response => {
				// Do nothing
			}).catch(error => {
				alert('There was an error');
			});
			localStorage.setItem("currentNote","");
		});

		const db = firebase.firestore();
		const route = useRoute();

		localStorage.setItem("currentNote",route.params.noteID);

		const unsubscribe = firebase.auth().onAuthStateChanged(async(user)=>{
			if(user){
				//Get the note
				let note = await db.collection('users').doc(user.uid).collection('WebNotes').doc(route.params.noteID).get();
				//Set the title
				document.getElementById("noteTitle").innerHTML=sessionStorage.getItem('webNoteTitle');
				//Set the body
				let noteBodyTag = document.getElementById("noteBody");
				noteBodyTag.value = note.data()['text'];
				noteBodyTag.style.height = "";
				noteBodyTag.style.height = noteBodyTag.scrollHeight + "px";
				setCorrectHeight();//Get the height
				noteReady.value=true;
			}
		});

		const closeWithoutSaving=()=>{
			if(confirm("Are you sure you want to close without saving the note?")){
				localStorage.setItem("closeWithoutSaving","true");
				history.back();
			}
		}

		const readAloud=()=>{
			sessionStorage.setItem("textToRead",document.getElementById("noteBody").value.trim());
			sessionStorage.setItem("textToReadTitle",sessionStorage.getItem('webNoteTitle'));
		}

		const exportAsTxt=()=>{
			if(confirm("Export as txt?")){
				let txtBody = document.getElementById("noteBody").value.trim();
				var element = document.createElement('a');
				element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(txtBody));
				element.setAttribute('download',sessionStorage.getItem('webNoteTitle')+'.txt');
				element.style.display = 'none';
				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
			}
		}

		const displayNoteInformation=()=>{
			alert("- Characters: "+document.getElementById("noteBody").value.trim().length);
		}

		const changeTitle=()=>{
			let newTitle = prompt("New title:",sessionStorage.getItem('webNoteTitle'));
			if(newTitle!=null&&newTitle.trim()!=""){
				if(newTitle.length>150){
					alert("Less than 150 characters.");
					return;
				}
				newTitle=newTitle.trim();
				let thePath = sessionStorage.getItem('webFolderPath');
				if(thePath.substring(thePath.indexOf('WebFolder'),thePath.length)!='WebFolder'){
					thePath=thePath+'/content';
				}
				db.collection(thePath).doc('MonkeyNotes').update({
					[route.params.noteID]: newTitle,
				}).then(response => {
					document.getElementById("noteTitle").innerHTML=newTitle;
					sessionStorage.setItem('webNoteTitle',newTitle);
					sessionStorage.setItem('changesMadeInTitle',route.params.noteID+':'+newTitle);
				}).catch(error => {
					alert('There was an error');
				});
			}
		}

		const showDropdownNote=()=>{
			let dropdownIcon = document.getElementById("dropdown_icon");
			if(document.getElementById("dropdown-content-note").style.display=="block"){
				dropdownIcon.innerHTML="arrow_drop_down";
				document.getElementById("dropdown-content-note").style.display="none";
			} else {
				dropdownIcon.innerHTML="arrow_drop_up";
				document.getElementById("dropdown-content-note").style.display="block";
			}
		}

		const replaceWord=()=>{
			showDropdownNote();
			const replacedWord=prompt("Word to replace:");
			if(replacedWord==null)return;
			const word=prompt("Word:");
			if(word==null)return;
			const body=document.getElementById("noteBody").value.trim();
			const newBody=body.replaceAll(replacedWord,word);
			document.getElementById("noteBody").value=newBody;
		}

		//Set the correct height for the textarea
		const setCorrectHeight=async()=>{
			setTimeout(()=>{
				//Get the nav height
				let navHeight = document.getElementById("theNav").offsetHeight;
				//Set the correct height for the body (height - nav height)
				document.getElementById("noteBody").style.height = (window.innerHeight-navHeight-25).toString() + "px";
			},250);
		}

		onBeforeUnmount(()=>{
			unsubscribe();
			if(localStorage.getItem("closeWithoutSaving")=="true"){
				localStorage.setItem("closeWithoutSaving","false");
			} else {
				//Save the note body
				firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).collection('WebNotes').doc(localStorage.getItem("currentNote")).update({
					text: document.getElementById("noteBody").value.trim(),
				}).then(response => {
					// Do nothing
				}).catch(error => {
					alert('There was an error');
				});
			}
			localStorage.setItem("currentNote","");
		});

		// Speech to text
		const microVisible=ref(localStorage.getItem("microVisible")=="true");
		const displayMicro=()=>monkey.displayMicro(selectLang,microVisible);
		let microPressed = false;
		const setMicroPressed=(boolValue)=>microPressed=boolValue;
		const speechToText=()=>monkey.speechToText(dark,microPressed,setMicroPressed);
		const selectLang=ref(false);
		const setLanguage=()=>monkey.setLanguage(document.getElementById('language'));
		const changeLang=()=>monkey.changeLang(selectLang);

		// Shortcuts
		const isKeyPressed = {
			'Alt': false,
			'v': false,
		}
		onMounted(()=>{
			document.onkeydown=keyDownEvent=>{
				isKeyPressed[keyDownEvent.key] = true;
				if(isKeyPressed["Control"])isKeyPressed["Alt"]=false;
				if(isKeyPressed["Alt"]&&isKeyPressed["v"]){
					if(microVisible.value){
						speechToText();
					} else {
						displayMicro();
					}
				}
				if(isKeyPressed["Alt"]&&isKeyPressed["l"]&&microVisible.value)changeLang();
				if(isKeyPressed["Alt"]&&isKeyPressed["x"]&&microVisible.value)displayMicro();
				if(keyDownEvent.key!="Alt")isKeyPressed["Alt"]=false;
				if(isKeyPressed["Escape"]&&document.getElementById("dropdown-content-note").style.display=="block")showDropdownNote();
			}
			document.onkeyup = (keyUpEvent) => {
				isKeyPressed[keyUpEvent.key] = false;
			};
		});
		onUnmounted(()=>{
			document.onkeydown = null;
			document.onkeyup = null;
		});
		
		return {
			monkey,
			speechToText,
			displayMicro,
			microVisible,
			changeLang,
			selectLang,
			setLanguage,
			unsubscribe,
			showDropdownNote,
			closeWithoutSaving,
			displayNoteInformation,
			changeTitle,
			noteReady,
			readAloud,
			exportAsTxt,
			replaceWord,
			dark,
		}
	}
}

</script>

<style scoped>
	@import '../styles/style_for_notes.css';
	@import '../styles/nav.css';
</style>