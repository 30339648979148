<template>

	<!-- Nav Bar -->
	<nav :class="dark?'darkCard':''">
		<div>
			<i @click="displayDrawerMenu()" id="menu_icon" class="material-icons" aria-hidden="true">menu</i>
			<h1 class="unselectable">MonkeyNote</h1>
		</div>
		<div>
			<div id="hidden_icons_for_mobile">
				<i v-if="!userLoggedIn" @click="$router.push('/signin');" class="material-icons" aria-hidden="true">person</i>
				<i @click="$router.push('/backup');" class="material-icons" aria-hidden="true">cloud_upload</i>
			</div>
			<i @click="searchAll();$router.push('/search');" class="material-icons" aria-hidden="true">search</i>
			<div @click="changeTheme()">
				<i class="material-icons" aria-hidden="true">{{dark?"brightness_3":"brightness_7"}}</i>
			</div>
			<i id="arrow_drop_down" @click="showDropdown" class="material-icons" aria-hidden="true">arrow_drop_down</i>
			<div id="dropdown-content-nav" :class="dark?'dropdown-content darkCard':'dropdown-content'">
				<router-link to="/tutorial"><a class="titleText">Tutorial</a></router-link>
				<div id="mobile_items">
					<router-link v-if="!userLoggedIn" to="/signin"><a class="titleText">Sign in</a></router-link>
					<router-link to="/backup"><a class="titleText">Backup</a></router-link>
				</div>
				<router-link to="/help"><a class="titleText">Help us</a></router-link>
				<router-link to="/templates"><a class="titleText">Templates</a></router-link>
				<a class="titleText" target="_blank" style="padding:20px 35px;" href="https://play.google.com/store/apps/details?id=com.lautee.monkeynote">
					<div>
						<i style="margin:0;padding:0;margin-right:9px;" class="material-icons" aria-hidden="true">android</i>
						App
					</div>
				</a>
			</div>
		</div>
	</nav>

  <div id="page_container">
    
    <!-- Main Folder -->
    <div id="mainFolder" :class="dark?'darkCard':''" style="height:100%">
			<h2 class="unselectable">Main Folder</h2>
			<div :class="dark?'separator background-white':'separator'"></div>
			<div id="mainFolderContent">
				<div v-if="loading.value">
				</div>
				<h3 v-else-if="mainFolderFolders.length==0&&mainFolderNotes.length==0" class="titleText noContent unselectable">No content</h3>
				<template v-else>
					<FolderCard v-for="(folder,index) in mainFolderFolders" @contextmenu.prevent="folderMenu(folder.id)" :key="index" :id="folder.id" :isInMainFolder=true :dark=dark>{{folder.name}}</FolderCard>
					<NoteCard v-for="(note,index) in mainFolderNotes" @contextmenu.prevent="noteMenu(note.id)" :key="index" :id="note.id" :isInMainFolder=true :dark=dark>{{note.title}}</NoteCard>
				</template>
			</div>
			<div :class="dark?'separator background-white':'separator'"></div>
			<div id="mainFolderButtons">
				<div :class="dark?'darkCardHover':''" @click="addNewFolderInMainFolder()">
					<i class="material-icons" aria-hidden="true">create_new_folder</i>
					<h3 class="titleText unselectable">New folder</h3>
				</div>
				<div :class="dark?'darkCardHover':''" @click="addNewNoteInMainFolder()">
					<i class="material-icons" aria-hidden="true">note_add</i>
					<h3 class="titleText unselectable">New note</h3>
				</div>
			</div>
		</div>

		<!-- HomePage content -->
    <div id="homepage" :class="dark?'whiteText':'blackText'">
      <UserPanel v-if="userLoggedIn" :email="email" :close="close" :dark="dark"/>
      <Modal :isLoading="isLoading" :isVisible="modalVisible" :closeModal="toggleModal" :options="options" :functions="functions" :dark="dark"/>
      <div id="favorite_banner">
        <div>
          <i class="material-icons" aria-hidden="true">star</i>
          <h2 style="margin-left:10px;" class="noMargin unselectable">Favorites</h2>
        </div>
        <i @click="searchFavorites();$router.push('/search');" id="searchInFavoritesButton" class="material-icons" aria-hidden="true">search</i>
      </div>
      <div :class="dark?'separator background-white':'separator'"></div>
      <div v-if="loading.value"></div>
			<h3 v-else-if="favoriteFolders.length==0&&favoriteNotes.length==0" class="titleText noContent unselectable">No content</h3>
			<template v-else>
				<FolderCard v-for="(folder,index) in favoriteFolders" @contextmenu.prevent="favoriteFolderMenu(folder.id)" :key="index" :id="folder.id" :dark=dark>{{folder.name}}</FolderCard>
				<NoteCard v-for="(note,index) in favoriteNotes" @contextmenu.prevent="favoriteNoteMenu(note.id)" :key="index" :id="note.id" :dark=dark>{{note.title}}</NoteCard>
			</template>
      <br>
      <br>
			<button id="recycleBinButton">
				<router-link id="recycleBinButtonContent" :class="dark?'whiteText':'blackText'" to="/recyclebin">
					<div class="centerItems">
						<i class="material-icons" aria-hidden="true">delete</i>
						<h2 style="margin-left:10px;font-size:22px;" class="titleText">Recycle bin</h2>
					</div>
				</router-link>
			</button>
    </div>

  </div>

</template>

<script>
import {reactive} from 'vue';
import { ref } from "vue";
import router from '../router/router.js';
import Localbase from 'localbase';
import firebase from 'firebase/app';
import 'firebase/auth';
import { onMounted } from "vue";
import { onUnmounted } from "vue";
import Modal from '../components/Modal.vue';
import FolderCard from '../components/FolderCard.vue';
import NoteCard from '../components/NoteCard.vue';
import UserPanel from '../components/UserPanel.vue';
import HelpUs from '../screens/HelpUs.vue';
import mf from '../modules/mf';

export default {
  name: 'HomePage',
  components: {
		UserPanel,
		FolderCard,
		NoteCard,
		Modal,
  },
  setup(props,context){

		//Get the theme icon
		const dark = ref(false);
		if(localStorage.getItem('darkTheme')=="true"){
			dark.value=true;
			document.querySelector('meta[name="theme-color"]').setAttribute("content", "rgb(72,72,72)");
			document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", "rgb(72,72,72)");
			document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]').setAttribute("content", "rgb(72,72,72)");
		}

		const alwaysTrue = true;

		const db = new Localbase('monkeynote');

		let drawerMenuDisplayed = false;
		
		const userLoggedIn = ref(false);
		const email = ref('');
		const close=()=>userLoggedIn.value=!userLoggedIn.value;

		const loading = ref(true);

		const modalVisible = ref(false);
		const isLoading = ref(false);
		const options = reactive([]);
		const functions = reactive([]);

		const mainFolderFolders=reactive([]);
		const mainFolderNotes=reactive([]);
		const favoriteFolders=reactive([]);
		const favoriteNotes=reactive([]);

		firebase.auth().onAuthStateChanged((user)=>{
			if(user){
				email.value = user.email;
				userLoggedIn.value=true;
			}
		});

		// Fetch the homepage data
		const fetchHomePageData = async function(){

			let mainFolderFolderIDs;
			let mainFolderNoteIDs;
			let favoriteFolderIDs;
			let favoriteNoteIDs;
			//Get the list of main folder folder IDs
			const mainFolderFolderIDsAsString = localStorage.getItem("mainFolderFolders");
			if(mainFolderFolderIDsAsString!=null&&mainFolderFolderIDsAsString.trim()!=""){
				mainFolderFolderIDs=mainFolderFolderIDsAsString.split(" ");
			} else {
				mainFolderFolderIDs = [];
			}
			//Get the list of main folder note IDs
			const mainFolderNoteIDsAsString = localStorage.getItem("mainFolderNotes");
			if(mainFolderNoteIDsAsString!=null&&mainFolderNoteIDsAsString.trim()!=""){
				mainFolderNoteIDs=mainFolderNoteIDsAsString.split(" ");
			} else {
				mainFolderNoteIDs = [];
			}
			//Get the list of favorite folder IDs
			let favoriteFolderIDsAsString = localStorage.getItem("favoriteFolders");
			if(favoriteFolderIDsAsString!=null&&favoriteFolderIDsAsString.trim()!=""){
				favoriteFolderIDs=favoriteFolderIDsAsString.split(" ");
			} else {
				favoriteFolderIDs = [];
			}
			//Get the list of favorite note IDs
			let favoriteNoteIDsAsString = localStorage.getItem("favoriteNotes");
			if(favoriteNoteIDsAsString!=null&&favoriteNoteIDsAsString.trim()!=""){
				favoriteNoteIDs=favoriteNoteIDsAsString.split(" ");
			} else {
				favoriteNoteIDs = [];
			}

			let mainFolderFolderRaw = [];
			let mainFolderNoteRaw = [];
			let favoriteFolderRaw = [];
			let favoriteNoteRaw = [];
			//Get the list of main folder folders
			for (let i = 0; i < mainFolderFolderIDs.length; i++) {
				try {
					let x = await db.collection("folders").doc(mainFolderFolderIDs[i]).get();
					mainFolderFolderRaw.push({id:mainFolderFolderIDs[i],name:x.name});
				} catch(e) {
					//Delete the ID
				}
			}
			//Get the list of main folder notes
			for (let i = 0; i < mainFolderNoteIDs.length; i++) {
				try {
					let x = await db.collection("notes").doc(mainFolderNoteIDs[i]).get();
					mainFolderNoteRaw.push({id:mainFolderNoteIDs[i],title:x.title});
				} catch(e) {
					//Delete the ID
				}
			}
			//Get the list of favorite folders
			for (let i = 0; i < favoriteFolderIDs.length; i++) {
				try {
					let x = await db.collection("folders").doc(favoriteFolderIDs[i]).get();
					favoriteFolderRaw.push({id:favoriteFolderIDs[i],name:x.name});
				} catch(e) {
					//Delete the ID
				}
			}
			//Get the list of favorite notes
			for (let i = 0; i < favoriteNoteIDs.length; i++) {
				try {
					let x = await db.collection("notes").doc(favoriteNoteIDs[i]).get();
					favoriteNoteRaw.push({id:favoriteNoteIDs[i],title:x.title});
				} catch(e) {
					//Delete the ID
				}
			}

			//Clear the previous content
			while (mainFolderFolders.length) {
				mainFolderFolders.pop();
			}
			//Add the loaded content
			mainFolderFolderRaw.forEach(function(element,index){
				mainFolderFolders.push(element);
			});
			//Clear the previous content
			while (mainFolderNotes.length) {
				mainFolderNotes.pop();
			}
			//Add the loaded content
			mainFolderNoteRaw.forEach(function(element,index){
				mainFolderNotes.push(element);
			});
			//Clear the previous content
			while (favoriteFolders.length) {
				favoriteFolders.pop();
			}
			//Add the loaded content
			favoriteFolderRaw.forEach(function(element,index){
				favoriteFolders.push(element);
			});
			//Clear the previous content
			while (favoriteNotes.length) {
				favoriteNotes.pop();
			}
			//Add the loaded content
			favoriteNoteRaw.forEach(function(element,index){
				favoriteNotes.push(element);
			});
			
			//Update the HomePage
			loading.value=false;
		}
		fetchHomePageData();

		/*Nav bar*/
		//For the dropdown menu
		const showDropdown=()=>{
			let dropdownIcon = document.getElementById("arrow_drop_down");
			if(document.getElementById("dropdown-content-nav").style.display=="block"){
				dropdownIcon.innerHTML="arrow_drop_down";
				document.getElementById("dropdown-content-nav").style.display="none";
			} else {
				dropdownIcon.innerHTML="arrow_drop_up";
				document.getElementById("dropdown-content-nav").style.display="block";
			}
		}

		//For the dropdown menu of a folder
		const showDropdownFolder=(dropdownIcon)=>{
			if(document.getElementById("dropdown-content-folder").style.display=="block"){
				dropdownIcon.innerHTML="arrow_drop_down";
				document.getElementById("dropdown-content-folder").style.display="none";
			} else {
				dropdownIcon.innerHTML="arrow_drop_up";
				document.getElementById("dropdown-content-folder").style.display="block";
			}
		}

		//For the dropdown menu of a note
		const showDropdownNote=(dropdownIcon)=>{
			if(document.getElementById("dropdown-content-note").style.display=="block"){
				dropdownIcon.innerHTML="arrow_drop_down";
				document.getElementById("dropdown-content-note").style.display="none";
			} else {
				dropdownIcon.innerHTML="arrow_drop_up";
				document.getElementById("dropdown-content-note").style.display="block";
			}
		}

		//Change the theme when clicking the icon
		const changeTheme=()=>{
			if(localStorage.getItem("darkTheme")=="true"){
				dark.value=false;
				document.getElementsByTagName("body")[0].setAttribute("style","background-color: rgb(255,224,130);");
				localStorage.setItem("darkTheme","false");
				document.querySelector('meta[name="theme-color"]').setAttribute("content", "rgb(121,85,72)");
				document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", "rgb(121,85,72)");
				document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]').setAttribute("content", "rgb(121,85,72)");
			} else {
				document.getElementsByTagName("body")[0].setAttribute("style","background-color: rgb(0,0,0);");
				dark.value=true;
				localStorage.setItem("darkTheme","true");
				document.querySelector('meta[name="theme-color"]').setAttribute("content", "rgb(72,72,72)");
				document.querySelector('meta[name="msapplication-navbutton-color"]').setAttribute("content", "rgb(72,72,72)");
				document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]').setAttribute("content", "rgb(72,72,72)");
			}
		}

		//For the drawer menu
		const displayDrawerMenu=()=>{
			let mainFolder = document.getElementById("mainFolder");
			let drawerIcon = document.getElementById("menu_icon");
			if(drawerMenuDisplayed){
				drawerIcon.innerHTML="menu";
				mainFolder.style.position="static";
				mainFolder.style.display = "none";
			} else {
				drawerIcon.innerHTML="close";
				mainFolder.style.position="fixed";
				mainFolder.style.left="0px";
				mainFolder.style.top="50px";
				mainFolder.style.width="250px";
				mainFolder.style.height=(window.innerWidth*0.8).toString()+"px";
				mainFolder.style.minHeight = "390px";
				mainFolder.style.display = "block";
			}
			drawerMenuDisplayed = !drawerMenuDisplayed;
		}

		/*Main Folder*/

		//Function to hide the main folder menu on screen resizing
		window.addEventListener("resize",()=>{
			let mainFolder = document.getElementById("mainFolder");
			if(mainFolder==null||mainFolder==undefined)return;
			//If it's desktop view
			if(window.innerWidth>770){
				//And the main folder is displayed as a floating menu or isn't displayed
				if(mainFolder.style.display=="none" || mainFolder.style.position=="fixed"){
					//Change the menu icon as if it were close in mobile
					document.getElementById("menu_icon").innerHTML="menu";
					//turn it into a common div
					mainFolder.style.position="static";
					//Change the width
					mainFolder.style.width="250px";
					//Change the height
					mainFolder.style.height="auto";
					//display it
					mainFolder.style.display="block";
				}
			} else {//If it's mobile view
				//And the main folder panel is still shown as block panel
				if(mainFolder.style.position=="static" && mainFolder.style.display=="block"){
					mainFolder.style.display="none";
				}
			}
		});

		const addNewFolderInMainFolder=()=>{
			let name = prompt("New folder name:");
			if(name!=null&&name.trim()!=""){
				db.collection('folders').add({
					parentID: null,
					name: name.trim(),
					notesID:"",
					foldersID:"",
					favorite:false,
				}).then(response => {
					//Add the key into the main folder folders ID list
					let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
					let mainFolderFoldersAsString = localStorage.getItem("mainFolderFolders");
					if(mainFolderFoldersAsString==null){
						localStorage.setItem("mainFolderFolders",key);
					} else {
						let y = mainFolderFoldersAsString.split(" ");
						y.push(key);
						while(alwaysTrue){//Delete the empty items
							if(y.indexOf("")!=-1){
								y.splice(y.indexOf(""),1);
							} else {
								break;
							}
						}
						localStorage.setItem("mainFolderFolders",y.join(" "));
					}
					//Add the key into the folder itself
					db.collection('folders').doc(key).update({id:key}).then(response=>{
						//Update the changes
						mainFolderFolders.push({id:key,name:name});
					});
				}).catch(error => {
					console.log('There was an error, do something else.');
					console.log(error);
				});
			}
		}

		const addNewNoteInMainFolder=()=>{
			let title = prompt("New note tile:");
			if(title!=null&&title.trim()!=""){
				let milliseconds = (new Date()).getTime();//milliseconds since epoch
				db.collection('notes').add({
					folderID: null,
					title: title.trim(),
					body: "",
					favorite:false,
					creationDate: milliseconds,
					lastModified: milliseconds,
				}).then(response => {
					//Add the key into the main folder notes ID list
					let key = response.substring(response.indexOf('"key":"')+7,response.indexOf('","data"'));
					let mainFolderNotesAsString = localStorage.getItem("mainFolderNotes");
					if(mainFolderNotesAsString==null){
						localStorage.setItem("mainFolderNotes",key);
					} else {
						let y = mainFolderNotesAsString.split(" ");
						y.push(key);
						while(alwaysTrue){//Delete the empty items
							if(y.indexOf("")!=-1){
								y.splice(y.indexOf(""),1);
							} else {
								break;
							}
						}
						localStorage.setItem("mainFolderNotes",y.join(" "));
					}
					//Add the key into the note itself
					db.collection('notes').doc(key).update({id:key}).then(response=>{
						//Update the changes
						mainFolderNotes.push({id:key,title:title});
					});
				}).catch(error => {
					console.log('There was an error.');
				});
			}
		}

		const folderMenu=id=>{
			//Clear the previous content
			while (options.length) {
				options.pop();
			}
			//Add the options
			options.push("Delete folder");
			options.push("Copy folder");
			options.push("Move folder");
			//Clear the previous content
			while (functions.length) {
				functions.pop();
			}
			//Add the functions
			functions.push(async function(){
				isLoading.value=true;
				if(confirm("Delete the folder?")){
					await mf.deleteFolder(id);
				}
				await fetchHomePageData();
				isLoading.value=false;
				modalVisible.value=false;
			});
			functions.push(()=>{
				sessionStorage.setItem('selectedItem','folder:'+id);
				router.push('/select/copy/mainfolder');
			});
			functions.push(()=>{
				sessionStorage.setItem('selectedItem','folder:'+id);
				router.push('/select/move/mainfolder');
			});
			//Show the modal
			toggleModal();
		}

		const noteMenu=id=>{
			//Clear the previous content
			while (options.length) {
				options.pop();
			}
			//Add the options
			options.push("Delete note");
			options.push("Copy note");
			options.push("Move note");
			//Clear the previous content
			while (functions.length) {
				functions.pop();
			}
			//Add the options
			functions.push(async function(){
				isLoading.value=true;
				if(confirm("Delete the note?")){
					await mf.deleteNote(id);
				}
				await fetchHomePageData();
				isLoading.value=false;
				modalVisible.value=false;
			});
			functions.push(()=>{
				sessionStorage.setItem('selectedItem','note:'+id);
				router.push('/select/copy/mainfolder');
			});
			functions.push(()=>{
				sessionStorage.setItem('selectedItem','note:'+id);
				router.push('/select/move/mainfolder');
			});
			//Show the modal
			toggleModal();
		}

		const favoriteFolderMenu=id=>{
			//Clear the previous content
			while (options.length) {
				options.pop();
			}
			//Add the options
			options.push("Delete folder");
			//Clear the previous content
			while (functions.length) {
				functions.pop();
			}
			//Add the options
			functions.push(async function(){
				isLoading.value=true;
				if(confirm("Delete the folder?")){
					await mf.deleteFolder(id);
				}
				await fetchHomePageData();
				isLoading.value=false;
				modalVisible.value=false;
			});
			//Show the modal
			toggleModal();
		}

		const favoriteNoteMenu=id=>{
			//Clear the previous content
			while (options.length) {
				options.pop();
			}
			//Add the options
			options.push("Delete note");
			//Clear the previous content
			while (functions.length) {
				functions.pop();
			}
			//Add the options
			functions.push(async function(){
				isLoading.value=true;
				if(confirm("Delete the note?")){
					await mf.deleteNote(id);
				}
				await fetchHomePageData();
				isLoading.value=false;
				modalVisible.value=false;
			});
			//Show the modal
			toggleModal();
		}

		const toggleModal=()=>modalVisible.value=!modalVisible.value;

		const searchAll=()=>{
			sessionStorage.setItem('searchBoundaries','All');
		}
		const searchFavorites=()=>{
			sessionStorage.setItem('searchBoundaries','Favorites');
		}

		// Shortcuts
		const isKeyPressed = { 
			'm': false,//main folder
			'f': false,//folder
			'n': false,//Note
			't': false,//Theme
			'b': false,//Backup
			'r': false,//Recycle bin
			's': false,//Search word screen
			'c': false,//Create
			//Indexes
			'1': false,
			'2': false,
			'3': false,
			'4': false,
			'5': false,
			'6': false,
			'7': false,
			'8': false,
			'9': false,
		}
		onMounted(()=>{
			document.onkeydown = (keyDownEvent) => {
				try {
					isKeyPressed[keyDownEvent.key] = true;
					//The shortcuts
					if(isKeyPressed['t']){
						changeTheme();
						return;
					}
					if(isKeyPressed['r']&&!isKeyPressed['Control']){
						router.push('/recyclebin');
						return;
					}
					if(isKeyPressed['b']){
						router.push('/backup');
						return;
					}
					if(isKeyPressed['s']){
						sessionStorage.setItem('searchBoundaries','All');
						router.push('/search');
						return;
					}
					if(isKeyPressed['Escape']){
						modalVisible.value=false;
						return;
					}
					if(isKeyPressed['c']&&isKeyPressed['f']){
						addNewFolderInMainFolder();
						isKeyPressed['c']=false;
						isKeyPressed['f']=false;
					}
					if(isKeyPressed['c']&&isKeyPressed['n']){
						addNewNoteInMainFolder();
						isKeyPressed['c']=false;
						isKeyPressed['n']=false;
					}
					if(isKeyPressed["m"]){
						if(isKeyPressed["f"]){
							if(isKeyPressed["1"]){
								router.push('/folder/'+mainFolderFolders[0].id);
							} else if (isKeyPressed["2"]) {
								router.push('/folder/'+mainFolderFolders[1].id);
							} else if (isKeyPressed["3"]) {
								router.push('/folder/'+mainFolderFolders[2].id);
							} else if (isKeyPressed["4"]) {
								router.push('/folder/'+mainFolderFolders[3].id);
							} else if (isKeyPressed["5"]) {
								router.push('/folder/'+mainFolderFolders[4].id);
							} else if (isKeyPressed["6"]) {
								router.push('/folder/'+mainFolderFolders[5].id);
							} else if (isKeyPressed["7"]) {
								router.push('/folder/'+mainFolderFolders[6].id);
							} else if (isKeyPressed["8"]) {
								router.push('/folder/'+mainFolderFolders[7].id);
							} else if (isKeyPressed["9"]) {
								router.push('/folder/'+mainFolderFolders[8].id);
							}
						} else if(isKeyPressed["n"]) {
							if(isKeyPressed["1"]){
								router.push('/note/'+mainFolderNotes[0].id);
							} else if (isKeyPressed["2"]) {
								router.push('/note/'+mainFolderNotes[1].id);
							} else if (isKeyPressed["3"]) {
								router.push('/note/'+mainFolderNotes[2].id);
							} else if (isKeyPressed["4"]) {
								router.push('/note/'+mainFolderNotes[3].id);
							} else if (isKeyPressed["5"]) {
								router.push('/note/'+mainFolderNotes[4].id);
							} else if (isKeyPressed["6"]) {
								router.push('/note/'+mainFolderNotes[5].id);
							} else if (isKeyPressed["7"]) {
								router.push('/note/'+mainFolderNotes[6].id);
							} else if (isKeyPressed["8"]) {
								router.push('/note/'+mainFolderNotes[7].id);
							} else if (isKeyPressed["9"]) {
								router.push('/note/'+mainFolderNotes[8].id);
							}
						} else {
							if(isKeyPressed["1"]){
								if(1<=mainFolderFolders.length){
									router.push('/folder/'+mainFolderFolders[0].id);
								} else {
									router.push('/note/'+mainFolderNotes[0-mainFolderFolders.length].id);
								}
							} else if (isKeyPressed["2"]) {
								if(2<=mainFolderFolders.length){
									router.push('/folder/'+mainFolderFolders[1].id);
								} else {
									router.push('/note/'+mainFolderNotes[1-mainFolderFolders.length].id);
								}
							} else if (isKeyPressed["3"]) {
								if(3<=mainFolderFolders.length){
									router.push('/folder/'+mainFolderFolders[2].id);
								} else {
									router.push('/note/'+mainFolderNotes[2-mainFolderFolders.length].id);
								}
							} else if (isKeyPressed["4"]) {
								if(4<=mainFolderFolders.length){
									router.push('/folder/'+mainFolderFolders[3].id);
								} else {
									router.push('/note/'+mainFolderNotes[3-mainFolderFolders.length].id);
								}
							} else if (isKeyPressed["5"]) {
								if(5<=mainFolderFolders.length){
									router.push('/folder/'+mainFolderFolders[4].id);
								} else {
									router.push('/note/'+mainFolderNotes[4-mainFolderFolders.length].id);
								}
							} else if (isKeyPressed["6"]) {
								if(6<=mainFolderFolders.length){
									router.push('/folder/'+mainFolderFolders[5].id);
								} else {
									router.push('/note/'+mainFolderNotes[5-mainFolderFolders.length].id);
								}
							} else if (isKeyPressed["7"]) {
								if(7<=mainFolderFolders.length){
									router.push('/folder/'+mainFolderFolders[6].id);
								} else {
									router.push('/note/'+mainFolderNotes[6-mainFolderFolders.length].id);
								}
							} else if (isKeyPressed["8"]) {
								if(8<=mainFolderFolders.length){
									router.push('/folder/'+mainFolderFolders[7].id);
								} else {
									router.push('/note/'+mainFolderNotes[7-mainFolderFolders.length].id);
								}
							} else if (isKeyPressed["9"]) {
								if(9<=mainFolderFolders.length){
									router.push('/folder/'+mainFolderFolders[8].id);
								} else {
									router.push('/note/'+mainFolderNotes[8-mainFolderFolders.length].id);
								}
							}
						}
					} else if (isKeyPressed["f"]) {
						if(isKeyPressed["1"]){
							router.push('/folder/'+favoriteFolders[0].id);
						} else if (isKeyPressed["2"]) {
							router.push('/folder/'+favoriteFolders[1].id);
						} else if (isKeyPressed["3"]) {
							router.push('/folder/'+favoriteFolders[2].id);
						} else if (isKeyPressed["4"]) {
							router.push('/folder/'+favoriteFolders[3].id);
						} else if (isKeyPressed["5"]) {
							router.push('/folder/'+favoriteFolders[4].id);
						} else if (isKeyPressed["6"]) {
							router.push('/folder/'+favoriteFolders[5].id);
						} else if (isKeyPressed["7"]) {
							router.push('/folder/'+favoriteFolders[6].id);
						} else if (isKeyPressed["8"]) {
							router.push('/folder/'+favoriteFolders[7].id);
						} else if (isKeyPressed["9"]) {
							router.push('/folder/'+favoriteFolders[8].id);
						}
					} else if (isKeyPressed["n"]) {
						if(isKeyPressed["1"]){
							router.push('/note/'+favoriteNotes[0].id);
						} else if (isKeyPressed["2"]) {
							router.push('/note/'+favoriteNotes[1].id);
						} else if (isKeyPressed["3"]) {
							router.push('/note/'+favoriteNotes[2].id);
						} else if (isKeyPressed["4"]) {
							router.push('/note/'+favoriteNotes[3].id);
						} else if (isKeyPressed["5"]) {
							router.push('/note/'+favoriteNotes[4].id);
						} else if (isKeyPressed["6"]) {
							router.push('/note/'+favoriteNotes[5].id);
						} else if (isKeyPressed["7"]) {
							router.push('/note/'+favoriteNotes[6].id);
						} else if (isKeyPressed["8"]) {
							router.push('/note/'+favoriteNotes[7].id);
						} else if (isKeyPressed["9"]) {
							router.push('/note/'+favoriteNotes[8].id);
						}
					} else {
						if(isKeyPressed["1"]){
							if(1<=favoriteFolders.length){
								router.push('/folder/'+favoriteFolders[0].id);
							} else {
								router.push('/note/'+favoriteNotes[0-favoriteFolders.length].id);
							}
						} else if (isKeyPressed["2"]) {
							if(2<=favoriteFolders.length){
								router.push('/folder/'+favoriteFolders[1].id);
							} else {
								router.push('/note/'+favoriteNotes[1-favoriteFolders.length].id);
							}
						} else if (isKeyPressed["3"]) {
							if(3<=favoriteFolders.length){
								router.push('/folder/'+favoriteFolders[2].id);
							} else {
								router.push('/note/'+favoriteNotes[2-favoriteFolders.length].id);
							}
						} else if (isKeyPressed["4"]) {
							if(4<=favoriteFolders.length){
								router.push('/folder/'+favoriteFolders[3].id);
							} else {
								router.push('/note/'+favoriteNotes[3-favoriteFolders.length].id);
							}
						} else if (isKeyPressed["5"]) {
							if(5<=favoriteFolders.length){
								router.push('/folder/'+favoriteFolders[4].id);
							} else {
								router.push('/note/'+favoriteNotes[4-favoriteFolders.length].id);
							}
						} else if (isKeyPressed["6"]) {
							if(6<=favoriteFolders.length){
								router.push('/folder/'+favoriteFolders[5].id);
							} else {
								router.push('/note/'+favoriteNotes[5-favoriteFolders.length].id);
							}
						} else if (isKeyPressed["7"]) {
							if(7<=favoriteFolders.length){
								router.push('/folder/'+favoriteFolders[6].id);
							} else {
								router.push('/note/'+favoriteNotes[6-favoriteFolders.length].id);
							}
						} else if (isKeyPressed["8"]) {
							if(8<=favoriteFolders.length){
								router.push('/folder/'+favoriteFolders[7].id);
							} else {
								router.push('/note/'+favoriteNotes[7-favoriteFolders.length].id);
							}
						} else if (isKeyPressed["9"]) {
							if(9<=favoriteFolders.length){
								router.push('/folder/'+favoriteFolders[8].id);
							} else {
								router.push('/note/'+favoriteNotes[8-favoriteFolders.length].id);
							}
						}
					}
				} catch(e) {
					console.log('Item not found 🐵');
				}
			}
			document.onkeyup = (keyUpEvent) => {
				isKeyPressed[keyUpEvent.key] = false;
			};
		});
		onUnmounted(()=>{
			document.onkeydown = null;
			document.onkeyup = null;
		});

		return {
			userLoggedIn,
			email,
			close,
			searchAll,
			searchFavorites,
			fetchHomePageData,
			drawerMenuDisplayed,
			showDropdown,
			showDropdownFolder,
			showDropdownNote,
			displayDrawerMenu,
			addNewFolderInMainFolder,
			addNewNoteInMainFolder,
			mainFolderFolders,
			mainFolderNotes,
			favoriteFolders,
			favoriteNotes,
			folderMenu,
			favoriteFolderMenu,
			noteMenu,
			favoriteNoteMenu,
			loading,
			toggleModal,
			modalVisible,
			isLoading,
			options,
			functions,
			changeTheme,
			dark,
		};

  }
}
</script>

<style scoped>
	@import '../styles/nav.css';

	/*Nav Bar*/
	#hidden_icons_for_mobile {
		display: block;
	}
	#mobile_items {
		display: none;
	}
	#menu_icon {
		display: none;
	}

	/*Dropdown menu*/
	.dropdown-content {
		display: none;
		background-color: rgb(121,85,72);
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		z-index: 1;
		position:fixed;
		right:0px;
		top:45px;
		cursor: pointer;
	}
	.dropdown-content a {
		color: white;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
	}
	.dropdown-content a:hover {
		background-color: rgb(88,62,52);
	}

	/*Main Folder*/

	#mainFolder {
		display: block;
		background-color: rgb(255,209,72);
		box-shadow: 4px 3px 8px 1px #969696;
	}
	#mainFolder h2 {
		text-align: center;
		margin: 10px;
		padding: 0;
	}
	#mainFolderContent {
		margin: 5px;
		overflow: visible;
		height: auto;
	}
	/*Main folder buttons*/
	#mainFolderButtons {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
	#mainFolderButtons div h3 {
		padding: 0px;
		margin: 0px;
	}
	#mainFolderButtons div:hover {
		background-color: rgb(249,187,0);
	}
	#mainFolderButtons div {
		text-align: center;
		padding: 10px;
		cursor: pointer;
	}
	#mainFolderButtons div i {
		font-size: 2em;
	}

	/*HomePage content*/
	#page_container {
		height: 100%;
		display: grid;
		grid-template-columns: 250px auto;
		grid-gap: 15px;
	}
	#homepage i {
		font-size:1.9em;
		display:inline;
		margin:0px;
		padding:0px;
	}
	#homepage h2 {
		display:inline;
		margin:0px;
		padding:0px;
	}
	#favorite_banner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 10px;
	}
	#favorite_banner i {
		cursor: pointer;
	}
	#favorite_banner div {
		display: inline;
		align-items: center;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	#searchInFavoritesButton {
		padding-left:15px;
		padding-right:15px;
	}
	#recycleBinButton {
		background-color: rgba(0,0,0,0.0);/*transparent*/
		border: none;
		padding: 13px;
		text-align: center;
		text-decoration: none;
		display: block;
		font-size: 16px;
		margin: auto;
		cursor: pointer;
		box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
	}
	#recycleBinButtonContent {
		text-decoration: none;
	}

	@media screen and (max-width: 770px) {/*For tablets*/
		/*Nav Bar*/
		#mobile_items {
			display: block;
		}
		#hidden_icons_for_mobile {
			display: none;
		}
		#menu_icon {
			display: block;
		}
		/*Main Folder*/
		#mainFolder {
			display: none;
			box-shadow: 4px 3px 8px 1px #969696;
		}
		#mainFolderContent {
			overflow: visible scroll;
			height: 60%;
		}
		/*HomePage content*/
		#page_container {
			grid-template-columns: auto;
		}
	}
</style>