import {createWebHashHistory,createRouter} from "vue-router";
import HomePage from "../screens/HomePage.vue";
import RecycleBin from "../screens/RecycleBin.vue";
import HelpUs from "../screens/HelpUs.vue";
import Tutorial from "../screens/Tutorial.vue";
import SearchWord from "../screens/SearchWord.vue";
import Backup from "../screens/Backup.vue";
import FolderView from "../screens/FolderView.vue";
import NoteView from "../screens/NoteView.vue";
import ReadAloud from "../screens/ReadAloud.vue";
import SelectItems from "../screens/SelectItems.vue";
import Authentication from "../screens/Authentication.vue";
import WebFolder from "../screens/WebFolder.vue";
import WebNote from "../screens/WebNote.vue";
import FastNote from "../screens/FastNote.vue";
import Templates from "../screens/Templates.vue";
import PrivacyPolicy from "../screens/PrivacyPolicy.vue";
import DeSotoAppPrivacyPolicy from "../screens/DeSotoAppPrivacyPolicy.vue";
import NotFound from "../screens/NotFound.vue";

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes: [
		{
			path: "/:catchAll(.*)",
			name: 'NotFound',
			component: NotFound,
		},
		{
			path: "/",
			name: 'HomePage',
			component: HomePage,
		},
		{
			path: "/recyclebin",
			name: 'RecycleBin',
			component: RecycleBin,
		},
		{
			path: "/tutorial",
			name: 'Tutorial',
			component: Tutorial,
		},
		{
			path: "/backup",
			name: 'Backup',
			component: Backup,
		},
		{
			path: "/help",
			name: 'HelpUs',
			component: HelpUs,
		},
		{
			path: "/search",
			name: 'SearchWord',
			component: SearchWord,
		},
		{
			path: "/folder/:folderID",
			name: 'FolderView',
			component: FolderView,
		},
		{
			path: "/note/:noteID",
			name: 'NoteView',
			component: NoteView,
		},
		{
			path: "/readNote",
			name: 'ReadAloud',
			component: ReadAloud,
		},
		{
			path: "/select/:process/:folderID",
			name: 'SelectItems',
			component: SelectItems,
		},
		{
			path: "/signin",
			name: 'Authentication',
			component: Authentication,
		},
		{
			path: "/webfolder",
			name: 'WebFolder',
			component: WebFolder,
		},
		{
			path: "/webnote/:noteID",
			name: 'WebNote',
			component: WebNote,
		},
		{
			path: "/fastnote",
			name: 'FastNote',
			component: FastNote,
		},
		{
			path: "/templates",
			name: 'Templates',
			component: Templates,
		},
		{
			path: "/privacy",
			name: 'PrivacyPolicy',
			component: PrivacyPolicy,
		},
		{
			path: "/privacy/desotoapp",
			name: 'DeSotoAppPrivacyPolicy',
			component: DeSotoAppPrivacyPolicy,
		},
	],
});

export default router;