<template>
	<div v-if="isVisible" id="overlay">
		<div v-if="isLoading" id="loadingMonkey">
			<template v-if="customText==null||customText==undefined||customText==''">
				<p style="font-size:72px;margin:0px">🐵</p>
				<p class="titleText">Loading...</p>
			</template>
			<template v-else>
				<p style="font-size:72px;margin:0px">{{customText}}</p>
				<p class="titleText"></p>
			</template>
		</div>
		<div v-else id="modal" :class="dark?'darkModal':'lightModal'">
			<p v-for="(option,index) in options" @click="functions[index]" :key="index">{{option}}</p>
			<p @click="closeModal()">Cancel</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FolderView',
		props: {
			isLoading: Boolean,
			isVisible: Boolean,
			closeModal: Function,
			options: Array,
			functions: Array,
			dark: Boolean,
			customText: String,
		},
	}
</script>

<style scoped>
	#overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0,0,0,0.3);
		z-index: 23;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#loadingMonkey h1 {
		text-align:center;
		font-size:5em;
	}
	#loadingMonkey p {
		text-align:center;
		color:white;
		font-size:23px;
	}
	#modal {
		width: 50%;
		max-width: 350px;
		box-shadow: 1px 2px 4px rgba(153,155,168,0.12);
		border-radius: 4px;
		border-radius: 10px;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		box-shadow: 0px 16px 32px 0px rgba(0,0,0,0.2);
		padding: 15px;
		text-align: center;
		font-weight: bold;
		font-size: 15px;
	}
	#modal p {
		cursor: pointer;
		color: white;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
	}

	.lightModal {
		background-color: rgb(121,85,72);
	}
	.lightModal p:hover {
		background-color: rgb(88,62,52);
	}
	.darkModal {
		background-color: rgb(72,72,72);
	}
	.darkModal p:hover {
		background-color: rgb(33,33,33);
	}
</style>