<template>

	<Modal :isLoading="true" :isVisible="loading" :dark="dark"/>
	
	<div :class="dark?'monkeyBar darkCard':'monkeyBar'">
		<i @click="$router.go(-1)" class="material-icons" aria-hidden="true">arrow_back</i>
		<h3 class="titleText unselectable">Search word</h3>
	</div>

	<div id="content" :style="dark?'color:white;':'color:black;'">

		<template v-if="searchBoundaries=='All'"></template>
		<template v-else-if="searchBoundaries=='Favorites'">
			<div class="centerItems">
				<i class="material-icons" aria-hidden="true">star</i>
				<h2 style="margin-left:16px;" class="unselectable">Favorites</h2>
			</div>
		</template>
		<template v-else>
			<h2 style="text-align:center;">{{searchBoundaries.split("<monkeynote separator>")[0]}}</h2>
		</template>

		<div @click="changeSearchParams(0)" :class="dark?'checkboxTile darkCardHover':'checkboxTile'">
			<h2 class="unselectable">Search folders</h2>
			<i class="material-icons" aria-hidden="true">check_box</i>
		</div>
		<div @click="changeSearchParams(1)" :class="dark?'checkboxTile darkCardHover':'checkboxTile'">
			<h2 class="unselectable">Search note titles</h2>
			<i class="material-icons" aria-hidden="true">check_box</i>
		</div>
		<div @click="changeSearchParams(2)" :class="dark?'checkboxTile darkCardHover':'checkboxTile'">
			<h2 class="unselectable">Search note content</h2>
			<i class="material-icons" aria-hidden="true">check_box</i>
		</div>

		<div id="search_word_input">
			<input class="bodyText" type="text" id="wordToSearch" name="wordToSearch" placeholder="search..." :style="dark?'color:white;':'color:black;'" :onkeyup="search">
		</div>

		
		<h3 v-if="matchFolders.length==0&&matchNotes.length==0" style="text-align:center;display:block;" class="titleText unselectable">No content</h3>
		<div v-else>

			<h2>Folders:</h2>
			<template v-for="(matchFolder,i) in matchFolders" :key="i">
				<div @click="$router.push('/folder/'+matchFolder.id)" :class="dark?'matchPath darkCardHover':'matchPath'">
					<h3>{{"Folder #"+(i+1)}}</h3>
					<template v-for="(folder,c) in matchFolder.path" :key="c">
						<div class="centerItems">
							<i class="material-icons centerItems" aria-hidden="true">folder</i>
							<p class="bodyText">{{folder}}</p>
						</div>
					</template>
				</div>
				<div :class="dark?'separator background-white':'separator'"></div>
			</template>

			<h2>Notes:</h2>
			<template v-for="(matchNote,i) in matchNotes" :key="i">
				<div @click="$router.push('/note/'+matchNote.id)" :class="dark?'matchPath darkCardHover':'matchPath'">
					<h3>{{"Note #"+(i+1)}}</h3>
					<div v-for="(folder,e) in matchNote.path" :key="e" class="centerItems">
						<i class="material-icons centerItems" aria-hidden="true">folder</i>
						<p class="bodyText">{{folder}}</p>
					</div>
					<div class="centerItems">
						<i class="material-icons note_icon" aria-hidden="true">note</i>
						<p class="bodyText">{{matchNote.noteTitle}}</p>
					</div>
				</div>
				<template v-for="(match,o) in matchNote.matches" :key="o">
					<h3 class="titleText unselectable">Match {{o+1}}: </h3>
					<p class="bodyText">{{match}}</p>
				</template>
				<div :class="dark?'separator background-white':'separator'"></div>
			</template>
		</div>

	</div>
</template>

<script>
import {reactive} from 'vue';
import { ref } from "vue";
import Localbase from 'localbase';
import Modal from '../components/Modal.vue';
import {getFolderFullContent} from '../modules/mf';
export default {
  name: 'SearchWord',
  components: {Modal},
  setup(props,context){

		let alwaysTrue=true;

		const db = new Localbase('monkeynote');

		const dark = localStorage.getItem('darkTheme')=="true";
		const loading = ref(false);

		//Get the search parameters
		const searchBoundaries = sessionStorage.getItem('searchBoundaries');

		//For seach word screen
		let searchFolders = true;
		let searchNoteTitles = true;
		let searchNoteContent = true;

		//Change the search parameters
		const changeSearchParams=(index)=>{
			let node = document.getElementsByClassName("checkboxTile")[index];
			if(index==0){//folders
				if(searchFolders){
					node.childNodes[1].innerHTML="check_box_outline_blank";
				} else {
					node.childNodes[1].innerHTML="check_box";
				}
				searchFolders=!searchFolders;
			} else if(index==1){//note titles
				if(searchNoteTitles){
					node.childNodes[1].innerHTML="check_box_outline_blank";
				} else {
					node.childNodes[1].innerHTML="check_box";
				}
				searchNoteTitles=!searchNoteTitles;
			} else if(index==2){//note content
				if(searchNoteContent){
					node.childNodes[1].innerHTML="check_box_outline_blank";
				} else {
					node.childNodes[1].innerHTML="check_box";
				}
				searchNoteContent=!searchNoteContent;
			} else {
				//Do notihing
			}
		}

		const matchFolders = reactive([]);
		const matchNotes = reactive([]);

		const filterContent=(folders,notes,word)=>{

			//Filter folders
			if(searchFolders){
				folders=folders.filter((folder)=>{
					return folder.name.toLowerCase().indexOf(word)!=-1;
				});
			} else {
				folders = [];
			}

			//Filter notes
			if(searchNoteTitles&&!searchNoteContent){
				notes=notes.filter((note)=>{
					return note.title.toLowerCase().indexOf(word)!=-1;
				});
			}
			if(!searchNoteTitles&&searchNoteContent){
				notes=notes.filter((note)=>{
					return note.body.toLowerCase().indexOf(word)!=-1;
				});
			}
			if(!searchNoteTitles&&!searchNoteContent){
				notes=[];
			}
			if(searchNoteTitles&&searchNoteContent){
				notes=notes.filter((note)=>{
					return (note.body.toLowerCase().indexOf(word)!=-1)||(note.title.toLowerCase().indexOf(word)!=-1);
				});
			}

			return {folders:folders,notes:notes};
		}

		/*
		matchFolder {
			id: String,
			path: Array<String>
		}
		matchNote {
			id: String,
			path: Array<String>,
			noteTitle: String,
			matches: Array<String>,
		}
		*/
		const showResults=async(word,folders,notes)=>{
			while(matchFolders.length > 0) {
				matchFolders.pop();
			}
			while(matchNotes.length > 0) {
				matchNotes.pop();
			}
			for(let i=0;i<folders.length;i++){
				let path = await pathOf(true,folders[i].parentID);
				path.push(folders[i].name);
				matchFolders.push({id:folders[i].id,path:path});
			}
			for(let i=0;i<notes.length;i++){
				let path = await pathOf(false,notes[i].folderID);
				matchNotes.push({
					id: notes[i].id,
					path: path,
					noteTitle: notes[i].title,
					matches: getMatches(word,notes[i].body),
				});
			}
		}
		
		const pathOf=async(isFolder,parentID)=>{
			//If it's a folder directly inside the main folder
			if(parentID==null||parentID.trim()=='')return [];

			//Otherwise get the folder path
			let folderPath = [];
			let momentaryFolder;
			let firstIteration=true;
			while(alwaysTrue){
				if(firstIteration){
					momentaryFolder = await db.collection('folders').doc(parentID).get();
					firstIteration=false;
				} else {
					momentaryFolder = await db.collection('folders').doc(momentaryFolder.parentID).get();
				}
				if(momentaryFolder.parentID==null||momentaryFolder.parentID.trim()==''){
					folderPath.push(momentaryFolder.name);
					break;
				} else {
					folderPath.push(momentaryFolder.name);
				}
			}

			return folderPath.reverse();
		}

		const getMatches=(word,body)=>{

			if(!searchNoteContent)return [];

			//Get all the matches indexes
      let indexes = [];
      let theLastIndex = 0;
      while(alwaysTrue){
        theLastIndex = body.toLowerCase().indexOf(word,indexes.length==0?0:(indexes.slice(-1).pop())+1);
        if(indexes.indexOf(theLastIndex)!=-1){//If the index is already saved
          indexes.pop();
          break;
        } else {
          indexes.push(theLastIndex);
        }
      }

      //Get all the portions for each index for this note
      let portions = [];
      indexes.forEach((index)=>{
        let firstIndex;
        let lastIndex;
        let threeDotsAtTheBeginning=false;
        let threeDotsAtTheEnd=false;
        //Get first index
        if((index-10)<0){
          firstIndex=0;
        } else {
          firstIndex=index-10;
          threeDotsAtTheBeginning=true;
        }
        //Get last index
        if((index+word.length+10)>body.length-1){
          lastIndex=body.length-1;
        } else {
          lastIndex=index+word.length+10;
          threeDotsAtTheEnd=true;
        }
        //Save the portion
        portions.push((threeDotsAtTheBeginning?'...':'')+body.substring(firstIndex,lastIndex+1)+(threeDotsAtTheEnd?'...':''));
      });

      return portions;
		}

		const search=async(key)=>{
			loading.value=true;
			try {
				//Get the notes in the recycle bin to avoid including them in the backup
				let deletedNotes=localStorage.getItem('deletedNotes');
				if(deletedNotes&&deletedNotes.trim()!=''){
					deletedNotes=localStorage.getItem('deletedNotes').split(' ');
				} else {
					deletedNotes=[];
				}

				if(key.key.toLowerCase()=="enter"){
					let word = document.getElementById("wordToSearch").value;
					if(word!=null&&word.trim()!=""){

						word=word.toLowerCase();
						
						if(searchBoundaries=="All"){
							
							//Get all folders and notes
							let folders = await db.collection("folders").get();
							let notes = await db.collection("notes").get();
							notes=notes.filter((note)=>{
								return deletedNotes.indexOf(note.id)==-1;
							});
							//Filter depending on the parameters
							const x = filterContent(folders,notes,word);
							folders = x.folders;
							notes = x.notes;
							//Show the results
							showResults(word,folders,notes);
						
						} else if(searchBoundaries=="Favorites") {
							
							//Get all favorite folders and notes
							let folders=[];
							let notes=[];
							let favoriteFolderIDs;
							let favoriteNoteIDs;
							let favoriteFolderIDsAsString = localStorage.getItem("favoriteFolders");
							let favoriteNoteIDsAsString = localStorage.getItem("favoriteNotes");
							if(favoriteFolderIDsAsString!=null&&favoriteFolderIDsAsString.trim()!=""){
								favoriteFolderIDs=favoriteFolderIDsAsString.split(" ");
							} else {
								favoriteFolderIDs = [];
							}
							if(favoriteNoteIDsAsString!=null&&favoriteNoteIDsAsString.trim()!=""){
								favoriteNoteIDs=favoriteNoteIDsAsString.split(" ");
							} else {
								favoriteNoteIDs = [];
							}
							for (let i = 0; i < favoriteFolderIDs.length; i++) {
								folders.push(await db.collection('folders').doc(favoriteFolderIDs[i]).get());
							}
							for (let i = 0; i < favoriteNoteIDs.length; i++) {
								notes.push(await db.collection('notes').doc(favoriteNoteIDs[i]).get());
							}
							//Filter depending on the parameters
							const x = filterContent(folders,notes,word);
							folders=x.folders;
							notes=x.notes;
							//Remove the deleted notes
							notes=notes.filter((note)=>{
								return deletedNotes.indexOf(note.id)==-1;
							});
							//Show the results
							showResults(word,folders,notes);

						} else {
							
							//Get all folders and notes inside the top folder
							let folders=[];
							let notes=[];
							await getFolderFullContent(searchBoundaries.split("<monkeynote separator>")[1],folders,notes);
							//Filter depending on the parameters
							const x=filterContent(folders,notes,word);
							folders=x.folders;
							notes=x.notes;
							//Remove the deleted notes
							notes=notes.filter((note)=>{
								return deletedNotes.indexOf(note.id)==-1;
							});
							//Show the results
							showResults(word,folders,notes);

						}
					}
				}
				loading.value=false;
			} catch(e) {
				alert('An error happened');
				loading.value=false;
			}
		}

		return {
			loading,
			dark,
			changeSearchParams,
			searchBoundaries,
			search,
			matchFolders,
			matchNotes,
		}
  }
}
</script>

<style scoped>
	.checkboxTile:hover {
		background-color: #E4C872;
	}
	.checkboxTile {
		margin: 15px;
		padding: 15px;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}
	.checkboxTile h2 {
		display: inline;
		margin: 0px;
		padding: 0px;
	}
	.checkboxTile i {
		display: inline;
		margin: 0px;
		padding: 0px;
		font-size: 30px;	
	}
	#search_word_input {
		margin: 20px;
	}
	#search_word_input input {
		background-color: rgba(0,0,0,0);
		height: 40px;
		width: 100%;
		display: inline;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	.matchPath {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
	}
	.matchPath:hover {
		background-color: #E4C872;
		cursor: pointer;
	}
	.matchPath div {
		margin-left: 15px;
	}
	.matchPath p {
		margin-left: 10px;
	}
	.matchPath i {
		color: rgb(174,131,0);
	}
	.note_icon {
		transform:rotate(270deg);
		color: rgb(153,217,234) !important;
	}
	/*Folder card selectable*/
	.folder_card_selectable:hover {
		background-color: #E4C872;
	}
	.folder_card_selectable {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: none;
		padding: 20px;
		text-align: center;
		cursor: pointer;
		box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19);
		width: 85%;
		margin: 20px auto 20px auto;
	}
	.folder_card_selectable div {
		color: black;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.folder_card_selectable i {
		color: rgb(140,105,0);
		font-size:1.9em;
		display:inline;
		margin:0px;
		padding:0px;
	}
	.folder_card_selectable h2 {
		display:inline;
		margin:0px;
		padding-left: 10px;
	}

	/*Note card selectable*/
	.note_card_selectable:hover {
		background-color: #E4C872;
	}
	.note_card_selectable {
		background-color: rgb(255,224,130);
		color: black;
		font-size: 1em;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: none;
		padding: 20px;
		text-align: center;
		cursor: pointer;
		box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19);
		width: 85%;
		margin: 20px auto 20px auto;
	}
	.note_card_selectable i {
		color: rgb(140,105,0);
		font-size:1.9em;
		display:inline;
		margin:0px;
		padding:0px;
	}
	.note_card_selectable h2 {
		display:inline;
		margin:0px;
		padding:0px;
	}
</style>