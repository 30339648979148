<template>

	<div v-if="selectable" class="note_card_selectable" :style="dark?'background-color:rgb(50,50,50);color:white;':''">
		<h2 style="overflow:hidden;text-overflow:ellipsis;" class="bodyText"><slot></slot></h2>
		<i v-if="selected" class="material-icons" aria-hidden="true">check_box</i>
		<i v-else class="material-icons" aria-hidden="true">check_box_outline_blank</i>
  </div>

  <div v-else-if="isInMainFolder" :tabindex="index+1001" @click="$router.push('/note/'+id)" class="note_card note_card_in_main_folder" :style="dark?'background-color:rgb(72,72,72);color:white;':''">
		<h2 style="overflow:hidden;text-overflow:ellipsis;" class="bodyText"><slot></slot></h2>
	</div>

	<div v-else :tabindex="index+1001" @click="$router.push((web?'/webnote/':'/note/')+id)" class="note_card" :style="dark?'background-color:rgb(50,50,50);color:white;':''">
    <h2 style="overflow:hidden;text-overflow:ellipsis;" class="bodyText"><slot></slot></h2>
	</div>

</template>

<script>
export default{
  name:"NoteCard",
  props:{
    id:String,
    index:Number,
    isInMainFolder:Boolean,
    selectable:Boolean,
    selected:Boolean,
    dark:Boolean,
    web:Boolean,
  },
  setup(props,context){
  }
}
</script>

<style scoped>
	.note_card:hover {
    background-color: #E4C872;
  }
  .note_card {
    background-color: rgb(255,224,130);
    color: black;
    font-size: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: none;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19);
    width: 85%;
    margin: 20px auto 20px auto;
  }
  .note_card i {
    color: rgb(140,105,0);
    font-size:1.9em;
    display:inline;
    margin:0px;
    padding:0px;
  }
  .note_card h2 {
    display:inline;
    margin:0px;
    padding:0px;
  }
  .note_card_in_main_folder {
		padding: 15px 10px;
		margin: 15px auto 15px auto;
		background-color: rgb(255,209,72);
	}
	.note_card_selectable:hover {
		background-color: #E4C872;
	}
	.note_card_selectable {
		background-color: rgb(255,224,130);
		color: black;
		font-size: 1em;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: none;
		padding: 20px;
		text-align: center;
		cursor: pointer;
		box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19);
		width: 85%;
		margin: 20px auto 20px auto;
	}
	.note_card_selectable i {
		color: rgb(140,105,0);
		font-size:1.9em;
		display:inline;
		margin:0px;
		padding:0px;
	}
	.note_card_selectable h2 {
		display:inline;
		margin:0px;
		padding:0px;
	}
</style>