<template>
	
	<div :class="dark?'monkeyBar darkCard':'monkeyBar'">
		<i @click="$router.go(-1)" class="material-icons" aria-hidden="true">arrow_back</i>
		<h3 class="titleText">{{noteTitle}}</h3>
		<div class="actionsInNav">
			<i @click="showDropdown()" class="material-icons" aria-hidden="true">settings</i>
		</div>
	</div>

	<div id="dropdown-content" :class="dark?'dropdown-content darkCard':'dropdown-content'">
		<a @click="changeLanguages()" class="titleText">Change languages (l)</a>
		<a @click="changeVoice()" class="titleText">Change voice (v)</a>
		<a @click="changePitch()" class="titleText">Change pitch (p)</a>
		<a @click="changeRate()" class="titleText">Change speed (s)</a>
	</div>

	<div class="folderAddButtons">
		<div @click="backwards()" :class="dark?'circularButton darkButton':'circularButton'">
			<i style="font-size:1.8em" class="material-icons" aria-hidden="true">keyboard_arrow_left</i>
		</div>
		<div v-if="paused" @click="playButton()" :class="dark?'circularButton darkButton':'circularButton'">
			<i style="font-size:1.8em" class="material-icons" aria-hidden="true">play_arrow</i>
		</div>
		<div v-else @click="playButton()" :class="dark?'circularButton darkButton':'circularButton'">
			<i style="font-size:1.8em" class="material-icons" aria-hidden="true">pause</i>
		</div>
		<div @click="forward()" :class="dark?'circularButton darkButton':'circularButton'">
			<i style="font-size:1.8em" class="material-icons" aria-hidden="true">keyboard_arrow_right</i>
		</div>
		<div @click="stop()" :class="dark?'circularButton darkButton':'circularButton'">
			<i style="font-size:1.8em" class="material-icons" aria-hidden="true">stop</i>
		</div>
	</div>

	<div id="settings" class="bodyText" :style="dark?'color:white;':''">
		<div v-if="languagesPanel" id="languagesPanel">
			<label for="language">Language:</label>
			<select name="language" id="language" :onchange="setLanguage">
				<option v-for="(lang,index) in monkey.langs" :key="index" :value="monkey.langCodes[index]">{{lang}}</option>
			</select>
		</div>

		<div v-if="voicePanel" id="voicePanel">
			<label for="voice">Voice:</label>
			<select name="voice" id="voice" :onchange="setVoice">
				<option v-for="(voice,index) in voices" :key="index" :value="index">{{voice.name}}</option>
			</select>
		</div>

		<div v-if="pitchPanel" id="pitchPanel">
			<label for="pitch">Pitch:</label>
			<input type="range" value="1.0" step="0.1" id="pitch" name="pitch" min="0" max="10" :onchange="setPitch">
		</div>

		<div v-if="ratePanel" id="ratePanel">
			<label for="rate">Speed:</label>
			<input type="range" step="0.1" value="1.0" id="rate" name="rate" min="0" max="5" :onchange="setRate">
		</div>
	</div>


	<div id="content" :style="dark?'color:white;':''">
		<p v-for="(p,i) in noteBody" :key="i" @dblclick="setIndex(i)" :class="dark?'darkParagraph bodyText':'paragraph bodyText'">{{p}}</p>
	</div>

</template>

<script>
import {reactive} from 'vue';
import { ref } from "vue";
import { onMounted } from "vue";
import { onUnmounted } from "vue";
import monkey from '../modules/monkey';

export default {
  name: 'ReadAloud',
  setup(props,context) {

		const dark=localStorage.getItem('darkTheme')=="true";

		const noteTitle = sessionStorage.getItem("textToReadTitle");
		const noteBody = sessionStorage.getItem("textToRead").split("\n");

		const languagesPanel = ref(false);
		const voicePanel = ref(false);
		const pitchPanel = ref(false);
		const ratePanel = ref(false);

		const paused = ref(true);
		const playButton=()=>{
			paused.value=!paused.value;
			if(!paused.value){
				readNote();
			} else {
				monkeySpeaker.cancel();
			}
		};
		const index=ref(0);
		const setIndex=(newIndex)=>{
			document.getElementById("content").childNodes[index.value+1].className=dark?"bodyText darkParagraph":"bodyText paragraph";
			index.value=newIndex;
			document.getElementById("content").childNodes[index.value+1].className=dark?"bodyText selectedDark":"bodyText selected";
		}
		const forward=()=>{
			monkeySpeaker.cancel();
			paused.value=true;
			if(index.value+1==noteBody.length)return;
			setIndex(index.value+1);
		}
		const backwards=()=>{
			monkeySpeaker.cancel();
			paused.value=true;
			if(index.value==0)return;
			setIndex(index.value-1);
		}
		const stop=()=>{
			monkeySpeaker.cancel();
			paused.value=true;
			setIndex(0);
		}

		const monkeySpeaker = window.speechSynthesis;
		const speaker = new SpeechSynthesisUtterance();
		let readNote=()=>{
			if(paused.value){
				return;
			}
			if((index.value >= noteBody.length)){
				paused.value=true;
				setIndex(index.value-1);
				return;
			}
			if(noteBody[index.value].trim()==""){
				setIndex(index.value+1);
				readNote();
			} else {
				speaker.text = noteBody[index.value];
				speaker.onend = ()=>{
					if(paused.value)return;
					setIndex(index.value+1);
					readNote();
				}
				monkeySpeaker.speak(speaker);
			}
		}

		const showDropdown=()=>{
			if(document.getElementById("dropdown-content").style.display=="block"){
				document.getElementById("dropdown-content").style.display="none";
			} else {
				document.getElementById("dropdown-content").style.display="block";
			}
		}

		const voices = reactive([]);

		const changeLanguages=()=>languagesPanel.value=!languagesPanel.value;
		const setLanguage=()=>{
			const x = document.getElementById("language");
			if(x)speaker.lang=x.value;
		}
		
		const changeVoice=()=>{
			//Get the voices
			const x = window.speechSynthesis.getVoices();
			x.forEach((y)=>voices.push(y));
			//Show the panel
			voicePanel.value=!voicePanel.value;
		}
		const setVoice=()=>{
			const x = document.getElementById("voice");
			if(x)speaker.voice=voices[x.value];
		}
		
		const changePitch=()=>pitchPanel.value=!pitchPanel.value;
		const setPitch=()=>{
			const x = document.getElementById("pitch");
			if(x)speaker.pitch=x.value;
		}
		
		const changeRate=()=>ratePanel.value=!ratePanel.value;
		const setRate=()=>{
			const x = document.getElementById("rate");
			if(x)speaker.rate=x.value;
		}

		//Shortcuts
		const isKeyPressed = { 
			'l': false,//language
			'v': false,//voice
			'p': false,//pitch
			's': false,//speed
			'r': false,//reset
			'ArrowLeft': false,//Previous paragraph
			'ArrowRight': false,//Next paragraph
			'': false,//Play or pause
		}
		onMounted(()=>{
			document.onkeydown = (keyDownEvent) => {
				try {
					isKeyPressed[keyDownEvent.key] = true;
					if(isKeyPressed["l"])changeLanguages();
					if(isKeyPressed["v"])changeVoice();
					if(isKeyPressed["p"])changePitch();
					if(isKeyPressed["s"])changeRate();
					if(isKeyPressed["r"])stop();
					if(isKeyPressed["ArrowUp"]){
						keyDownEvent.preventDefault();
						backwards();
					}
					if(isKeyPressed["ArrowDown"]){
						keyDownEvent.preventDefault();
						forward();
					}
					if(isKeyPressed[" "]){
						keyDownEvent.preventDefault();
						playButton();
					}
				} catch(e) {
					console.log('Item not found 🐵');
				}
			}
			document.onkeyup = (keyUpEvent) => {
				isKeyPressed[keyUpEvent.key] = false;
			};
		});
		onUnmounted(()=>{
			monkeySpeaker.cancel();
			document.onkeydown = null;
			document.onkeyup = null;
		});

		return {
			monkey,
			dark,
			noteTitle,
			noteBody,
			paused,
			playButton,
			setIndex,
			forward,
			backwards,
			stop,
			changeLanguages,
			changeVoice,
			changePitch,
			changeRate,
			showDropdown,
			languagesPanel,
			voicePanel,
			pitchPanel,
			ratePanel,
			voices,
			setLanguage,
			setVoice,
			setPitch,
			setRate,
		}
	}
}
</script>

<style scoped>

	.folderAddButtons {
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin-top: 16px;
		margin-bottom: 16px;
	}
	.folderAddButtons	.circularButton {
		margin: 0px;
	}
	.folderAddButtons	.darkButton {
		color:white;
		background-color: rgb(72,72,72);
	}
	.circularButton:hover {
		background-color: rgb(255,209,72);
	}
	.darkButton:hover {
		background-color: rgb(50,50,50);
	}
	#content {
		margin: 10px;
	}
	#content p {
		margin:0;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	#content .paragraph:hover {
		background-color: rgb(255,209,72);
	}
	#content .darkParagraph:hover {
		background-color: rgb(50,50,50);
	}
	.selected {
		background-color: orange;
	} 
	.selectedDark {
		background-color: rgb(72,72,72);
	}

	.dropdown-content {
		display: none;
		background-color: rgb(121,85,72);
		width: 80%;
		margin: 0px 0px 0px auto;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		z-index: 1;
		position:fixed;
		right: 0px;
		cursor: pointer;
	}
	.dropdown-content a {
		color: white;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
	}
	.dropdown-content a:hover {
		background-color: rgb(88,62,52);
	}

	#settings {
		font-weight: bold;
		font-size: 1.3em;
	}
	#settings div {
		margin: 0;
		padding: 13px;
	}
	#settings label {
		margin-right: 16px;
	}
	#settings input[type="range"] {
		width: 70%;
	}
</style>