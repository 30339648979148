<template>
	<div :class="dark?'monkeyBar darkCard':'monkeyBar'">
		<i @click="$router.go(-1)" class="material-icons" aria-hidden="true">arrow_back</i>
		<h3 class="titleText unselectable">Privacy policy</h3>
	</div>
	<br>
	<div class="contentPadding" :style="dark?'color:white;':'color:black;'">
		<p class="bodyText">This Privacy Policy establishes the terms in which MonkeyNote uses and protects the information that is provided by its users when using its website. This company is committed to the security of its users' data. When we ask you to fill in the personal information fields with which you can be identified, we do so by ensuring that it will only be used in accordance with the terms of this document. However, this Privacy Policy may change over time or be updated, so we recommend and emphasize that you continually review this page to ensure that you agree with such changes.</p>
		<h2 class="titleText">Information that is collected</h2>
		<p class="bodyText">Our app only collects the user's mail. However, it is completely voluntary to give the email or not, since it is a requirement to create an account, but the account is not a requirement to use the app. The account enables the user to store only the information they want in it to transport it between devices where they log in with their account, but all their information that is not stored in their account (which is outside of Web Folder and Fast Note), it will remain on your device only and will not be transported by any means to any of our or third party destinations.</p>
		<p class="bodyText">Whether or not the user logs in, the information in the Main Folder (or Main Folder) will always be on your device and will never be transferred to any medium or read by any third party, including MonkeyNote.</p>
		<h2 class="titleText">Use of the information collected</h2>
		<p class="bodyText">Our app uses the information in order to provide the best possible service and improve our products and services. It is possible that periodic emails are sent through our site with special offers, new products and other advertising information that we consider relevant to you or that may provide you with some benefit, these emails will be sent to the address you provide and may be canceled. anytime.</p>
		<p class="bodyText">MonkeyNote is highly committed to fulfilling the commitment to keep your information safe. We use the most advanced systems and constantly update them to ensure that there is no unauthorized access.</p>
		<h2 class="titleText">Cookies</h2>
		<p class="bodyText">MonkeyNote web app does not use cookies of any kind.</p>
		<h2 class="titleText">Third Party Links</h2>
		<p class="bodyText">This website may contain links to other sites that may be of interest to you (such as the link to our YouTube tutorial). Once you click on these links and leave our page, we no longer have control over the site to which you are redirected and therefore we are not responsible for the terms or privacy or the protection of your data on those other third party sites. These sites are subject to their own privacy policies, so it is recommended that you consult them to confirm that you agree with them.</p>
		<h2 class="titleText">About ads or ads in the mobile app</h2>
		<p class="bodyText">This section "About ads in the mobile app" only applies to the free app in the Play Store. The web app and the premium app do not have any advertising and therefore this section does not apply to both.</p>
		<p class="bodyText">The service used for ads in the mobile app is AdMob.</p>
		<p class="bodyText">Google/Firebase AdMob: Admob serves advertisements in our free app. You can opt out of AdMob by following the instructions described by Google: <a href="https://support.google.com/ads/answer/2662922?hl=en">https://support.google.com/ads/answer/2662922?hl=en</a>. For more details about AdMob and how they collect information please refer to these links: <a href="https://www.google.com/policies/privacy/partners/">https://www.google.com/policies/privacy/partners/</a> and <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.</p>
		<h2 class="titleText">Control of your personal information</h2>
		<p class="bodyText">In case you have created an account, you can at any time delete your account, and when you delete it there will be no trace of its existence on our servers, so we recommend that you delete your account only if you are sure you want to do so, for this reason we will ask you that you enter your email and password a second time when requesting the deletion of your account, to ensure your total willingness to delete your account. You can decide whether or not you want to receive cell phone notifications or emails about news that we believe may be of interest to you about MonkeyNote and its services.</p>
		<p class="bodyText">MonkeyNote to store data in your account, for user authentication and notification service, uses Firebase and Firebase Firestore services.</p>
		<p class="bodyText">This app does not collect information without your express consent (creating an account is a voluntary act). This company will not sell, transfer or distribute your personal information provided when creating the account, unless required by a judge with a court order.</p>
		<p class="bodyText">MonkeyNote reserves the right to change the terms of this Privacy Policy at any time.</p>
	</div>
</template>

<script>
	export default {
		name: 'PrivacyPolicy',
		setup(props,context) {
			const dark = localStorage.getItem('darkTheme')=="true";
			return {dark}
		}
	}
</script>

<style scoped>
	h2 {
		text-decoration: underline;
	}
</style>