<template>

	<Modal :isLoading="isLoading" :isVisible="modalVisible" :closeModal="toggleModal" :options="options" :functions="functions" :dark="dark"/>

	<nav style="justify-content:space-between;" :class="dark?'monkeyBar darkCard':'monkeyBar'">
		<div class="centerItems">
			<i @click="$router.go(-1)" class="material-icons" aria-hidden="true">arrow_back</i>
			<h2 style="display:inline;" class="titleText unselectable">Recycle bin</h2>
		</div>
		<i @click="clearRecycleBin()" class="material-icons" aria-hidden="true">delete_forever</i>
	</nav>

	<div v-if="!(loading.value)" id="recycleBinContent" :style="dark?'color:white;':'color:black;'">
		
		<h3 v-if="deletedNotes.length==0" class="titleText noContent unselectable">No content</h3>
		<template v-else>
			<NoteCard v-for="(note,index) in deletedNotes" :key="index" @contextmenu.prevent="deletedNoteMenu(note.id)" :id="note.id" :dark="dark">{{note.title}}</NoteCard>
		</template>

	</div>
</template>

<script>
import {reactive} from 'vue';
import { ref } from "vue";
import { onMounted } from "vue";
import { onUnmounted } from "vue";
import Localbase from 'localbase';
import NoteCard from '../components/NoteCard.vue';
import Modal from '../components/Modal.vue';
import {getAllFoldersBelow,getAllNotesBelow,fixIndexedIssue} from '../modules/mf';
export default {
  name: 'RecycleBin',
  components: {
		NoteCard,
		Modal,
  },
  setup(props,context){

		let alwaysTrue = true;

		let db = new Localbase('monkeynote');
		
		let deletedNotes = reactive([]);
		let loading = ref(true);

		const dark = localStorage.getItem('darkTheme')=="true";
		let isLoading = ref(false);
		let modalVisible = ref(false);
		let options = reactive([]);
		let functions = reactive([]);

		const toggleModal=()=>{
			modalVisible.value=!modalVisible.value;
		}

		async function getRecycleBinContent(){
			//Clear the previous content
			while (deletedNotes.length) {
				deletedNotes.pop();
			}
			//Get the list of deleted notes IDs
			if(localStorage.getItem("deletedNotes")==null||localStorage.getItem("deletedNotes")==""){
				//The recycle bin is empty
				loading.value = false;
			} else {
				let a = localStorage.getItem("deletedNotes").split(" ");
				let deletedNoteIDsThatDoesNotExist = [];
				for (let i = a.length - 1; i >= 0; i--) {
					let x = await db.collection("notes").doc(a[i]).get();
					try {
						deletedNotes.push({id:a[i],title:x.title});
					} catch(e) {
						console.log("The deleted note with the ID "+a[i]+" does not exist.");
						deletedNoteIDsThatDoesNotExist.push(a[i]);
					}
				}
				//Remove the ID of notes that does not exist
				for (let i = 0; i < deletedNoteIDsThatDoesNotExist.length; i++) {
					a.splice(a.indexOf(deletedNoteIDsThatDoesNotExist[i]),1);
				}
				localStorage.setItem("deletedNotes",a.join(" "));
				loading.value = false;
			}
		}
		getRecycleBinContent();

		async function clearRecycleBin(){
			if(confirm("Are you sure you want to delete everything?")){
				isLoading.value=true;
				modalVisible.value=true;
				try {
					await fixIndexedIssue();
					//Delete each note
					for (let i = deletedNotes.length - 1; i >= 0; i--) {
						try {
							await db.collection("notes").doc(deletedNotes[i].id).delete();
						} catch(e) {
							// Do nothing
						}
					}
					//Clear the deletedNotes
					while (deletedNotes.length) {
						deletedNotes.pop();
					}
					//Clear the deletedNotes in the Local Storage
					localStorage.setItem("deletedNotes","");
					isLoading.value=false;
					modalVisible.value=false;
				} catch(e) {
					alert('There was an error');
					console.log(e);
					isLoading.value=false;
					modalVisible.value=false;
				}
			}
		}

		function deletedNoteMenu(id){
			//Clear the previous content
			while (options.length) {
				options.pop();
			}
			//Add the options
			options.push("Restore note");
			options.push("Delete note");
			//Clear the previous content
			while (functions.length) {
				functions.pop();
			}
			//Add the options
			functions.push(async function(){
				isLoading.value=true;
				if(confirm("Restore the note?")){
					await restoreNote(id);
				}
				await getRecycleBinContent();
				isLoading.value=false;
				modalVisible.value=false;
			});
			functions.push(async function(){
				isLoading.value=true;
				if(confirm("Delete the note forever?")){
					await deleteNoteForever(id);
				}
				await getRecycleBinContent();
				isLoading.value=false;
				modalVisible.value=false;
			});
			//Show the modal
			toggleModal();
		}

		async function deleteNoteForever(id){
			//Remove it from the list of deleted notes
			let x = localStorage.getItem("deletedNotes").split(" ");
			x.splice(x.indexOf(id),1);
			while(alwaysTrue){//Delete the empty items
				if(x.indexOf("")!=-1){
					x.splice(x.indexOf(""),1);
				} else {
					break;
				}
			}
			localStorage.setItem("deletedNotes",x.join(" "));
			//Delete the note itself
			try {
				await db.collection("notes").doc(id).delete();
			} catch(e) {
				alert('There was an error');
				console.log(e);
			}
		}

		async function restoreNote(id){
			try {
				//Remove the note ID from deletedNotes in Local Storage
				let x = localStorage.getItem("deletedNotes").split(" ");
				x.splice(x.indexOf(id),1);
				while(alwaysTrue){//Delete the empty items
					if(x.indexOf("")!=-1){
						x.splice(x.indexOf(""),1);
					} else {
						break;
					}
				}
				localStorage.setItem("deletedNotes",x.join(" "));
				//Add the note ID into mainFolderNotes in Local Storage
				let y = localStorage.getItem("mainFolderNotes");
				if(y==null||y=="") {
					localStorage.setItem("mainFolderNotes",id);
				} else {
					let z = y.split(" ");
					z.push(id);
					while(alwaysTrue){//Delete the empty items
						if(z.indexOf("")!=-1){
							z.splice(z.indexOf(""),1);
						} else {
							break;
						}
					}
					localStorage.setItem("mainFolderNotes",z.join(" "));
				}
			} catch(e) {
				alert('There was an error');
				console.log(e);
			}
		}

		onMounted(()=>{
			document.onkeydown=keyDownEvent=>{
				if(keyDownEvent.key=='c')clearRecycleBin();
			}
		});
		onUnmounted(()=>document.onkeydown=null);

		return {
			deletedNotes,
			clearRecycleBin,
			loading,
			isLoading,
			modalVisible,
			options,
			functions,
			toggleModal,
			deletedNoteMenu,
			deleteNoteForever,
			dark
		};
  }
}
</script>