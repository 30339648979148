<template>
  <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
  <router-view :key="$route.fullPath"/>
</template>

<script>
  if(localStorage.getItem("darkTheme")=="true"){
    document.getElementsByTagName("body")[0].setAttribute("style","background-color: rgb(0,0,0);");
  } else {
    document.getElementsByTagName("body")[0].setAttribute("style","background-color: rgb(255,224,130);");
  }
  export default {name:'App'}
</script>

<style>

  i {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /*Dark theme styles*/
  .background-white {
    background-color: white !important;
  }
  .background-black {
    background-color: black !important;
  }
  .darkCard {
    background-color: rgb(72,72,72) !important;
    color: white;
  }
  .darkCard i:hover, .darkCard a:hover {
    background-color: rgb(33,33,33) !important;
  }
  .darkCardHover:hover {
    background-color: rgb(33,33,33) !important;
  }
  .blackText {
    color: black;
  }
  .whiteText {
    color: white;
  }

  .actionsInNav {
    width:100%;
    display:flex;
    align-items:flex-end;
    align-content:flex-end;
    justify-content:flex-end;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
  html, body {
    height: 100%;
  }
  .contentPadding {
    padding: 10px;
  }
  .centerItems {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .titleText {/*Works with hn tags*/
    font-family: 'Helvetica', 'Arial', sans-serif;
  }
  .bodyText {/*Works with p tags*/
    font-family: Verdana, sans-serif;
    font-size: 16px;
  }
  /*Separator*/
  .separator {height:5px; background-color:black; margin: 5px 7px;}
  .noMargin {
    margin: 0px;
  }
  .noContent{text-align:center;display:block;}
  /*Circular button*/
  .circularButton {
    border: none;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 30px;
    cursor: pointer;
    border-radius: 50%;
  }
  .circularButton i {
    font-size:1.9em;
  }
  /*Default monkey bar*/
  .monkeyBar {
    background-color: rgb(121,85,72);
    color: white;
    font-size: 1em;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    /*Flex box*/
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }
  .monkeyBar .titleText {
    color: white;
  }
  .monkeyBar i {
    font-size: 30px;
    padding: 15px;
    margin-right: 10px;
    cursor: pointer;
  }
  .monkeyBar i:hover {
    background-color: rgb(88,62,52);
  }
  .monkeyBar p {
    margin: 0px;
    padding: 0px;
    color:white;
  }
  /*Monkey button*/
  .monkeyButton {
    background-color: rgb(121,85,72);
    color: white;
    border: none;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    font-weight:bold;
    display: block;
    font-size: 16px;
    margin: auto;
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
</style>