import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router.js';

// Firebase code
import firebase from 'firebase/app';
const firebaseConfig = {
  apiKey: "AIzaSyBhrn7rrgNwxXpcJwks6rDfgo_QXJtteEo",
  authDomain: "monkeynote-83ca2.firebaseapp.com",
  databaseURL: "https://monkeynote-83ca2.firebaseio.com",
  projectId: "monkeynote-83ca2",
  storageBucket: "monkeynote-83ca2.appspot.com",
  messagingSenderId: "260607521238",
  appId: "1:260607521238:web:8935148d32b177905c3477",
  measurementId: "G-3NLZZLMJ04"
};
firebase.initializeApp(firebaseConfig);

createApp(App).use(router).mount('#app');